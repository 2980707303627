import React, { Component } from 'react';
import { ButtonGroup, Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import TablePaginator from '../../containers/TablePaginator/TablePaginator';
import ListActions from '../../containers/ListActions/ListActions';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj } from '../../actions';
import DownloadActions from '../../containers/DownloadActions/DownloadActions';

const emptyMessage = (<p>Não Existem registros para serem exibidos.</p>);

class UnidadeList extends Component {

    state = {
        url: '/unidades'
    }

    componentDidMount() {
        this.props.fetchObjs(this.state.url);
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Unidades</strong>
                                    </Col>
                                    <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <ButtonGroup>
                                            <Button type="button" size="sm" color="primary" onClick={() => this.props.history.push(`${this.state.url}/new`)}>
                                                <i className="fa fa-plus"></i> Novo
                                        </Button>
                                            <DownloadActions body={this.props.unidades.length !== 0 ? this.props.unidades : null} />
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.props.unidades.length === 0 ? emptyMessage : ([
                                    <Table  key={`table`} hover bordered striped responsive size="sm">
                                        <thead  key={`thead`}>
                                            <tr key="head">
                                                <th xs="12" sm="1" key="acoes" className="text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody  key={`tbody`}>
                                            {this.props.unidades.map((unidade, i) => {
                                                return (
                                                    <tr key={`tr${i}`}>
                                                        <td key={`tdActions${i}`}><ListActions {...this.props} {...this.state} id={unidade.ID} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>,
                                    <TablePaginator  key={`paginator`}{...this.props} {...this.state} />
                                ])}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        unidades: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(UnidadeList);

