import React, { Component } from 'react';
import { ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { staticUrl } from '../../actions'

class DownloadActions extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            dropdownOpen: new Array(6).fill(false)
        };

    }

    toggle(i) {
        const newArray = this.state.dropdownOpen.map((element, index) => {
            return (index === i ? !element : false);
        });
        this.setState({
            dropdownOpen: newArray,
        });
    }

    handle = (type) => {
        if (this.props.body) {
            window.open(`${staticUrl}/arquivos?type=${type}&body=${JSON.stringify(this.props.body)}`, "_blank");
        }
    }

    render() {
        return (
            <ButtonDropdown isOpen={this.state.dropdownOpen[0]} toggle={() => {
                this.toggle(0);
            }}>
                <DropdownToggle color="success" caret>
                    Exportar
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => this.handle('xls')}>XLS</DropdownItem>
                    <DropdownItem onClick={() => this.handle('xml')}>XML</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        );
    }
}

export default DownloadActions;