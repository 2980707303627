export const emptyMessage = 'Não Existem registros para serem exibidos';
export const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
export function dateFormatter(date) {
    return `${date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()}/${date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1}/${date.getUTCFullYear()}`
};
export function dateTimeFormatter(date) {
    return `${date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()}/${date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1}/${date.getUTCFullYear()} ${date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours()}:${date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes()}`
};
export const colorsFixed = ["#df9a0a",
    "#ffc0cb",
    "#c6e2ff",
    "#fda48d",
    "#ffe4e1",
    "#590000",
    "#008080",
    "#a1785d",
    "#fc8200",
    "#b1ee59",
    "#fce9f0",
    "#5c2045",
    "#4e3249",
    "#590000",
    "#800000",
    "#ffdfe5",
    "#ffc0cb",
    "#191919",
    "#000000",
    "#800000",
    "#ffa500",
    "#0000ff",
    "#40e0d0",
    "#ffd700",
    "#c6e2ff",
    "#ffe4e1",
    "#008080",
    "#ffffff",
    "#ffc0cb",
    "#000000",
    "#243a25",
    "#fda48d",
    "#fbe689",
    "#f8ce14",
    "#ff6666",
    "#bd59ed",
    "#80ff66",
    "#7662f1",
    "#f17662",
    "#66ccff",
    "#ff9966",
    "#ff6680"
];
export const colors = makeColors();
export function makeColors() {
    let colorsRandons = [];
    colorsFixed.map(() => {
        let index = Math.floor(Math.random() * colorsFixed.length);
        return colorsRandons.push(colorsFixed[index]);
    });
    return colorsRandons;
}
export function firstDayOfMonth(date = new Date()) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}
export function firstDayOfYear(date = new Date()) {
    return new Date(date.getFullYear(), 1, 1);
}
export function lastDayOfMonth(date = new Date()) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}
export function lastDayOfYear(date = new Date()) {
    return new Date(date.getFullYear(), 12, 0);
}
export function getAge(date) {
    return new Date().getFullYear() - new Date(date).getFullYear();
}
export const theme = {
    error: {
        trailColor: 'pink',
        color: 'red'
    },
    default: {
        trailColor: 'lightblue',
        color: 'blue'
    },
    success: {
        trailColor: 'lime',
        color: 'green'
    }
}
export const etapaConst = {
    CRIADO: 1,
    ENVIADO_APROVACAO: 2,
    ENVIADO_PARCEIRO: 3,
    APROVADO: 4,
    CONCLUIDO: 5,
    REPROVADO: 6,
    CANCELADO: 7
}