import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { fetchObj } from '../../actions';

class Pessoa extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ID: null,
      NOME: '',
      errors: {},
      loading: false,
      redirect: false,
      urlPessoa: '/pessoas',
      namePessoa: 'pessoa'
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.urlPessoa + this.props.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props }); 
          this.props.onChange(['ID'], this.state.ID, this.state.namePessoa);
        });
    }
  }

  saveObj = ({ ID, DESCRICAO }) => {
    if (ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, { ID, DESCRICAO });
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, { DESCRICAO });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.onChange([e.target.name], e.target.value, this.state.namePessoa);
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">Nome</Label>
            </Col>
            <Col xs="12" md="10">
              <Input
                name="NOME"
                value={this.state.NOME}
                onChange={this.handleChange}
                id="NOME"
                required
              />
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { fetchObj })(Pessoa);