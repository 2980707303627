import React, { Component } from 'react';
import { ButtonGroup, Button, Badge, Card, CardBody, CardHeader, CardFooter, Col, Row, Table, Label, Input, FormText, Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchObjs, fetchObj } from '../../actions';
import { emptyMessage, currencyFormatter, dateFormatter } from '../../utils/utils';
import swal from 'sweetalert2';
import months from '../../utils/months.json';
import years from '../../utils/years.json';
import DownloadActions from '../../containers/DownloadActions/DownloadActions';

class ContabilList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            months: months,
            years: years,
            filtro: [false],
            url: '/contabeis'
        };
        this.toogleFiltro = this.toogleFiltro.bind(this);
    }

    componentDidMount() {
        this.buscaDados();
    }

    buscaDados() {
        this.props.fetchObjs(`${this.state.url}?month=${this.state.month}&year=${this.state.year}`);
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    toogleFiltro(tab) {
        const prevState = this.state.filtro;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        this.setState({
            filtro: state,
        });
    }

    closeMonth() {
        return swal({
            title: "Deseja realmente fechar o mês?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, fechar',
            cancelButtonText: 'Cancelar',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false,
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                if (this.state.year && this.state.month) {
                    this.props.fetchObj(`${this.state.url}/closemonth/${this.state.month}/${this.state.year}`)
                        .then(() => {
                            swal("", "Fechamento realizado com sucesso!", "success");
                        });
                }
            }
        });
    }

    render() {
        return (
            <div className="animated fadeIn">
                {
                    this.props.hiddenFilter ? null :
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader id="headingOne">
                                        <Button
                                            block color="link"
                                            className="text-left m-0 p-0"
                                            onClick={() => this.toogleFiltro(0)}
                                            aria-expanded={this.state.filtro[0]}
                                            aria-controls="collapseOne">
                                            <strong>Filtros</strong>
                                        </Button>
                                    </CardHeader>
                                    <Collapse isOpen={this.state.filtro[0]} data-parent="#filtro" id="collapseOne" aria-labelledby="headingOne">
                                        <CardBody>
                                            <Row>
                                                <Col md="1">
                                                    <Label htmlFor="text-input">Mês</Label>
                                                </Col>
                                                <Col xs="4">
                                                    <Input
                                                        type="select"
                                                        name="month"
                                                        value={this.state.month}
                                                        onChange={this.handleChange}
                                                        id="month">
                                                        <option value="-1">Todos</option>
                                                        {
                                                            this.state.months ?
                                                                this.state.months.map((month, i) => {
                                                                    return (
                                                                        <option key={month.id} value={month.id}>{month.nome}</option>
                                                                    )
                                                                }) : null
                                                        }
                                                    </Input>
                                                    <FormText color="muted"></FormText>
                                                </Col>
                                                <Col md="1">
                                                    <Label htmlFor="text-input">Ano</Label>
                                                </Col>
                                                <Col xs="4">
                                                    <Input
                                                        type="select"
                                                        name="year"
                                                        value={this.state.year}
                                                        onChange={this.handleChange}
                                                        id="year">
                                                        <option value="-1">Todos</option>
                                                        {
                                                            this.state.years ?
                                                                this.state.years.map((year) => {
                                                                    return (
                                                                        <option key={year} value={year}>{year}</option>
                                                                    )
                                                                }) : null
                                                        }
                                                    </Input>
                                                    <FormText color="muted"></FormText>
                                                </Col>
                                                <Col xs="2">
                                                    <Button type="button" size="sm" color="primary" onClick={() => { this.buscaDados() }}><i className="fa fa-floppy-o"></i> Pesquisar</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                }
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Livro Contábil</strong>
                                    </Col>
                                    <Col  xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <ButtonGroup>
                                            <DownloadActions body={this.props.contabeis.length !== 0 ? this.props.contabeis : null} />
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.props.contabeis.length === 0 ? emptyMessage : ([
                                    <Table key="table" hover bordered striped responsive size="sm">
                                        <thead key="thead">
                                            <tr key="head">
                                                <th key="data">Data</th>
                                                <th key="valor">Valor</th>
                                                <th key="descricao">Descrição</th>
                                                <th key="tipo">Tipo</th>
                                            </tr>
                                        </thead>
                                        <tbody key="tbody">
                                            {this.props.contabeis.map((item, i) => {
                                                let tableClassName = item.VALOR > 0 ? "table-success" : "table-danger";
                                                return (
                                                    <tr key={`tr${i}`} className={tableClassName}>
                                                        <td key={`data${i}`}>{item && item.DATA ? dateFormatter(new Date(item.DATA)) : ''}</td>
                                                        <td key={`valor${i}`}>{item ? currencyFormatter.format(item.VALOR) : ''}</td>
                                                        <td key={`descricao${i}`}>{item ? item.DESCRICAO : ''}</td>
                                                        {item.VALOR > 0 ?
                                                            <td key={`tipoR${i}`}><Badge  key={`receita${i}`} color="success">Receita</Badge> - {item && item.tipoReceita ? item.tipoReceita.DESCRICAO : ''}</td>
                                                            :
                                                            <td key={`tipoD${i}`}><Badge  key={`despesa${i}`} color="danger">Despesa</Badge> - {item && item.tipoDespesa ? item.tipoDespesa.DESCRICAO : ''}</td>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                ])}
                            </CardBody>
                            {this.state.month !== "-1" && this.state.year !== "-1" ?
                                <CardFooter>
                                    <Button type="button" size="sm" color="success" onClick={() => { this.closeMonth() }}><i className="fa fa-floppy-o"></i> Fechar o Mês</Button>
                                </CardFooter>
                                : null}
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        contabeis: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, fetchObj })(ContabilList);

