import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { saveObj, fetchObj, fetchObjs, updateObj, deleteObj } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';

class Agenda extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      DESCRICAO: '',
      TITULO: '',
      LOCAL: '',
      DATA: '',
      errors: {},
      loading: false,
      redirect: false,
      url: '/agendas',
      nameObj: 'agenda'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props });
          let currentDate = new Date(this.state.DATA);
          currentDate.setHours(currentDate.getHours() - 3);
          this.setState({ DATA: currentDate.toISOString().replace(/\.([^.]+)$/, '>$1').split('>')[0] });
        });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
  };

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveObj(this.state)
      .then(() => this.props.history.push(this.state.url));
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardHeader>
                <strong>Cadastro</strong> Agendamentos
                </CardHeader>
              <CardBody>
                {this.state.ID ?
                  <FormGroup row>
                    <Col md="2">
                      <Label>Código</Label>
                    </Col>
                    <Col xs="12" md="10">
                      <p className="form-control-static">{this.state.ID}</p>
                    </Col>
                  </FormGroup>
                  : null}
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Título</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="TITULO"
                      value={this.state.TITULO}
                      onChange={this.handleChange}
                      id="TITULO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Descrição</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="DESCRICAO"
                      value={this.state.DESCRICAO}
                      onChange={this.handleChange}
                      id="DESCRICAO"
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Local</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="LOCAL"
                      value={this.state.LOCAL}
                      onChange={this.handleChange}
                      id="LOCAL"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Data e hora</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="DATA"
                      type="datetime-local"
                      value={this.state.DATA}
                      onChange={this.handleChange}
                      id="DATA"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, fetchObjs, updateObj, deleteObj })(Agenda);
