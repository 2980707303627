import React, { Component } from 'react';
import swal from 'sweetalert2';
import { Button } from 'reactstrap';

class ListActions extends Component {

    constructor(props) {
        super(props);
        let menus = JSON.parse(localStorage.getItem("usuarioLogado"))?.menus;
        const hash = window && window.location && window.location.hash;
        let permissionRead = false;
        let permissionWrite = false;
        let permissionDelete = false;
        menus.map(menu => {
            menu.children.map(children => {
                if (hash.includes(children.url)) {
                    if (children.menuUsuarios.length > 0) {
                        let menuUsuario = children.menuUsuarios[0];
                        permissionRead = menuUsuario.PERMISSION_READ;
                        permissionWrite = menuUsuario.PERMISSION_WRITE;
                        permissionDelete = menuUsuario.PERMISSION_DELETE;
                        return true;
                    }
                }
                return true;
            });
            return true;
        });

        if (!permissionRead) {
            this.props.history.push('dashboard');
            swal("Ops!", "Você não tem permissão para acessar esta página verifique! = )", "error");
        }

        this.state = {
            disableEdit: this.props.disableEdit || !permissionWrite,
            disableDelete: this.props.disableDelete || !permissionDelete
        };
    }

    deleteObj = (props, id) => {
        return swal({
            title: "Deseja realmente excluir o registro?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, excluir',
            cancelButtonText: 'Cancelar',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false,
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                if (id) {
                    return props.deleteObj(props.url, id);
                }
            }
        });
    }

    handleDelete = (id) => {
        this.deleteObj(this.props, id)
            .then((props) => this.props.fetchObjs(this.props.url));
    }

    handleEdit = () => {
        this.props.history.push(`${this.props.url}/${this.props.id}`);
    }

    render() {
        return (
            <div key={`div${this.props.id}`} className="text-center">
                <Button disabled={this.state.disableEdit} key={`edit${this.props.id}`} size="sm" color="primary" onClick={this.handleEdit}>
                    <i key={`editI${this.props.id}`} className="fa fa-pencil"></i>
                </Button>
                <Button disabled={this.state.disableDelete} key={`delete${this.props.id}`} size="sm" color="danger" onClick={() => this.handleDelete(this.props.id)}>
                    <i key={`deleteI${this.props.id}`} className="fa fa-trash"></i>
                </Button>
            </div>
        );
    }

}

export default ListActions;