import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Row, Button } from 'reactstrap';
import { fetchObj, fetchObjs } from '../../actions';
import FileBase64 from 'react-file-base64';

class BeneficiarioDocumento extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ID: null,
            URL: '',
            errors: {},
            loading: false,
            redirect: false,
            urlBeneficiarioDocumento: '/documentos/beneficiarios',
            nameBeneficiarioDocumento: 'beneficiariosDocumentos'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleDeleteDoc = this.handleDeleteDoc.bind(this);
        this.handleOpenDoc = this.handleOpenDoc.bind(this);
    }

    handleDeleteDoc = () => {
        let state = this.state;
        state.URL = '';
        state.DOCUMENTO = '';
        this.setState(state);
        this.props.onChange(["DOCUMENTO"], '', state.nameBeneficiarioDocumento, this.props.index);
        this.props.onChange(["URL"], '', state.nameBeneficiarioDocumento, this.props.index);
    }

    handleOpenDoc = () => {
        window.open(this.state.URL, "_blank");
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps,
            ID: nextProps.ID ? nextProps.ID : '',
            URL: nextProps.URL ? nextProps.URL : '',
        });
    }

    componentDidMount = () => {
        this.setState({
            ...this.props,
            ID: this.props.ID ? this.props.ID : '',
            URL: this.props.URL ? this.props.URL : '',
        });
        if (!this.props.contas) {
            this.props.fetchObjs('/contas', 1, 1000)
                .then(() => { this.setState({ contas: this.props.records }) });
        }
    }

    handleChange = (e) => {
        if (e.base64) {
            this.setState({ DOCUMENTO: e.base64 }); let state = this.state;
            state["DOCUMENTO"] = e.base64;
            this.setState(state);
            this.props.onChange(["DOCUMENTO"], e.base64, this.state.nameBeneficiarioDocumento, this.props.index);
        } else {
            this.setState({ [e.target.name]: e.target.value });
            let state = this.state;
            state[e.target.name] = e.target.value;
            this.setState(state);
            this.props.onChange([e.target.name], e.target.value, this.state.nameBeneficiarioDocumento, this.props.index);
        }
    }

    render() {
        return (
            <Row key={`rowBeneficiarioDocumento${this.props.index}`}>
                <Col xs="12" md="12" key={`colBeneficiarioDocumento${this.props.index}`}>
                    <FormGroup row key={`formgroupBeneficiarioDocumento${this.props.index}`}>
                        <Col xs="12" md="12">
                            {!this.state.URL ?
                                <div>
                                    <FileBase64
                                        name="DOCUMENTO"
                                        value={this.state.DOCUMENTO}
                                        id="DOCUMENTO"
                                        multiple={false}
                                        onDone={this.handleChange} />
                                </div>
                                :
                                <div>
                                    <Button key={`delete${this.props.key}`} size="sm" color="danger" onClick={() => this.handleDeleteDoc()}>
                                        <i key={`deleteI${this.props.key}`} className="fa fa-trash"></i>
                                    </Button>
                                    <Button key={`open${this.props.key}`} size="sm" color="primary" onClick={() => this.handleOpenDoc()}>
                                        <i key={`openI${this.props.key}`} className="fa fa-folder-open-o"></i>
                                    </Button>
                                    &nbsp;
                                    <a href={this.state.URL} className="form-control-static">{this.state.URL}</a>
                                </div>
                            }
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return { ...state.obj };
}

export default connect(mapStateToProps, { fetchObj, fetchObjs })(BeneficiarioDocumento);