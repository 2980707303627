import { SET_OBJ, OBJ_FETCHED } from './actions';
import swal from 'sweetalert2';


export function reducer(state = [], action = {}) {

  let retDef = [
    action.obj
  ];

  if (action.obj && action.obj.errorCode === 403) {
    let msg = "Ocorreu algum problema com esta operação! \n Faça Login novamente ou entre em contato com nosso suporte : ) ";
    msg = action.obj.userMessage ? action.obj.userMessage : msg;
    swal("Ops!", msg, "error");
    const hostname = window && window.location && window.location.hostname ? window.location.hostname : '';
    window.location = hostname.includes('cic.explorelabs.com.br') ? 'https://www.cic.explorelabs.com.br/#/loginpage' : 'http://localhost:8080/#/loginpage';
  }

  switch (action.type) {

    case OBJ_FETCHED:
      if (!action.obj || !action.obj.records) return retDef;
      return action.obj.records.length < 1 ? [] : action.obj.records[0];

    case SET_OBJ:
      return action.obj;

    default:
      return retDef;
  }
}
