import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row, Button, Collapse, ListGroup, ListGroupItem } from 'reactstrap';
import { saveObj, fetchObj, fetchObjs, updateObj, deleteObj } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';
import ImageCrop from '../../containers/ImageCrop/ImageCrop';
import { AppSwitch } from '@coreui/react'

class Usuario extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      LOGIN: '',
      IMAGEM: '',
      URL: '',
      uploadImage: {},
      pessoas: [],
      errors: {},
      menus: [],
      menusRemove: [],
      permission: [true],
      activeTab: 0,
      url: '/usuarios',
      nameObj: 'usuario'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeChild = this.handleChangeChild.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.tooglePermission = this.tooglePermission.bind(this);
    this.handlePermissionChange = this.handlePermissionChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => this.setState({ ...this.props }));

      this.props.fetchObjs(`/pessoas/usuarios/${match.params.id}`)
        .then(() => { this.setState({ pessoas: this.props.records }) });

    } else {
      this.props.fetchObjs('/pessoas/usuarios/new')
        .then(() => { this.setState({ pessoas: this.props.records }) });
    }
    let query = `/menus/unidades`;
    query += match.params.id ? `?usuario=${match.params.id}` : '';
    this.props.fetchObjs(query)
      .then(() => { this.setState({ menus: this.props.records }) });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeChild = (name, value) => {
    this.setState({ [name]: value });
  }

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveObj(this.state)
      .then(() => this.props.history.push(this.state.url));
  }

  handlePermissionChange = (e, index) => {
    let menus = this.state.menus;
    let children = menus[this.state.activeTab].children;
    children[index][e.target.name] = e.target.checked ? 1 : 0;

    if (e.target.checked && e.target.name === 'PERMISSION_WRITE') {
      children[index].PERMISSION_READ = e.target.checked ? 1 : 0;
    }
    if (e.target.checked && e.target.name === 'PERMISSION_DELETE') {
      children[index].PERMISSION_READ = e.target.checked ? 1 : 0;
      children[index].PERMISSION_WRITE = e.target.checked ? 1 : 0;
    }
    if (!e.target.checked && e.target.name === 'PERMISSION_READ') {
      children[index].PERMISSION_WRITE = e.target.checked ? 1 : 0;
      children[index].PERMISSION_DELETE = e.target.checked ? 1 : 0;
    }
    if (!e.target.checked && e.target.name === 'PERMISSION_WRITE') {
      children[index].PERMISSION_DELETE = e.target.checked ? 1 : 0;
    }

    let menusRemove = this.state.menusRemove;
    if (!e.target.checked && children[index].ID
      && (!children[index].PERMISSION_READ || e.target.name === 'PERMISSION_READ')
      && (!children[index].PERMISSION_WRITE || e.target.name === 'PERMISSION_WRITE')
      && (!children[index].PERMISSION_DELETE || e.target.name === 'PERMISSION_DELETE')) {
      menusRemove.push(children[index]);
    }
    if (e.target.checked && children[index].ID) {
      menusRemove.map((pRemove, iRemove) => {
        if (pRemove.ID === children[index].ID) {
          menusRemove.splice(iRemove, 1);
        }
        return true;
      });
    }
    menus[this.state.activeTab].children = children;
    this.setState({ menus, menusRemove });
  }


  tooglePermission(tab) {

    const prevState = this.state.permission;
    const state = prevState.map((x, index) => tab === index ? !x : false);

    this.setState({
      permission: state,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <strong>Cadastro</strong> Usuário
                </CardHeader>
            <CardBody>
              {this.state.ID ?
                <FormGroup row>
                  <Col md="2">
                    <Label>Código</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <p className="form-control-static">{this.state.ID}</p>
                  </Col>
                </FormGroup>
                : null}
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="text-input">Imagem</Label>
                </Col>
                <Col xs="12" md="5">
                  <ImageCrop onChange={this.handleChangeChild} {...this.state} />
                </Col>
              </FormGroup>
            </CardBody>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardBody>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Login</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="LOGIN"
                      value={this.state.LOGIN}
                      onChange={this.handleChange}
                      id="LOGIN"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Catequista</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="PESSOA"
                      value={this.state.PESSOA}
                      onChange={this.handleChange}
                      id="PESSOA"
                      disabled={this.state.ID ? true : false}
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.pessoas ?
                          this.state.pessoas.map((pessoa, i) => {
                            return (
                              <option key={pessoa.ID} value={pessoa.ID}>{pessoa.NOME}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
              </CardBody>
              <CardBody>
                <Card>
                  <CardHeader id="headingOne">
                    <Button
                      block color="link"
                      className="text-left m-0 p-0"
                      onClick={() => this.tooglePermission(0)}
                      aria-expanded={this.state.permission[0]}
                      aria-controls="collapseOne">
                      <strong>Permissões</strong>
                    </Button>
                  </CardHeader>
                  <Collapse isOpen={this.state.permission[0]} data-parent="#permission" id="collapseOne" aria-labelledby="headingOne">
                    <CardBody>
                      <Row>
                        <Col xs="4">
                          <ListGroup id="list-tab" role="tablist">
                            {
                              this.state.menus ?
                                this.state.menus.map((item, i) => {
                                  return (
                                    <ListGroupItem key={`ListGroupItemMenu${i}`} onClick={() => this.toggle(i)} action active={this.state.activeTab === i} >{item.name}</ListGroupItem>
                                  )
                                }) : null
                            }
                          </ListGroup>
                        </Col>
                        <Col xs="8">
                          <ListGroup id="list-tab" role="tablist">
                            {
                              this.state.menus && this.state.menus[this.state.activeTab] ?
                                this.state.menus[this.state.activeTab].children.map((item, index) => {
                                  return (
                                    <ListGroupItem action key={`ListGroupItemChildren${index}`}>
                                      <Row key={`RowChildren${index}`}>
                                        <Col key={`ColNameChildren${index}`} md="2" xs="12">
                                          {item.name}
                                        </Col>
                                        <Col key={`ColPermissionsChildren${index}`} md="10" xs="12">
                                          <Row key={`RowPermissionsChildren${index}`}>
                                            <Col key={`ColLeituraChildren${index}`} md="2" xs="6" style={{ textAlign: 'right' }}>
                                              Leitura
                                            </Col>
                                            <Col key={`ColLeituraSwitchChildren${index}`} md="2" xs="6" style={{ textAlign: 'left' }}>
                                              <AppSwitch
                                                name="PERMISSION_READ"
                                                checked={item.PERMISSION_READ === 1}
                                                value={item.PERMISSION_READ + ""}
                                                onChange={(e) => { this.handlePermissionChange(e, index) }}
                                                id="PERMISSION_READ"
                                                className={'mx-1'}
                                                variant={'pill'}
                                                color={'primary'}
                                                outline={'alrt'}
                                                label
                                                dataOn={'\u2713'}
                                                dataOff={'\u2715'} />
                                            </Col>
                                            <Col key={`ColEscritaChildren${index}`} md="2" xs="6" style={{ textAlign: 'right' }}>
                                              Escrita
                                            </Col>
                                            <Col key={`ColEscritaSwitchChildren${index}`} md="2" xs="6" style={{ textAlign: 'left' }}>
                                              <AppSwitch
                                                name="PERMISSION_WRITE"
                                                checked={item.PERMISSION_WRITE === 1}
                                                value={item.PERMISSION_WRITE + ""}
                                                onChange={(e) => { this.handlePermissionChange(e, index) }}
                                                id="PERMISSION_WRITE"
                                                className={'mx-1'}
                                                variant={'pill'}
                                                color={'primary'}
                                                outline={'alrt'}
                                                label
                                                dataOn={'\u2713'}
                                                dataOff={'\u2715'} />
                                            </Col>
                                            <Col key={`ColDelecaoChildren${index}`} md="2" xs="6" style={{ textAlign: 'right' }}>
                                              Deleção
                                            </Col>
                                            <Col key={`ColDelecaoSwitchChildren${index}`} md="2" xs="6" style={{ textAlign: 'left' }}>
                                              <AppSwitch
                                                name="PERMISSION_DELETE"
                                                checked={item.PERMISSION_DELETE === 1}
                                                value={item.PERMISSION_DELETE + ""}
                                                onChange={(e) => { this.handlePermissionChange(e, index) }}
                                                id="PERMISSION_DELETE"
                                                className={'mx-1'}
                                                variant={'pill'}
                                                color={'primary'}
                                                outline={'alrt'}
                                                label
                                                dataOn={'\u2713'}
                                                dataOff={'\u2715'} />
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </ListGroupItem>
                                  )
                                }) : null
                            }
                          </ListGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col >
      </Row >
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, updateObj, deleteObj, fetchObjs })(Usuario);