import React, { Component } from 'react';
import swal from 'sweetalert2';
import { Button, CardFooter } from 'reactstrap';

class CardFooterActions extends Component {

    constructor(props) {
        super(props);

        let menus = JSON.parse(localStorage.getItem("usuarioLogado")).menus;
        const hash = window && window.location && window.location.hash;
        let permissionRead = false;
        let permissionWrite = false;
        let permissionDelete = false;
        menus.map(menu => {
            menu.children.map(children => {
                if (hash.includes(children.url)) {
                    if (children.menuUsuarios.length > 0) {
                        let menuUsuario = children.menuUsuarios[0];
                        permissionRead = menuUsuario.PERMISSION_READ;
                        permissionWrite = menuUsuario.PERMISSION_WRITE;
                        permissionDelete = menuUsuario.PERMISSION_DELETE;
                        return true;
                    }
                }
                return true;
            });
            return true;
        });

        if (!permissionRead) {
            this.props.history.push('dashboard');
            swal("Ops!", "Você não tem permissão para acessar esta página verifique! = )", "error");
        }

        this.state = {
            hiddenSave: this.props.hiddenSave || !permissionWrite,
            hiddenDelete: this.props.hiddenDelete || !permissionDelete,
            id: this.props.id ? this.props.id : this.props.match && this.props.match.params.id ? this.props.match.params.id : ''
        };

    }

    deleteObj = (props) => {
        let state = this.state;
        return swal({
            title: "Deseja realmente excluir o registro?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, excluir',
            cancelButtonText: 'Cancelar',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false,
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                if (state.id) {
                    return props.deleteObj(props.url, state.id);
                }
            }
        });
    }

    handleDelete = () => {
        this.deleteObj(this.props)
            .then((props) => this.props.history.push(this.props.url));
    }

    render() {
        return (
            <CardFooter>
                {!this.state.hiddenSave ? <Button type="submit" size="sm" color="success"><i className="fa fa-floppy-o"></i> Salvar</Button> : null}
                {!this.state.hiddenDelete && !Number.isNaN(this.state.id) ? <Button type="reset" size="sm" color="danger" onClick={() => this.handleDelete()}><i className="fa fa-trash"></i> Deletar</Button> : null}
                {!this.props.hiddenBack && !this.props.isModal ? <Button type="reset" size="sm" color="primary" onClick={() => this.props.history.push(this.props.url)}><i className="fa fa-arrow-circle-left"></i> Voltar</Button> : null}
            </CardFooter>
        );
    }
}

export default CardFooterActions;