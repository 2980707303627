import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { saveObj, fetchObj, fetchObjs, updateObj, deleteObj } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';

class Conta extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      BANCO: '',
      AGENCIA: '',
      CONTA: '',
      TIPO_CONTA: '',
      DESCRICAO: '',
      tiposcontas: [],
      errors: {},
      loading: false,
      redirect: false,
      url: '/contas',
      nameObj: 'conta'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => this.setState({ ...this.props }));
    }
    this.props.fetchObjs('/tiposcontas', 1, 1000)
      .then(result => { this.setState({ tiposcontas: this.props.records }) });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveObj(this.state)
      .then(() => this.props.history.push(this.state.url));
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardHeader>
                <strong>Cadastro</strong> Conta bancária
                </CardHeader>
              <CardBody>
                {this.state.ID ?
                  <FormGroup row>
                    <Col md="2">
                      <Label>Código</Label>
                    </Col>
                    <Col xs="12" md="10">
                      <p className="form-control-static">{this.state.ID}</p>
                    </Col>
                  </FormGroup>
                  : null}
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Banco</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="BANCO"
                      value={this.state.BANCO}
                      onChange={this.handleChange}
                      id="BANCO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Agência</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="AGENCIA"
                      value={this.state.AGENCIA}
                      onChange={this.handleChange}
                      id="AGENCIA"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Conta</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="CONTA"
                      value={this.state.CONTA}
                      onChange={this.handleChange}
                      id="CONTA"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Tipo</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="TIPO_CONTA"
                      value={this.state.TIPO_CONTA}
                      onChange={this.handleChange}
                      id="TIPO_CONTA"
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.tiposcontas ?
                          this.state.tiposcontas.map((tipoConta, i) => {
                            return (
                              <option key={tipoConta.ID} value={tipoConta.ID}>{tipoConta.DESCRICAO}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Descrição</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="DESCRICAO"
                      value={this.state.DESCRICAO}
                      onChange={this.handleChange}
                      id="DESCRICAO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, fetchObjs, updateObj, deleteObj })(Conta);
