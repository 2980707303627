import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { saveObj, fetchObjs, fetchObj, updateObj, deleteObj } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';

class Meta extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      DESCRICAO: '',
      CATEQUISTA: '',
      STATUS: '',
      IMAGEM: '',
      URL: '',
      uploadImage: {},
      statusList: [],
      catequistas: [],
      errors: {},
      loading: false,
      redirect: false,
      url: '/metas',
      nameObj: 'meta'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeChild = this.handleChangeChild.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props });
        });
    }
    this.props.fetchObjs('/status', 1, 1000)
      .then(() => { this.setState({ statusList: this.props.records || [] }) });
    this.props.fetchObjs('/funcionarios', 1, 1000)
      .then(() => { this.setState({ catequistas: this.props.records || [] }) });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeChild = (name, value) => {
    this.setState({ [name]: value });
  }

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveObj(this.state)
      .then((props) => this.props.history.push(this.state.url));
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <strong>Cadastro</strong> Invenstimento
                </CardHeader>
            <CardBody>
              {this.state.ID ?
                <FormGroup row>
                  <Col md="2">
                    <Label>Código</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <p className="form-control-static">{this.state.ID}</p>
                  </Col>
                </FormGroup>
                : null}
            </CardBody>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardBody>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Descrição</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="DESCRICAO"
                      value={this.state.DESCRICAO}
                      onChange={this.handleChange}
                      id="DESCRICAO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Responsável</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="CATEQUISTA"
                      value={this.state.CATEQUISTA}
                      onChange={this.handleChange}
                      id="CATEQUISTA"
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.catequistas ?
                          this.state.catequistas.map((catequista, i) => {
                            return (
                              <option key={catequista.ID} value={catequista.ID}>{`${catequista.pessoaFisica.pessoa.NOME}`}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Status</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="STATUS"
                      value={this.state.STATUS}
                      onChange={this.handleChange}
                      id="STATUS"
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.statusList ?
                          this.state.statusList.map((statusList, i) => {
                            return (
                              <option key={statusList.ID} value={statusList.ID}>{statusList.DESCRICAO}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, updateObj, deleteObj, fetchObjs })(Meta);