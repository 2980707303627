import React, { Component } from 'react';
import { ButtonGroup, Button, Card, CardBody, CardHeader, Col, Row, Table, FormGroup, Collapse, Label } from 'reactstrap';
import TablePaginator from '../../containers/TablePaginator/TablePaginator';
import ListActions from '../../containers/ListActions/ListActions';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj } from '../../actions';
import DownloadActions from '../../containers/DownloadActions/DownloadActions';
import { DateRange } from 'react-date-range';
import locale from 'date-fns/locale/pt';
import { firstDayOfYear, lastDayOfYear } from '../../utils/utils';
import months from '../../utils/months.json';

const emptyMessage = (<p>Não Existem registros para serem exibidos.</p>);

class AgendaList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: '/agendas',
            filtro: [false],
            start: firstDayOfYear(),
            end: lastDayOfYear(),
            selectionRange: {
                startDate: firstDayOfYear(),
                endDate: lastDayOfYear(),
                key: 'selection',
            },
        }
        this.toogleFiltro = this.toogleFiltro.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.buscaDados(this.state);
    }

    buscaDados(state) {
        let start = state.start ? state.start.toISOString() : null;
        let end = state.end ? state.end.toISOString() : null;
        let startQuery = start ? `start=${start}` : null;
        let endQuery = end ? `end=${end}` : null;
        let query = start && end ? `?${startQuery}&${endQuery}` : start ? `?${startQuery}` : end ? `?${endQuery}` : '';
        this.props.fetchObjs(`${state.url}${query}`)
            .then(() => {
                this.setState(state);
            });
    }

    toogleFiltro(tab) {
        const prevState = this.state.filtro;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        this.setState({
            filtro: state,
        });
    }

    handleSelect(selection) {
        this.setState({ start: selection.selection.startDate, end: selection.selection.endDate, selectionRange: selection.selection });
        this.buscaDados({ start: selection.selection.startDate, end: selection.selection.endDate, url: this.state.url});
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader id="headingOne">
                                <Button
                                    block color="link"
                                    className="text-left m-0 p-0"
                                    onClick={() => this.toogleFiltro(0)}
                                    aria-expanded={this.state.filtro[0]}
                                    aria-controls="collapseOne">
                                    <strong>Filtros</strong>
                                </Button>
                            </CardHeader>
                            <Collapse isOpen={this.state.filtro[0]} data-parent="#filtro" id="collapseOne" aria-labelledby="headingOne">
                                <CardBody>
                                    <FormGroup row>
                                        <Col md="2">
                                            <Label htmlFor="text-input">Período</Label>
                                        </Col>
                                        <Col xs="12" md="10">
                                            <DateRange
                                                ranges={[this.state.selectionRange]}
                                                onChange={this.handleSelect}
                                                locale={locale}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="horizontal"
                                            />
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Agenda</strong>
                                    </Col>
                                    <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <ButtonGroup>
                                            <Button type="button" size="sm" color="primary" onClick={() => this.props.history.push(`${this.state.url}/new`)}>
                                                <i className="fa fa-plus"></i> Novo
                                        </Button>
                                            <DownloadActions body={this.props.agendas.length !== 0 ? this.props.agendas : null} />
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.props.agendas.length === 0 ? emptyMessage : ([
                                    <Table key="table" hover bordered striped responsive size="sm">
                                        <thead key="thead">
                                            <tr key="head">
                                                <th key="id">Código</th>
                                                <th key="monthandyear">Mês e Ano</th>
                                                <th key="datetime">Data e Hora</th>
                                                <th key="title">Título</th>
                                                <th key="local">Local</th>
                                                <th xs="12" sm="1" key="acoes" className="text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody key="tbody">
                                            {this.props.agendas.map((agenda, i) => {
                                                return (
                                                    <tr key={`tr${agenda.ID}`}>
                                                        <td key={`tdId${agenda.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${agenda.ID}`)}>{agenda ? agenda.ID : ''}</td>
                                                        <td key={`tdMonthAndYear${agenda.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${agenda.ID}`)}>{agenda ? `${months[new Date(agenda.DATA).getMonth()]?.nome} ${new Date(agenda.DATA).getFullYear()}` : ''}</td>
                                                        <td key={`tdDate${agenda.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${agenda.ID}`)}>{agenda ? new Date(agenda.DATA).toLocaleString() : ''}</td>
                                                        <td key={`tdTitle${agenda.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${agenda.ID}`)}>{agenda ? agenda.TITULO : ''}</td>
                                                        <td key={`tdLocal${agenda.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${agenda.ID}`)}>{agenda ? agenda.LOCAL : ''}</td>
                                                        <td key={`tdAction${agenda.ID}`} ><ListActions {...this.props} {...this.state} id={agenda.ID} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>,
                                    <TablePaginator key="tablePaginator" {...this.props} {...this.state} />
                                ])}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        agendas: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(AgendaList);

