import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, FormGroup, FormText, Input, Label, Row, Form, Button, Collapse, Table } from 'reactstrap';
import { saveObj, fetchObj, fetchObjs, updateObj, deleteObj } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';
import Dropzone from 'react-dropzone';
import AtividadeFoto from '../AtividadeFoto/AtividadeFoto';
import Select from 'react-select';
import { AppSwitch } from '@coreui/react'
import { emptyMessage } from '../../utils/utils';

import '../../containers/ImageCrop/darkroom.css';

class Atividade extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      NOME: '',
      DESCRICAO: '',
      DATA: '',
      TIPO_ATIVIDADE: '',
      REALIZADA: 1,
      SHOW_CALENDARIO: 1,
      tiposatividades: [],
      atividadeFotos: [],
      atividadeFotosRemove: [],
      presencas: [],
      presencasRemove: [],
      atividadeTurmas: [],
      atividadeTurmasRemove: [],
      turmas: [],
      presenca: [true],
      errors: {},
      url: '/atividades',
      nameObj: 'atividade'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeChild = this.handleChangeChild.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.get = this.onFileChange.bind(this);
    this.tooglePresenca = this.tooglePresenca.bind(this);
    this.handlePresencaChange = this.handlePresencaChange.bind(this);
    this.handleToogleChange = this.handleToogleChange.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match && match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props });
          this.setState({ DATA: new Date(this.state.DATA).toISOString().split('T')[0] });
          this.props.fetchObjs(`/turmas/atividades/${match.params.id}`)
            .then(() => {
              let atividadeTurmas = this.props.records ? this.props.records : [];
              let turmas = [];
              atividadeTurmas.map((element) => {
                turmas.push(element.turma.ID);
                return element.label = element.value = `${element.turma.DESCRICAO} - ${element.turma.ANO} - ${element.turma.catequista.pessoaFisica.pessoa.NOME}`;
              });
              this.setState({ atividadeTurmas });
              this.buscaPresenca(turmas, match.params.id);
            });
        });
    }
    this.props.fetchObjs('/turmas', 1, 1000)
      .then(() => {
        let turmas = this.props.records;
        let atividadeTurmas = [];
        turmas.map((element) => {
          return atividadeTurmas.push({
            label: `${element.DESCRICAO} - ${element.ANO} - ${element.catequista.pessoaFisica.pessoa.NOME}`,
            value: `${element.DESCRICAO} - ${element.ANO} - ${element.catequista.pessoaFisica.pessoa.NOME}`,
            turma: element
          });
        });
        this.setState({ turmasOptions: atividadeTurmas });
      });
    this.props.fetchObjs('/tiposatividades')
      .then(() => { this.setState({ tiposatividades: this.props.records }) });
  }

  filterTurmas = (inputValue) => {
    return this.state.turmasOptions.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  handleChangeTurma = (newValue, actionMeta) => {
    newValue = newValue ? newValue : [];
    let turmas = [];
    newValue.map((element) => {
      return turmas.push(element.turma.ID);
    });
    this.buscaPresenca(turmas, this.state.ID);
    let { atividadeTurmasRemove } = this.state;
    if (actionMeta.action === 'remove-value' && actionMeta.removedValue.ID) {
      atividadeTurmasRemove.push(actionMeta.removedValue);
    }
    this.setState({ atividadeTurmas: newValue, atividadeTurmasRemove });
  };

  buscaPresenca = (turmas, idAtividade) => {
    let query = `/presencas?turmas=${JSON.stringify(turmas)}${this.state.ID ? `&atividade=${idAtividade}` : ''}`;
    this.props.fetchObjs(query)
      .then(() => {
        //verifica os remove
        let presencasOld = this.state.presencas || [];
        let presencasNew = this.props.records || [];
        let presencasRemove = this.state.presencasRemove || [];
        //add olds to remove
        presencasOld.map(pOld => {
          if (pOld.ID) {
            let hasOnNew = false;
            presencasNew.map(pNew => {
              if (pNew.ID && pOld.ID === pNew.ID) {
                hasOnNew = true;
              }
              return true;
            });
            if (!hasOnNew) {
              presencasRemove.push(pOld);
            }
          }
          return true;
        });
        //remove news from remove
        presencasNew.map(pNew => {
          if (pNew.ID) {
            let hasOnNew = false;
            let index = -1;
            presencasRemove.map((pRemove, iRemove) => {
              if (pRemove.ID && pRemove.ID === pNew.ID) {
                hasOnNew = true;
                index = iRemove;
              }
              return true;
            });
            if (!hasOnNew) {
              presencasRemove.slice(index, 1);
            }
          }
          return true;
        });
        this.setState({ presencas: presencasNew, presencasRemove })
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleToogleChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked ? 1 : 0 });
  }

  handlePresencaChange = (e, index) => {
    let presencas = this.state.presencas;
    presencas[index][e.target.name] = e.target.checked;

    let presencasRemove = this.state.presencasRemove;
    if (!e.target.checked && presencas[index].ID) {
      presencasRemove.push(presencas[index]);
    }
    if (e.target.checked && presencas[index].ID) {
      presencasRemove.map((pRemove, iRemove) => {
        if (pRemove.ID === presencas[index].ID) {
          presencasRemove.splice(iRemove, 1);
        }
        return true;
      });
    }
    this.setState({ presencas, presencasRemove });
  }

  handleChangeChild = (name, value, objName, index) => {
    let obj = this.state[objName];
    if (index !== undefined && index !== null) {
      obj[index][name] = value;
    } else {
      obj[name] = value;
    }
    this.setState({ [objName]: obj });
  }

  handleDeleteChild = (index) => {
    let fotoAtividade = this.state.atividadeFotos[index];
    if (fotoAtividade.ID) {
      let atividadeFotosRemove = this.state.atividadeFotosRemove;
      atividadeFotosRemove.push(fotoAtividade);
      this.setState({ atividadeFotosRemove });
    }
    let atividadeFotos = this.state.atividadeFotos;
    atividadeFotos.splice(index, 1);
    this.setState({ atividadeFotos });
  }

  tooglePresenca(tab) {
    const prevState = this.state.presenca;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState({
      presenca: state,
    });
  }

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveObj(this.state)
      .then(() => this.props.history.push(this.state.url));
  }

  onFileChange(e) {
    let atividadeFotos = this.state.atividadeFotos;
    e.map((file, i) => {
      let reader = new FileReader();
      reader.onload = e => atividadeFotos[i] = { BASE64: e.target.result };
      reader.readAsDataURL(file);
      return atividadeFotos.push({ IMAGEM: file });
    });
    this.setState({ atividadeFotos });
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <strong>Cadastro</strong> Atividade
                </CardHeader>
            <CardBody>
              {this.state.ID ?
                <FormGroup row>
                  <Col md="2">
                    <Label>Código</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <p className="form-control-static">{this.state.ID}</p>
                  </Col>
                </FormGroup>
                : null}
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="text-input">Imagens</Label>
                </Col>
                <Col xs="10" md="10">
                  <Dropzone
                    style={{ width: "100%", height: 150, borderWidth: "medium", borderStyle: "dashed", borderColor: "#808080" }}
                    multiple={true} accept="image/*"
                    onDrop={this.onFileChange}>
                    <p style={{ color: "#808080", textAlign: "center" }}>Arraste aqui as imagens da sua atividade!</p>
                  </Dropzone>
                  <Row>
                    {
                      this.state.atividadeFotos ? this.state.atividadeFotos.map((item, i) => {
                        return (
                          <AtividadeFoto key={`atividadeFotoKey${i}`} {...item.IMAGEM} {...item} index={i} onChange={this.handleChangeChild} onDelete={this.handleDeleteChild}></AtividadeFoto>
                        );
                      }) : null
                    }
                  </Row>
                </Col>
              </FormGroup>
            </CardBody>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardBody>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Nome</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="NOME"
                      value={this.state.NOME}
                      onChange={this.handleChange}
                      id="NOME"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Descrição</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="textarea"
                      rows="5"
                      name="DESCRICAO"
                      value={this.state.DESCRICAO}
                      onChange={this.handleChange}
                      id="DESCRICAO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Data</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="date"
                      name="DATA"
                      value={this.state.DATA}
                      onChange={this.handleChange}
                      id="DATA"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Tipo</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="TIPO_ATIVIDADE"
                      value={this.state.TIPO_ATIVIDADE}
                      onChange={this.handleChange}
                      id="TIPO_ATIVIDADE"
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.tiposatividades ?
                          this.state.tiposatividades.map((tipoAtividade) => {
                            return (
                              <option key={tipoAtividade.ID} value={tipoAtividade.ID}>{tipoAtividade.DESCRICAO}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Turmas</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Select
                      isMulti
                      onChange={this.handleChangeTurma}
                      options={this.state.turmasOptions}
                      value={this.state.atividadeTurmas}
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Atividade realizada?</Label>
                  </Col>
                  <Col xs="12" md="3">
                    <Input
                      type="checkbox"
                      name="REALIZADA"
                      checked={this.state.REALIZADA}
                      value={this.state.REALIZADA + ""}
                      onChange={(e) => { this.handleToogleChange(e) }}
                      id="REALIZADA" />
                  </Col>
                  <Col md="3">
                    <Label htmlFor="text-input">Mostrar no calendário?</Label>
                  </Col>
                  <Col xs="12" md="3">
                    <Input
                      type="checkbox"
                      name="SHOW_CALENDARIO"
                      checked={this.state.SHOW_CALENDARIO}
                      value={this.state.SHOW_CALENDARIO + ""}
                      onChange={(e) => { this.handleToogleChange(e) }}
                      id="SHOW_CALENDARIO" />
                  </Col>
                </FormGroup>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="12" sm="12">
                        <Button
                          block color="link"
                          className="text-left m-0 p-0"
                          onClick={() => this.tooglePresenca(0)}
                          aria-expanded={this.state.presenca[0]}
                          aria-controls="collapseOne"
                          style={{ color: 'black' }}>
                          <strong>Presencas</strong>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.presenca[0]} data-parent="#presenca" id="collapseOne" aria-labelledby="headingOne">
                    <CardBody>
                      {this.state.presencas && this.state.presencas.length !== 0 ? (
                        <Table key="table" hover bordered striped responsive size="sm">
                          <thead key="thead">
                            <tr key="head">
                              <th key="nome">Nome</th>
                              <th key="presenca">Presença</th>
                            </tr>
                          </thead>
                          <tbody key="tbody">
                            {this.state.presencas.map((presenca, index) => {
                              return (
                                <tr key={`tr${presenca.NOME}`}>
                                  <td key={`tdId${presenca.NOME}`} >{presenca.NOME ? presenca.NOME : ''}</td>
                                  <td key={`tdVigencia${presenca.ID}`} >
                                    <AppSwitch
                                      name="PRESENCA"
                                      checked={presenca.PRESENCA}
                                      value={presenca.PRESENCA + ""}
                                      onChange={(e) => { this.handlePresencaChange(e, index) }}
                                      id="PRESENCA"
                                      className={'mx-1'}
                                      variant={'pill'}
                                      color={'primary'}
                                      outline={'alt'}
                                      label
                                      dataOn={'\u2713'}
                                      dataOff={'\u2715'} />
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      ) : emptyMessage}
                    </CardBody>
                  </Collapse>
                </Card>
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, fetchObjs, updateObj, deleteObj })(Atividade);