import React, { Component } from 'react';
import { Form, Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { sendObj, staticFrontUrl } from '../../../actions';
import { connect } from 'react-redux';

class Remind extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      nameObj: 'usuario',
      url: '/usuarios/remind'
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  remind = (event) => {
    event.preventDefault();
    
    let usuario = {}
    usuario.EMAIL = this.state.email;
    usuario.urlFront = staticFrontUrl;

    this.props.sendObj(this.state.url, this.state.nameObj, usuario)
      .then(user => {
        this.props.history.push('loginpage');
      });
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">

                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.remind}>
                  <CardBody className="p-4">
                    <h1>Esqueci a Senha</h1>
                    <p className="text-muted">Seja bem vindo, digite seu email, e enviaremos um link para alteração de senha!</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="email" placeholder="e-mail" name="email" onChange={this.handleChange} required/>
                    </InputGroup>
                    <Button type="submit" color="success" block >Enviar</Button>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { sendObj })(Remind);
