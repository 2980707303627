import { Darkroom, Canvas, History, Toolbar, CropMenu, Transform } from 'react-darkroom';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';
import swal from 'sweetalert2';

import './darkroom.css';

const canvasWidth = 300;
const canvasHeight = 320;

function fileController(thread = { source: null }, action) {
    switch (action.type) {
        case 'SET_FILE':
            return Object.assign({}, thread, {
                source: action.file,
                angle: 0
            });
        default:
            return thread;
    }
}

function imageController(thread = { crop: false, source: null, angle: 0 }, action) {
    switch (action.type) {
        case 'ROTATE_LEFT':
            return Object.assign({}, thread, {
                angle: thread.angle - 90
            });
        case 'ROTATE_RIGHT':
            return Object.assign({}, thread, {
                angle: thread.angle + 90
            });
        case 'START_CROPPING':
            return Object.assign({}, thread, {
                crop: true
            });
        case 'STOP_CROPPING':
            return Object.assign({}, thread, {
                crop: false
            });
        case 'CONFIRM_CROP':
            return Object.assign({}, thread, {
                crop: false,
                source: action.image
            });
        default:
            return thread;
    }
}

function readFile(file, done) {
    let reader = new FileReader();
    reader.onload = e => done(e.target.result);
    reader.readAsDataURL(file);
}

export default class ImageCrop extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deletedImage: false,
            step: 0,
            thread: [{ crop: false, source: null, angle: 0 }]
        };
        ['onFileChange',
            'update',
            'onRedo',
            'onUndo',
            'onRotateLeft',
            'onCropStart',
            'onCropConfirm',
            'onCropCancel',
            'onRotateRight'].forEach((method) => {
                this[method] = this[method].bind(this);
            });
    }

    update(action) {
        const state = this.state;
        let nextThread;
        let nextStep = state.thread.length;
        let newState;
        let newThread;

        switch (action.type) {
            case "SET_FILE":
                nextThread = fileController(state.thread[state.step], action);
                break;

            case "UNDO":
                nextStep = state.step - 1;
                break;

            case "REDO":
                nextStep = state.step + 1;
                break;

            case "ROTATE_LEFT":
            case "ROTATE_RIGHT":
            case "START_CROPPING":
            case "STOP_CROPPING":
            case "CONFIRM_CROP":
                nextThread = imageController(state.thread[state.step], action);
                break;
            default:
                return null;

        }

        if ((action.type !== "UNDO" && action.type !== "REDO") &&
            (state.step > 0 && state.step < state.thread.length - 1)) {
            newThread = [
                ...state.thread.slice(0, state.step),
                nextThread
            ];
            nextStep = newThread.length - 1;
        } else {
            newThread = nextThread ? [...state.thread, nextThread] : [].concat(state.thread);
        }

        newState = Object.assign({}, state, {
            step: nextStep,
            thread: newThread
        });

        this.setState(newState);
        this.props.onChange(this.props.name || "IMAGEM", newState.thread[newState.thread.length - 1].source);
    }

    onFileChange(e) {
        let files = Array.isArray(e) ? e[0] : e.target.files[0];
        readFile(files, file => {
            this.update({ type: 'SET_FILE', file });
        });
    }

    onUndo() {
        this.update({ type: 'UNDO' });
    }

    onRedo() {
        this.update({ type: 'REDO' });
    }

    onRotateLeft() {
        this.update({ type: 'ROTATE_LEFT' });
    }

    onRotateRight() {
        this.update({ type: 'ROTATE_RIGHT' });
    }

    onCropStart() {
        this.update({ type: 'START_CROPPING' });
    }

    onCropCancel() {
        this.update({ type: 'STOP_CROPPING' });
    }

    onCropConfirm() {

        let { source } = this.state.thread[this.state.step];
        let { x, y, width, height } = this.refs.canvasWrapper.cropBox;

        Transform.cropImage(source, { x, y, width, height }, { width: canvasWidth, height: canvasHeight })
            .then(image => this.update({ type: 'CONFIRM_CROP', image }));
    }

    handleDelete = (id) => {
        this.setState({ deletedImage: true });
    }

    handleOpenImage = () => {
        swal({
            imageUrl: this.props.URL,
            imageHeight: "100%",
            imageWidth: "100%",
            imageAlt: `${this.props.nomeObj}_${this.props.ID}`,
            customClass: 'swal-large',
            showConfirmButton: false,
        });
    }

    render() {
        let current = this.state.thread[this.state.step];
        let { angle, source, crop } = current;
        let hasFile = source !== null;

        let selectFile = () => {
            let name = `fileselect${this.props.name || "IMAGEM"}`;
            document.getElementById(name).click();
        };

        return (

            <div style={{ padding: "1em" }}>
                {
                    this.props.ID && this.props.URL && !this.state.deletedImage ?
                        <div>
                            <div>
                                <Button key={`delete${this.props.key}`} size="sm" color="danger" onClick={() => this.handleDelete(this.props.id)}>
                                    <i key={`deleteI${this.props.key}`} className="fa fa-trash"></i>
                                </Button>
                                <Button key={`open${this.props.key}`} size="sm" color="primary" onClick={() => this.handleOpenImage()}>
                                    <i key={`openI${this.props.key}`} className="fa fa-folder-open-o"></i>
                                </Button>
                            </div>
                            <div style={{ padding: 10 }}>
                                <img style={{ minWidth: 25, maxWidth: canvasWidth-10, minHeight: 25, maxHeight: canvasHeight-10, padding: 10 }} alt={`${this.props.nomeObj}_${this.props.ID}`} src={this.props.URL} onClick={() => this.handleOpenImage()}></img>
                            </div>
                        </div>
                        :
                        <Darkroom key={this.props.name || "IMAGEM"} name={this.props.name || "IMAGEM"} >
                            <form>
                                <Toolbar key={this.props.name || "IMAGEM"} name={this.props.name || "IMAGEM"}>
                                    <button type="button" key={this.props.name || "IMAGEM"} name={this.props.name || "IMAGEM"} onClick={selectFile} data-tipsy="Selecionar Imagem" className="tipsy tipsy--s">
                                        <span className="icon icon-image" />
                                        <input type="file" id={`fileselect${this.props.name || "IMAGEM"}`} name={this.props.name || "IMAGEM"} onChange={this.onFileChange} style={{ display: 'none' }} />
                                    </button>
                                    <History step={this.state.step} length={this.state.thread.length - 1}>
                                        <button
                                            action="back"
                                            onClick={this.onUndo}
                                            data-ifempty="disable"
                                            data-tipsy="Voltar"
                                            className="tipsy tipsy--sw">
                                            <span className="icon icon-undo2" />
                                        </button>
                                        <button
                                            action="forward"
                                            onClick={this.onRedo}
                                            data-ifempty="disable"
                                            data-tipsy="Avançar"
                                            className="tipsy tipsy--sw">
                                            <span className="icon icon-redo2" />
                                        </button>
                                    </History>
                                    <button
                                        disabled={!hasFile}
                                        onClick={this.onRotateLeft}
                                        data-tipsy="Virar para Esquerda"
                                        className="tipsy tipsy--sw">
                                        <span className="icon icon-undo" />
                                    </button>
                                    <button
                                        disabled={!hasFile}
                                        onClick={this.onRotateRight}
                                        data-tipsy="Virar para Direita"
                                        className="tipsy tipsy--sw">
                                        <span className="icon icon-redo" />
                                    </button>
                                    <CropMenu isCropping={crop}>
                                        <button
                                            disabled={!hasFile}
                                            data-showonlywhen='croppingIsOff'
                                            onClick={this.onCropStart}
                                            data-tipsy="Cortar"
                                            className="tipsy tipsy--sw">
                                            <span className="icon icon-crop" />
                                        </button>
                                        <button disabled={!hasFile} showOnlyWhen='croppingIsOn' style={{ color: 'cyan' }}>
                                            <span className="icon icon-crop" />
                                        </button>
                                        <button
                                            disabled={!hasFile}
                                            data-showonlywhen='croppingIsOn'
                                            onClick={this.onCropConfirm}
                                            style={{ color: 'green' }}
                                            data-tipsy="Confirmar"
                                            className="tipsy tipsy--sw">
                                            <span className="icon icon-checkmark" />
                                        </button>
                                        <button
                                            disabled={!hasFile}
                                            data-showonlywhen='croppingIsOn'
                                            onClick={this.onCropCancel}
                                            style={{ color: 'red' }}
                                            data-tipsy="Cancelar"
                                            className="tipsy tipsy--sw">
                                            <span className="icon icon-cross" />
                                        </button>
                                    </CropMenu>
                                    <button disabled={!hasFile} onClick={this.onSave} data-tipsy="Salvar" className="tipsy tipsy--sw">
                                        <span className="icon icon-floppy-disk" />
                                    </button>
                                </Toolbar>
                                <Dropzone style={{ width: canvasWidth + 30, height: canvasHeight + 30 }} multiple={false} accept="image/*" disableClick={true} onDropAccepted={this.onFileChange}>
                                    <Canvas
                                        ref="canvasWrapper"
                                        crop={crop}
                                        source={source}
                                        angle={angle}
                                        width={canvasWidth}
                                        height={canvasHeight}>
                                    </Canvas>
                                </Dropzone>
                            </form>
                        </Darkroom>
                }
            </div>
        );
    }
}
