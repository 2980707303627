import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { saveObj, fetchObj, updateObj, deleteObj } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';

class TipoAtividade extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      DESCRICAO: '',
      errors: {},
      loading: false,
      redirect: false,
      url: '/tiposatividades',
      nameObj: 'tipoAtividade'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => this.setState({ ...this.props }));
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  saveObj = ({ ID, DESCRICAO }) => {
    if (ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, { ID, DESCRICAO });
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, { DESCRICAO });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { ID, DESCRICAO } = this.state;
    this.setState({ loading: true });
    this.saveObj({ ID, DESCRICAO })
      .then((props) => this.props.history.push(this.state.url));
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardHeader>
                <strong>Cadastro</strong> Tipo de Atividade
                </CardHeader>
              <CardBody>
                {this.state.ID ?
                  <FormGroup row>
                    <Col md="2">
                      <Label>Código</Label>
                    </Col>
                    <Col xs="12" md="10">
                      <p className="form-control-static">{this.state.ID}</p>
                    </Col>
                  </FormGroup>
                  : null}
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Descrição</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="DESCRICAO"
                      value={this.state.DESCRICAO}
                      onChange={this.handleChange}
                      id="DESCRICAO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, updateObj, deleteObj })(TipoAtividade);