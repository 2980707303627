import React, { Component } from 'react';
import { ButtonGroup, Button, Card, CardBody, CardHeader, Col, Row, Table, Collapse, FormGroup, Label, Input, FormText } from 'reactstrap';
import TablePaginator from '../../containers/TablePaginator/TablePaginator';
import ListActions from '../../containers/ListActions/ListActions';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj, staticUrl } from '../../actions';
import DownloadActions from '../../containers/DownloadActions/DownloadActions';
import { emptyMessage, dateFormatter, getAge } from '../../utils/utils';
import status from './status.json';

class FuncionarioList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            NOME: '',
            ANO: '',
            status: status,
            url: '/funcionarios',
            filtro: [true]
        }
        this.toogleFiltro = this.toogleFiltro.bind(this);
        
    }

    componentDidMount() {
        this.props.fetchObjs(this.state.url);
    }

    handlePrint() {
        window.open(`${staticUrl}/funcionarios/reports/2018?token=${localStorage.getItem('token')}`, "_self");
    }

    
    toogleFiltro(tab) {
        const prevState = this.state.filtro;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        this.setState({
            filtro: state,
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        let state = this.state;
        state[e.target.name] = e.target.value;
        this.buscaDados(state);
    }

    buscaDados(state) {
        let query = '';
        if (state.NOME) {
            query += query.indexOf('?') === -1 ? `?nome=${state.NOME}` : `&nome=${state.NOME}`;
        }
        if (state.STATUS) {
            query += query.indexOf('?') === -1 ? `?status=${state.STATUS}` : `&status=${state.STATUS}`;
        }
        this.props.fetchObjs(`${this.state.url}${query}`);
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader id="headingOne">
                                <Button
                                    block color="link"
                                    className="text-left m-0 p-0"
                                    onClick={() => this.toogleFiltro(0)}
                                    aria-expanded={this.state.filtro[0]}
                                    aria-controls="collapseOne"
                                    style={{ color: 'black' }}>
                                    <strong>Filtros</strong>
                                </Button>
                            </CardHeader>
                            <Collapse isOpen={this.state.filtro[0]} data-parent="#filtro" id="collapseOne" aria-labelledby="headingOne">
                                <CardBody>
                                    <FormGroup row>
                                        <Col xs="12" md="2">
                                            <Label htmlFor="text-input">Nome</Label>
                                        </Col>
                                        <Col xs="12" md="10">
                                            <Input
                                                name="NOME"
                                                value={this.state.NOME}
                                                onChange={this.handleChange}
                                                id="NOME" />
                                            <FormText color="muted"></FormText>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="2">
                                            <Label htmlFor="text-input">Status</Label>
                                        </Col>
                                        <Col xs="12" md="10">
                                            <Input
                                                type="select"
                                                name="STATUS"
                                                value={this.state.STATUS}
                                                onChange={this.handleChange}
                                                id="STATUS">
                                                <option value="-1">Todos</option>
                                                {
                                                    this.state.status ?
                                                        this.state.status.map((s, i) => {
                                                            return (
                                                                <option key={i} value={i}>{`${s}`}</option>
                                                            )
                                                        }) : null
                                                }
                                            </Input>
                                            <FormText color="muted"></FormText>
                                        </Col>
                                    </FormGroup>
                                    <Button type="button" size="sm" color="primary" onClick={() => this.buscaDados(this.state)}>
                                        <i className="fa fa-search"></i> Buscar
                                    </Button>
                                </CardBody>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Catequistas</strong>
                                    </Col>
                                    <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <ButtonGroup>
                                            <Button type="button" size="sm" color="primary" onClick={() => this.props.history.push(`${this.state.url}/new`)}>
                                                <i className="fa fa-plus"></i> Novo
                                        </Button>
                                            <Button type="button" size="sm" color="secondary" onClick={() => this.handlePrint()}>
                                                <i className="fa fa-plus"></i> Imprimir
                                        </Button>
                                            <DownloadActions body={this.props.funcionarios.length !== 0 ? this.props.funcionarios : null} />
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.props.funcionarios.length === 0 ? emptyMessage : ([
                                    <Table key={`table`} hover bordered striped responsive size="sm">
                                        <thead key={`thead`}>
                                            <tr key="head">
                                                <th key="id">Código</th>
                                                <th key="nome">Nome</th>
                                                <th key="cargo">Cargo</th>
                                                <th key="dtInicio">Início</th>
                                                <th key="qtdeAnos">Vivência</th>
                                                <th key="qtdeAnos">Status</th>
                                                <th xs="12" sm="1" key="acoes" className="text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody key={`tbody`}>
                                            {this.props.funcionarios.map((funcionario, i) => {
                                                return (
                                                    <tr key={`tr${i}`}>
                                                        <td key={`tdId${i}`} onClick={() => this.props.history.push(`${this.state.url}/${funcionario.ID}`)}>{funcionario ? funcionario.ID : ''}</td>
                                                        <td key={`tdNome${i}`} onClick={() => this.props.history.push(`${this.state.url}/${funcionario.ID}`)}>{funcionario && funcionario.pessoaFisica && funcionario.pessoaFisica.pessoa ? funcionario.pessoaFisica.pessoa.NOME : ''}</td>
                                                        <td key={`tdCargo${i}`} onClick={() => this.props.history.push(`${this.state.url}/${funcionario.ID}`)}>{funcionario && funcionario.cargo ? funcionario.cargo.DESCRICAO : ''}</td>
                                                        <td key={`tdDtInicio${i}`} onClick={() => this.props.history.push(`${this.state.url}/${funcionario.ID}`)}>{funcionario && funcionario.DATA_INICIO ? dateFormatter(new Date(funcionario.DATA_INICIO)) : ''}</td>
                                                        <td key={`tdAnos${i}`} onClick={() => this.props.history.push(`${this.state.url}/${funcionario.ID}`)}>{funcionario && funcionario.DATA_INICIO ? getAge(funcionario.DATA_INICIO) : ''}</td>
                                                        <td key={`tdStatus${i}`} onClick={() => this.props.history.push(`${this.state.url}/${funcionario.ID}`)}>{funcionario && funcionario.STATUS ? 'Ativo' : 'Inativo'}</td>
                                                        <td key={`tdActions${i}`}><ListActions {...this.props} {...this.state} id={funcionario.ID} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>,
                                    <TablePaginator key={`paginator`}{...this.props} {...this.state} />
                                ])}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        funcionarios: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(FuncionarioList);

