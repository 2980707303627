import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import TablePaginator from '../../containers/TablePaginator/TablePaginator';
import ListActions from '../../containers/ListActions/ListActions';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj } from '../../actions';

const emptyMessage = (<p>Não Existem registros para serem exibidos.</p>);

class TipoReceitaList extends Component {

    state = {
        url: '/tiposreceitas'
    }

    componentDidMount() {
        this.props.fetchObjs(this.state.url);
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Tipos de Receita</strong>
                                    </Col>
                                    <Col  xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <Button type="button" size="sm" color="primary" onClick={() => this.props.history.push(`${this.state.url}/new`)}>
                                            <i className="fa fa-plus"></i> Novo
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.props.tipoReceitas.length === 0 ? emptyMessage : ([
                                    <Table key="table" hover bordered striped responsive size="sm">
                                    <thead key="thead">
                                        <tr key="head">
                                            <th key="id">Código</th>
                                            <th key="descricao">Descrição</th>
                                            <th xs="12" sm="1" key="acoes" className="text-center">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody key="tbody">
                                        {this.props.tipoReceitas.map((tipoReceita, i) => {
                                            return (
                                                <tr key={`tr${tipoReceita.ID}`}>
                                                    <td key={`tdId${tipoReceita.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${tipoReceita.ID}`)}>{tipoReceita ? tipoReceita.ID : ''}</td>
                                                    <td key={`tdDescription${tipoReceita.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${tipoReceita.ID}`)}>{tipoReceita ? tipoReceita.DESCRICAO : ''}</td>
                                                    <td key={`tdAction${tipoReceita.ID}`} ><ListActions {...this.props} {...this.state} id={tipoReceita.ID} /></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>,
                                <TablePaginator key="tablePaginator" {...this.props} {...this.state} />
                                ])}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        tipoReceitas: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(TipoReceitaList);

