import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import swal from 'sweetalert2';

class AtividadeFoto extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ID: this.props.ID ? this.props.ID : '',
            IS_CAPA: this.props.IS_CAPA ? 1 : 0,
            DESCRICAO: this.props.DESCRICAO ? this.props.DESCRICAO : '',
            URL: this.props.URL ? this.props.URL : '',
            preview: this.props.preview ? this.props.preview : '',
            url: '/fotos/atividades',
            name: 'atividadeFotos'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            URL: nextProps.URL ? nextProps.URL : ''
        });
    }

    handleChange = (e) => {
        let state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);
        this.props.onChange([e.target.name], e.target.value, this.props.index);
    }

    handleDelete = (e) => {
        this.props.onDelete(this.props.index);
    }

    handleOpenImage = () => {
        swal({
            imageUrl: this.props.URL || this.props.preview,
            imageHeight: "100%",
            imageWidth: "100%",
            imageAlt: `Imagem`,
            customClass: 'swal-large',
            showConfirmButton: false,
        });
    }

    render() {
        return (
            <Col key={`atividadeFotoCol${this.props.index}`} xs="12" md="3" style={{ padding: 10 }}>
                <div  key={`atividadeFotoDivCanvas${this.props.index}`} className="darkroom-canvas">
                    <Row  key={`atividadeFotoRow${this.props.index}`} style={{ padding: 8, textAlign: "left" }}>
                        <Button key={`delete${this.props.index}`} size="sm" color="danger" onClick={() => this.handleDelete()}>
                            <i key={`deleteI${this.props.index}`} className="fa fa-trash"></i>
                        </Button>
                        <Button key={`open${this.props.index}`} size="sm" color="primary" onClick={() => this.handleOpenImage()}>
                            <i key={`openI${this.props.index}`} className="fa fa-folder-open-o"></i>
                        </Button>
                    </Row>
                    {
                        this.props.URL ?
                            <img  key={`atividadeFotoImg${this.props.index}`} style={{ width: "100%" }} alt={`imagem`} src={this.state.URL} onClick={() => this.handleOpenImage()}></img>
                            :
                            <img  key={`atividadeFotoImg${this.props.index}`} style={{ width: "100%" }} alt={`imagem`} src={this.state.preview} onClick={() => this.handleOpenImage()}></img>
                    }
                    <br></br><br></br>
                </div>
            </Col>
        );
    }
}

export default AtividadeFoto;