import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import TablePaginator from '../../containers/TablePaginator/TablePaginator';
import ListActions from '../../containers/ListActions/ListActions';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj } from '../../actions';

import { emptyMessage } from '../../utils/utils';

class DocumentoList extends Component {

    state = {
        url: '/documentos'
    }

    componentDidMount() {
        this.props.fetchObjs(this.state.url);
    }

    vizualiar(url) {
        window.open(url, "_blank");
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Artigos</strong>
                                    </Col>
                                    <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <Button type="button" size="sm" color="primary" onClick={() => this.props.history.push(`${this.state.url}/new`)}>
                                            <i className="fa fa-plus"></i> Novo
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.props.documentos.length === 0 ? emptyMessage : ([
                                    <Table key="table" hover bordered striped responsive size="sm">
                                        <thead key="thead">
                                            <tr key="head">
                                                <th key="id">Código</th>
                                                <th key="nome">Nome</th>
                                                <th key="visualizar">Visualizar</th>
                                                <th xs="12" sm="1" key="acoes" className="text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody key="tbody">
                                            {this.props.documentos.map((documento, i) => {
                                                return (
                                                    <tr key={`tr${i}`}>
                                                        <td key={`id${i}`} onClick={() => this.props.history.push(`${this.state.url}/${documento.ID}`)}>{documento ? documento.ID : ''}</td>
                                                        <td key={`nome${i}`} onClick={() => this.props.history.push(`${this.state.url}/${documento.ID}`)}>{documento ? documento.NOME : ''}</td>
                                                        <td key={`visualizar${i}`} style={{ textAlign: "center" }}>{documento.DOC ?
                                                            <Button key={`buttonTdVisualizar${documento.id}`} size="sm" color="primary" onClick={() => { this.vizualiar(documento.DOC) }}>
                                                                <i key={`ITdVisualizar${documento.id}`} className="fa fa-folder-open"></i> Abrir
                                                            </Button> : ''}
                                                        </td>
                                                        <td key={`actions${i}`}><ListActions key={`listActions${i}`} {...this.props} {...this.state} id={documento.ID} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>,
                                    <TablePaginator key={`paginator`} {...this.props} {...this.state} />
                                ])}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        documentos: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(DocumentoList);

