import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { saveObj, fetchObj, updateObj, deleteObj } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';
import FileBase64 from 'react-file-base64';

class Documento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      NOME: '',
      DESCRICAO: '',
      IMAGEM: '',
      URL: '',
      DOC: '',
      DOCUMENTO: '',
      uploadImage: {},
      errors: {},
      loading: false,
      redirect: false,
      url: '/documentos',
      nameObj: 'documento'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeChild = this.handleChangeChild.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteDoc = this.handleDeleteDoc.bind(this);
    this.handleOpenDoc = this.handleOpenDoc.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => this.setState({ ...this.props }));
    }
  }

  handleChange = (e) => {
    if (e.base64) {
      this.setState({ DOCUMENTO: e.base64 });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleChangeChild = (name, value) => {
    this.setState({ [name]: value });
  }

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveObj(this.state)
      .then((props) => this.props.history.push(this.state.url));
  }

  handleDeleteDoc = () => {
    this.setState({ DOC: '' });
  }

  handleOpenDoc = () => {
    window.open(this.state.DOC, "_blank");
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <strong>Cadastro</strong> Documento
                </CardHeader>
            <CardBody>
              {this.state.ID ?
                <FormGroup row>
                  <Col md="2">
                    <Label>Código</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <p className="form-control-static">{this.state.ID}</p>
                  </Col>
                </FormGroup>
                : null}
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="text-input">Documento</Label>
                </Col>
                <Col xs="12" md="10">
                  {!this.state.DOC ?
                    <div>
                      <FileBase64
                        name="DOCUMENTO"
                        value={this.state.DOCUMENTO}
                        id="DOCUMENTO"
                        multiple={false}
                        onDone={this.handleChange} />
                    </div>
                    :
                    <div>
                      <Button key={`delete${this.props.key}`} size="sm" color="danger" onClick={() => this.handleDeleteDoc()}>
                        <i key={`deleteI${this.props.key}`} className="fa fa-trash"></i>
                      </Button>
                      <Button key={`open${this.props.key}`} size="sm" color="primary" onClick={() => this.handleOpenDoc()}>
                        <i key={`openI${this.props.key}`} className="fa fa-folder-open-o"></i>
                      </Button>
                      &nbsp;
                      <a href={this.state.DOC} className="form-control-static">{this.state.DOC}</a>
                    </div>
                  }
                </Col>
              </FormGroup>
            </CardBody>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardBody>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Nome</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="NOME"
                      value={this.state.NOME}
                      onChange={this.handleChange}
                      id="NOME"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Descrição</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="textarea"
                      rows="5"
                      name="DESCRICAO"
                      value={this.state.DESCRICAO}
                      onChange={this.handleChange}
                      id="DESCRICAO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, updateObj, deleteObj })(Documento);