import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { fetchObj } from '../../actions';

class Email extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ID: null,
      EMAIL: '',
      TIPO_EMAIL: '',
      tiposemails: [],
      errors: {},
      loading: false,
      redirect: false,
      urlEmail: '/emails',
      nameEmail: 'emails'
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    this.setState({ ...this.props });
    if (!this.props.tiposemails) {
      this.props.fetchObjs('/tiposemails', 1, 1000)
        .then(() => { this.setState({ tiposemails: this.props.records }) });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.onChange([e.target.name], e.target.value, this.state.nameEmail, this.props.index);
  }

  render() {
    return (
      <Row key={`rowEmail${this.props.index}`}>
        <Col xs="12" md="12" key={`colEmail${this.props.index}`}>
          <FormGroup row key={`formgroupEmail${this.props.index}`}>
            <Col md="1"
              key={`colLabelEmailEmail${this.props.index}`}
              style={{ textAlign: 'right', paddingTop: 5 }}>
              <Label htmlFor="text-input">Email</Label>
            </Col>
            <Col xs="12" md="5" 
              key={`colEmailEmail${this.props.index}`}>
              <Input
                key={`emailEmail${this.props.index}`}
                value={this.state.EMAIL}
                onChange={this.handleChange}
                name="EMAIL"
                id="EMAIL"
                required
              />
              <FormText color="muted"></FormText>
            </Col>
            <Col md="1"
              key={`colLabelTipoEmail${this.props.index}`}
              style={{ textAlign: 'right', paddingTop: 5 }}>
              <Label htmlFor="text-input">Tipo</Label>
            </Col>
            <Col xs="12" md="5"
              key={`colTipoEmail${this.props.index}`}>
              <Input
                key={`tipoEmail${this.props.index}`}
                type="select"
                name="TIPO_EMAIL"
                value={this.state.TIPO_EMAIL}
                onChange={this.handleChange}
                id="TIPO_EMAIL"
                required>
                <option value="0">Selecione ...</option>
                {
                  this.state.tiposemails ?
                    this.state.tiposemails.map((tipoEmail, i) => {
                      return (
                        <option key={tipoEmail.ID} value={tipoEmail.ID}>{tipoEmail.DESCRICAO}</option>
                      )
                    }) : null
                }
              </Input>
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { fetchObj })(Email);