import React, { Component } from 'react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class FullHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      usuarioLogado: JSON.parse(localStorage.getItem("usuarioLogado"))
    }
  }

  logout = () => {
    if (this.props && this.props.history) {
      this.props.history.push("/loginpage");
      localStorage.clear();
    }
  }

  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        {
          this.state && this.state.usuarioLogado && this.state.usuarioLogado.pessoa && this.state.usuarioLogado.pessoa.unidade.URL ?
            <AppNavbarBrand
              full={{ src: this.state.usuarioLogado.pessoa.unidade.URL, width: 125, height: 55, alt: 'Logo' }}
              minimized={{ src: 'https://s3.us-east-2.amazonaws.com/explorelabs/IconeExploreLabs.ico', width: 40, height: 40, alt: 'ExploreLabs' }}
            />
            :
            <AppNavbarBrand
              full={{ src: process.env.PUBLIC_URL + 'assets/img/brand/logo.png', width: 125, height: 55, alt: 'Logo' }}
              minimized={{ src: 'https://s3.us-east-2.amazonaws.com/explorelabs/IconeExploreLabs.ico', width: 40, height: 40, alt: 'ExploreLabs' }}
            />
        }
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              {
                this.state.usuarioLogado && this.state.usuarioLogado.URL ?
                  <img className="img-avatar" src={this.state.usuarioLogado.URL} alt={this.state.usuarioLogado.LOGIN}></img>
                  :
                  <img className="img-avatar" src="assets/img/avatars/1.png" alt="Usuário"></img>
              }
            </DropdownToggle>
            {
              this.state.usuarioLogado ?
                <DropdownMenu right style={{ right: 'auto' }}>
                  <DropdownItem header tag="div" className="text-center"><strong>{this.state.usuarioLogado && this.state.usuarioLogado.pessoa && this.state.usuarioLogado.pessoa.NOME ? this.state.usuarioLogado.pessoa.NOME : ''}</strong></DropdownItem>
                  <DropdownItem><i className="fa fa-bell-o"></i> Notificações<Badge color="info">{this.state.usuarioLogado.notificacoes}</Badge></DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.logout.bind(this)}><i className="fa fa-lock" ></i> Sair</DropdownItem>
                </DropdownMenu>
                : null
            }
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

FullHeader.propTypes = propTypes;
FullHeader.defaultProps = defaultProps;

export default FullHeader;
