import React, { Component } from 'react';
import { Label, Input, FormText, FormGroup, ButtonGroup, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj, staticUrl } from '../../actions';
import Select from 'react-select';
import years from '../../utils/years.json';
import { Document, Page } from "react-pdf";

class Roteiro extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: new Date().getFullYear(),
            years: years,
            turmasFiltro: [],
            turmas: [],
            turmasOptions: [],
            numPages: null,
            pageNumber: 1,
            file: '',
            query: '',
            url: '/turmas/reports/roteiros',
            filtro: [true]
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    componentDidMount() {
        this.props.fetchObjs('/turmas', 1, 1000)
            .then(() => {
                let turmas = this.props.records;
                let turmasFiltro = [];
                turmas.map((element) => {
                    return turmasFiltro.push({
                        label: `${element.DESCRICAO} - ${element.ANO} - ${element.catequista.pessoaFisica.pessoa.NOME}`,
                        value: `${element.DESCRICAO} - ${element.ANO} - ${element.catequista.pessoaFisica.pessoa.NOME}`,
                        ...element
                    });
                });
                this.setState({ turmasOptions: turmasFiltro });
            });
    }

    filterTurmas = (inputValue) => {
        return this.state.turmasOptions.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    handleChangeTurma = (newValue, actionMeta) => {
        newValue = newValue ? newValue : {};
        this.setState({ turmasFiltro: newValue });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        let state = this.state;
        state[e.target.name] = e.target.value;
        this.buscaDados(state);
    }

    handlePrint = () => {
        window.open(`${staticUrl}${this.state.url}${this.state.query}`, "_self")
    }

    buscaDados(state) {
        let query = `?token=${localStorage.getItem('token')}`;
        query += `&year=${state.year}`;
        if (state.turmasFiltro) {
            let turmas = [];
            state.turmasFiltro.map(element => {
                return turmas.push(element.ID);
            });
            query += `&turmas=${JSON.stringify(turmas)}`;
        }
        this.setState({ query, file: `${staticUrl}${this.state.url}${query}` });
    }

    render() {
        const { pageNumber } = this.state;
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <FormGroup row>
                                    <Col md="2">
                                        <Label htmlFor="text-input">Ano</Label>
                                    </Col>
                                    <Col xs="10">
                                        <Input
                                            type="select"
                                            name="year"
                                            value={this.state.year}
                                            onChange={this.handleChange}
                                            id="year">
                                            <option value="-1">Todos</option>
                                            {
                                                this.state.years ?
                                                    this.state.years.map((year) => {
                                                        return (
                                                            <option key={year} value={year}>{year}</option>
                                                        )
                                                    }) : null
                                            }
                                        </Input>
                                        <FormText color="muted"></FormText>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="2">
                                        <Label htmlFor="text-input">Turmas</Label>
                                    </Col>
                                    <Col xs="12" md="10">
                                        <Select
                                            isMulti
                                            onChange={this.handleChangeTurma}
                                            options={this.state.turmasOptions}
                                            value={this.state.turmasFiltro}
                                        />
                                        <FormText color="muted"></FormText>
                                    </Col>
                                </FormGroup>
                                <Button type="button" size="sm" color="primary" onClick={() => this.buscaDados(this.state)}>
                                    <i className="fa fa-search"></i> Buscar
                                    </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Resultado</strong>
                                    </Col>
                                    {
                                        this.state.query ? (
                                            <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                                                <ButtonGroup>
                                                    <Button type="button" size="sm" color="primary" onClick={() => { this.handlePrint() }}>
                                                        <i className="fa fa-download"></i> Download
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        ) : null
                                    }
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="12">
                                        <Document
                                            file={this.state.file}
                                            onLoadSuccess={this.onDocumentLoadSuccess}
                                            externalLinkTarget="_self"
                                            loading="Carregando ..."
                                            noData="Filtro não aplicado">
                                            <Page pageNumber={pageNumber} scale={1.0} />
                                        </Document>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        records: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(Roteiro);

