import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import InputMask from 'react-input-mask';
import { fetchObj } from '../../actions';

class Telefone extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ID: null,
      NUMERO: '',
      TIPO: '',
      tipostelefones: [],
      errors: {},
      loading: false,
      redirect: false,
      urlTelefone: '/telefones',
      nameTelefone: 'telefones'
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ID: nextProps.ID ? nextProps.ID : '',
      NUMERO: nextProps.NUMERO ? nextProps.NUMERO : '',
      TIPO: nextProps.TIPO ? nextProps.TIPO : ''
    });
  }

  componentDidMount = () => {
    this.setState({ ...this.props });
    if (!this.props.tipostelefones) {
      this.props.fetchObjs('/tipostelefones', 1, 1000)
        .then(() => { this.setState({ tipostelefones: this.props.records }) });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.onChange([e.target.name], e.target.value, this.state.nameTelefone, this.props.index);
  }

  hasDigit = (number = "") => {
    number = number.replace('(', '').replace(')', '').replace('_', '').replace(' ', '').replace('-', '');
    return number.length <= 10;
  }

  render() {
    return (
      <Row key={`rowTelefone${this.props.index}`}>
        <Col xs="12" md="12" key={`colTelefone${this.props.index}`}>
          <FormGroup row key={`formgroupTelefone${this.props.index}`}>
            <Col md="1"
              key={`colLabelNumeroTelefone${this.props.index}`}
              style={{ textAlign: 'right', paddingTop: 5 }}>
              <Label htmlFor="text-input">Telefone</Label>
            </Col>
            <Col xs="12" md="5"
              key={`colNumeroTelefone${this.props.index}`}>
              <InputMask
                className="form-control"
                mask={this.hasDigit(this.state.NUMERO) ? '(99) 9999-9999?' : '(99) 99999-9999'}
                formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                key={`numeroTelefone${this.props.index}`}
                value={this.state.NUMERO}
                onChange={this.handleChange}
                name="NUMERO"
                id="NUMERO"
                required
              />
              <FormText color="muted"></FormText>
            </Col>
            <Col md="1"
              key={`colLabelTipoTelefone${this.props.index}`}
              style={{ textAlign: 'right', paddingTop: 5 }}>
              <Label htmlFor="text-input">Tipo</Label>
            </Col>
            <Col xs="12" md="5"
              key={`colTipoTelefone${this.props.index}`}>
              <Input
                key={`tipoTelefone${this.props.index}`}
                type="select"
                name="TIPO"
                value={this.state.TIPO}
                onChange={this.handleChange}
                id="TIPO"
                required>
                <option value="0">Selecione ...</option>
                {
                  this.state.tipostelefones ?
                    this.state.tipostelefones.map((tipoTelefone, i) => {
                      return (
                        <option key={tipoTelefone.ID} value={tipoTelefone.ID}>{tipoTelefone.DESCRICAO}</option>
                      )
                    }) : null
                }
              </Input>
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { fetchObj })(Telefone);