import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Label, Input, FormText, FormGroup, Card, CardBody, CardHeader, Collapse, CardTitle, Col, Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchObjs } from '../../actions';
import { makeColors, firstDayOfMonth, lastDayOfMonth } from '../../utils/utils';
import { DateRange } from 'react-date-range';
import locale from 'date-fns/locale/pt';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

class Resultado extends Component {

    constructor(props) {
        super(props);
        this.state = {
            receitasChart: {
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: makeColors()
                },]
            },
            despesasChart: {
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: makeColors()
                },]
            },
            selectionRange: {
                startDate: firstDayOfMonth(),
                endDate: lastDayOfMonth(),
                key: 'selection',
            },
            radioSelected: 1,
            filtro: [false],
            CONTA: 0,
            contas: [],
            start: firstDayOfMonth(),
            end: lastDayOfMonth(),
            url: '/resultados/grafico'
        }
        this.toogleFiltro = this.toogleFiltro.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.props.fetchObjs('/contas', 1, 1000)
            .then(() => { this.setState({ contas: this.props.records }) });
        this.buscaDados(this.state);
    }

    buscaDados(state) {
        let start = state.start ? state.start.toISOString() : null;
        let end = state.end ? state.end.toISOString() : null;
        let startQuery = start ? `start=${start}` : null;
        let endQuery = end ? `end=${end}` : null;
        let query = start && end ? `?${startQuery}&${endQuery}` : start ? `?${startQuery}` : end ? `?${endQuery}` : '';
        if (state.CONTA) {
            if (query.indexOf('?') !== -1) {
                query += `&conta=${state.CONTA}`;
            } else {
                query += `?conta=${state.CONTA}`;
            }
        }
        this.props.fetchObjs(`${state.url}${query}`)
            .then(() => {
                let state = this.state;

                state.receitasChart.labels = this.props.resultado && this.props.resultado.receitas && this.props.resultado.receitas.labels ? this.props.resultado.receitas.labels : [];
                state.receitasChart.datasets[0].data = this.props.resultado && this.props.resultado.receitas &&this.props.resultado.receitas.values ? [].concat(this.props.resultado.receitas.values) : [];

                state.despesasChart.labels = this.props.resultado && this.props.resultado.despesas &&this.props.resultado.despesas.labels ? this.props.resultado.despesas.labels : [];
                state.despesasChart.datasets[0].data = this.props.resultado && this.props.resultado.despesas &&this.props.resultado.despesas.values ? [].concat(this.props.resultado.despesas.values) : [];

                this.setState(state);
            });
    }

    toogleFiltro(tab) {
        const prevState = this.state.filtro;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        this.setState({
            filtro: state,
        });
    }

    handleSelect(selection) {
        this.setState({ start: selection.selection.startDate, end: selection.selection.endDate, selectionRange: selection.selection });
        this.buscaDados({ start: selection.selection.startDate, end: selection.selection.endDate, url: this.state.url, CONTA: this.state.CONTA });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.buscaDados({ start: this.state.start, end: this.state.end, url: this.state.url, CONTA: e.target.value });
    }

    render() {
        return (
            <div className="animated fadeIn">
                {
                    this.props.hiddenFilter ? null :
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader id="headingOne">
                                        <Button
                                            block color="link"
                                            className="text-left m-0 p-0"
                                            onClick={() => this.toogleFiltro(0)}
                                            aria-expanded={this.state.filtro[0]}
                                            aria-controls="collapseOne">
                                            <strong>Filtros</strong>
                                        </Button>
                                    </CardHeader>
                                    <Collapse isOpen={this.state.filtro[0]} data-parent="#filtro" id="collapseOne" aria-labelledby="headingOne">
                                        <CardBody>
                                            <FormGroup row>
                                                <Col md="2">
                                                    <Label htmlFor="text-input">Conta bancária</Label>
                                                </Col>
                                                <Col xs="12" md="10">
                                                    <Input
                                                        type="select"
                                                        name="CONTA"
                                                        value={this.state.CONTA}
                                                        onChange={this.handleChange}
                                                        id="CONTA"
                                                        required>
                                                        <option value="0">Todos</option>
                                                        {
                                                            this.state.contas ?
                                                                this.state.contas.map((conta, i) => {
                                                                    return (
                                                                        <option key={conta.ID} value={conta.ID}>{`Banco:${conta.BANCO} - Ag:${conta.AGENCIA} - CC: ${conta.CONTA}`}</option>
                                                                    )
                                                                }) : null
                                                        }
                                                    </Input>
                                                    <FormText color="muted"></FormText>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="2">
                                                    <Label htmlFor="text-input">Período</Label>
                                                </Col>
                                                <Col xs="12" md="10">
                                                    <DateRange
                                                        ranges={[this.state.selectionRange]}
                                                        onChange={this.handleSelect}
                                                        locale={locale}
                                                        showSelectionPreview={true}
                                                        moveRangeOnFirstSelection={false}
                                                        months={2}
                                                        direction="horizontal"
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                }
                <Row>
                    <Col sm="6">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="5">
                                        <CardTitle className="mb-0">Receitas</CardTitle>
                                        <div className="small text-muted">Por tipo de receita</div>
                                    </Col>
                                </Row>
                                <div style={{}}>
                                    <Doughnut data={this.state.receitasChart} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col sm="6">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="5">
                                        <CardTitle className="mb-0">Despesas</CardTitle>
                                        <div className="small text-muted">Por tipo de despesa</div>
                                    </Col>
                                </Row>
                                <div style={{}}>
                                    <Doughnut data={this.state.despesasChart} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        resultado: state.obj.records && state.obj.records.length > 0 ? state.obj.records[0] : {},
        records: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs })(Resultado);

