import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import InputMask from 'react-input-mask';
import { fetchObj } from '../../actions';
import estadoCidade from './estadoCidade.json';

class Endereco extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ID: null,
      LOGRADOURO: '',
      NUMERO: '',
      BAIRRO: '',
      COMPLEMENTO: '',
      CEP: '',
      CIDADE: '',
      UF: '',
      PAIS: 'Brasil',
      estados: estadoCidade.estados,
      cidades: [],
      errors: {},
      loading: false,
      redirect: false,
      urlEndereco: '/enderecos',
      nameEndereco: 'endereco'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleEstadoChange = this.handleEstadoChange.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    this.props.onChange(['PAIS'], this.state.PAIS, this.state.nameEndereco);
    if (match.params.id && match.params.id !== 'new')
      this.props.fetchObj(this.state.urlEndereco + this.props.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props.endereco });
          this.state.estados
            .filter(element => this.state.UF === element.sigla)
            .map(element => this.setState({ cidades: element.cidades }));
          this.props.onChange(['ID'], this.state.ID, this.state.nameEndereco);
        });
  }

  saveObj = ({ ID, DESCRICAO }) => {
    if (ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, { ID, DESCRICAO });
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, { DESCRICAO });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.onChange([e.target.name], e.target.value, this.state.nameEndereco);
  }

  handleEstadoChange = (e) => {
    this.handleChange(e);
    this.state.estados
      .filter(element => e.target.value === element.sigla)
      .map(element => this.setState({ cidades: element.cidades }));
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">Logradouro</Label>
            </Col>
            <Col xs="12" md="10">
              <Input
                name="LOGRADOURO"
                value={this.state.LOGRADOURO}
                onChange={this.handleChange}
                id="LOGRADOURO"
                required
              />
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">Número</Label>
            </Col>
            <Col xs="12" md="10">
              <Input
                name="NUMERO"
                value={this.state.NUMERO}
                onChange={this.handleChange}
                id="NUMERO"
                required
              />
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">Bairro</Label>
            </Col>
            <Col xs="12" md="10">
              <Input
                name="BAIRRO"
                value={this.state.BAIRRO}
                onChange={this.handleChange}
                id="BAIRRO"
                required
              />
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">Complemento</Label>
            </Col>
            <Col xs="12" md="10">
              <Input
                name="COMPLEMENTO"
                value={this.state.COMPLEMENTO ? this.state.COMPLEMENTO : ''}
                onChange={this.handleChange}
                id="COMPLEMENTO"
              />
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">CEP</Label>
            </Col>
            <Col xs="12" md="10">
              <InputMask mask="99.999-999"
                className="form-control"
                value={this.state.CEP}
                onChange={this.handleChange}
                name="CEP"
                id="CEP">
                {(inputProps) => <Input {...inputProps} required />}
              </InputMask>
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">Estado</Label>
            </Col>
            <Col xs="12" md="10">
              <Input
                type="select"
                name="UF"
                value={this.state.UF}
                onChange={this.handleEstadoChange}
                id="UF"
                required>
                <option value="0">Selecione ...</option>
                {
                  this.state.estados ?
                    this.state.estados.map((estado, i) => {
                      return (
                        <option key={estado.sigla} value={estado.sigla}>{estado.nome}</option>
                      )
                    }) : null
                }
              </Input>
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">Cidade</Label>
            </Col>
            <Col xs="12" md="10">
              <Input
                type="select"
                name="CIDADE"
                value={this.state.CIDADE}
                onChange={this.handleEstadoChange}
                id="CIDADE"
                required>
                <option value="0">Selecione ...</option>
                {
                  this.state.cidades ?
                    this.state.cidades.map((cidade, i) => {
                      return (
                        <option key={cidade} value={cidade}>{cidade}</option>
                      )
                    }) : null
                }
              </Input>
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">País</Label>
            </Col>
            <Col xs="12" md="10">
              <Input
                name="PAIS"
                value={this.state.PAIS}
                onChange={this.handleChange}
                id="PAIS"
                required
              />
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { fetchObj })(Endereco);