import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import InputMask from 'react-input-mask';
import { fetchObj } from '../../actions';

class PessoaFisica extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ID: null,
      CPF: '',
      RG: '',
      errors: {},
      loading: false,
      redirect: false,
      urlPessoaFisica: '/pessoasfisicas',
      namePessoaFisica: 'pessoaFisica'
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.urlPessoaFisica + this.props.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props });
          this.props.onChange(['ID'], this.state.ID, this.state.namePessoaFisica);
        });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.onChange([e.target.name], e.target.value, this.state.namePessoaFisica);
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">CPF</Label>
            </Col>
            <Col xs="12" md="10">
              <InputMask mask="999.999.999-99"
                value={this.state.CPF}
                onChange={this.handleChange}
                name="CPF"
                id="CPF">
                {(inputProps) => <Input {...inputProps} required />}
              </InputMask>
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup>
          {/* <FormGroup row>
            <Col md="2">
              <Label htmlFor="text-input">RG</Label>
            </Col>
            <Col xs="12" md="10">
              <Input
                name="RG"
                value={this.state.RG}
                onChange={this.handleChange}
                id="RG"
                required
              />
              <FormText color="muted"></FormText>
            </Col>
          </FormGroup> */}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { fetchObj })(PessoaFisica);