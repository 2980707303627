import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { Label, Input, FormText, FormGroup, Card, CardBody, CardHeader, Collapse, CardTitle, Col, Row, Button, ButtonToolbar, ButtonGroup } from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { connect } from 'react-redux';
import { fetchObjs } from '../../actions';
import { firstDayOfMonth, lastDayOfMonth } from '../../utils/utils';
import { DateRange } from 'react-date-range';
import locale from 'date-fns/locale/pt';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const brandSuccess = '#4dbd74';
const brandDanger = '#f86c6b';

const mainChartOpts = {
    tooltips: {
        enabled: false,
        custom: CustomTooltips,
        intersect: true,
        mode: 'index',
        position: 'nearest',
        callbacks: {
            labelColor: function (tooltipItem, chart) {
                return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
            }
        }
    },
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    scales: {
        xAxes: [
            {
                gridLines: {
                    drawOnChartArea: false
                },
            }],
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5
                },
            }],
    },
    elements: {
        point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
        },
    },
};

class Fluxo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mainChart: {
                labels: [],
                datasets: [
                    {
                        label: 'Receitas',
                        backgroundColor: brandSuccess,
                        borderColor: brandSuccess,
                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 2,
                        data: [],
                    },
                    {
                        label: 'Despesas',
                        backgroundColor: brandDanger,
                        borderColor: brandDanger,
                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 1,
                        borderDash: [8, 5],
                        data: [],
                    },
                ],
            },
            selectionRange: {
                startDate: firstDayOfMonth(),
                endDate: lastDayOfMonth(),
                key: 'selection',
            },
            radioSelected: 1,
            filtro: [false],
            CONTA: 0,
            contas: [],
            start: firstDayOfMonth(),
            end: lastDayOfMonth(),
            url: '/fluxos/grafico'
        }
        this.toogleFiltro = this.toogleFiltro.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    onRadioBtnClick(type) {
        let state = this.state;
        state.radioSelected = type;
        if (Number(type) === 1) {
            state.mainChart.labels = this.props.fluxo.labels ? this.props.fluxo.labels : [];
            state.mainChart.datasets[0].data = this.props.fluxo.receitas ? [].concat(this.props.fluxo.receitas) : [];
            state.mainChart.datasets[1].data = this.props.fluxo.despesas ? [].concat(this.props.fluxo.despesas) : [];
        } else {
            state.mainChart.labels = this.props.fluxo.labelsMonth ? this.props.fluxo.labelsMonth : [];
            state.mainChart.datasets[0].data = this.props.fluxo.receitasMonth ? [].concat(this.props.fluxo.receitasMonth) : [];
            state.mainChart.datasets[1].data = this.props.fluxo.despesasMonth ? [].concat(this.props.fluxo.despesasMonth) : [];
        }
        this.setState(state);
    }

    componentDidMount() {
        this.props.fetchObjs('/contas', 1, 1000)
            .then(() => { this.setState({ contas: this.props.records }) });
        this.buscaDados(this.state);
    }

    buscaDados(state) {
        let start = state.start ? state.start.toISOString() : null;
        let end = state.end ? state.end.toISOString() : null;
        let startQuery = start ? `start=${start}` : null;
        let endQuery = end ? `end=${end}` : null;
        let query = start && end ? `?${startQuery}&${endQuery}` : start ? `?${startQuery}` : end ? `?${endQuery}` : '';
        if (state.CONTA) {
            if (query.indexOf('?') !== -1) {
                query += `&conta=${state.CONTA}`;
            } else {
                query += `?conta=${state.CONTA}`;
            }
        }
        this.props.fetchObjs(`${state.url}${query}`)
            .then(() => {
                let state = this.state;
                state.mainChart.labels = this.props.fluxo.labels ? this.props.fluxo.labels : [];
                state.mainChart.datasets[0].data = this.props.fluxo.receitas ? [].concat(this.props.fluxo.receitas) : [];
                state.mainChart.datasets[1].data = this.props.fluxo.despesas ? [].concat(this.props.fluxo.despesas) : [];
                this.setState(state);
            });
    }

    toogleFiltro(tab) {
        const prevState = this.state.filtro;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        this.setState({
            filtro: state,
        });
    }

    handleSelect(selection) {
        this.setState({ start: selection.selection.startDate, end: selection.selection.endDate, selectionRange: selection.selection });
        this.buscaDados({ start: selection.selection.startDate, end: selection.selection.endDate, url: this.state.url, CONTA: this.state.CONTA });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.buscaDados({ start: this.state.start, end: this.state.end, url: this.state.url, CONTA: e.target.value });
    }

    render() {
        return (
            <div className="animated fadeIn">
                {
                    this.props.hiddenFilter ? null :
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader id="headingOne">
                                        <Button
                                            block color="link"
                                            className="text-left m-0 p-0"
                                            onClick={() => this.toogleFiltro(0)}
                                            aria-expanded={this.state.filtro[0]}
                                            aria-controls="collapseOne">
                                            <strong>Filtros</strong>
                                        </Button>
                                    </CardHeader>
                                    <Collapse isOpen={this.state.filtro[0]} data-parent="#filtro" id="collapseOne" aria-labelledby="headingOne">
                                        <CardBody>
                                            <FormGroup row>
                                                <Col md="2">
                                                    <Label htmlFor="text-input">Conta bancária</Label>
                                                </Col>
                                                <Col xs="12" md="10">
                                                    <Input
                                                        type="select"
                                                        name="CONTA"
                                                        value={this.state.CONTA}
                                                        onChange={this.handleChange}
                                                        id="CONTA"
                                                        required>
                                                        <option value="0">Todos</option>
                                                        {
                                                            this.state.contas ?
                                                                this.state.contas.map((conta, i) => {
                                                                    return (
                                                                        <option key={conta.ID} value={conta.ID}>{`Banco:${conta.BANCO} - Ag:${conta.AGENCIA} - CC: ${conta.CONTA}`}</option>
                                                                    )
                                                                }) : null
                                                        }
                                                    </Input>
                                                    <FormText color="muted"></FormText>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="2">
                                                    <Label htmlFor="text-input">Período</Label>
                                                </Col>
                                                <Col xs="12" md="10">
                                                    <DateRange
                                                        ranges={[this.state.selectionRange]}
                                                        onChange={this.handleSelect}
                                                        locale={locale}
                                                        showSelectionPreview={true}
                                                        moveRangeOnFirstSelection={false}
                                                        months={2}
                                                        direction="horizontal"
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                }
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="5">
                                        <CardTitle className="mb-0">Fluxo de Caixa</CardTitle>
                                        <div className="small text-muted">Receitas x Despesas</div>
                                    </Col>
                                    {
                                        this.props.hiddenFilter ? null :
                                            <Col sm="7" className="d-none d-sm-inline-block">
                                                <ButtonToolbar className="float-right" aria-label="Toolbar with button groups">
                                                    <ButtonGroup className="mr-3" aria-label="First group">
                                                        <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Diário</Button>
                                                        <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>Mensal</Button>
                                                    </ButtonGroup>
                                                </ButtonToolbar>
                                            </Col>
                                    }
                                </Row>
                                <div className="chart-wrapper" style={{ height: 300 + 'px', marginTop: 40 + 'px' }}>
                                    <Bar data={this.state.mainChart} options={mainChartOpts} height={300} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        fluxo: state.obj.records && state.obj.records.length > 0 ? state.obj.records[0] : {},
        records: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs })(Fluxo);

