import React, { Component } from 'react';
import { Label, Input, FormText, FormGroup, ButtonGroup, Button, Card, CardBody, CardHeader, Col, Collapse, Row, Table, Badge } from 'reactstrap';
import TablePaginator from '../../containers/TablePaginator/TablePaginator';
import ListActions from '../../containers/ListActions/ListActions';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj } from '../../actions';
import DownloadActions from '../../containers/DownloadActions/DownloadActions';
import { emptyMessage, dateFormatter } from '../../utils/utils';

class BeneficiarioList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            NOME: '',
            beneficiarios: [],
            url: '/beneficiarios',
            filtro: [true]
        }
        this.toogleFiltro = this.toogleFiltro.bind(this);
        this.mapPropsToState = this.mapPropsToState.bind(this);
    }

    componentDidMount() {
        this.props.fetchObjs(`${this.state.url}`)
            .then(() => { this.setState({ beneficiarios: this.props.records }) });
    }

    mapPropsToState = () => {
        this.setState({ beneficiarios: this.props.records });
    }

    toogleFiltro(tab) {
        const prevState = this.state.filtro;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        this.setState({
            filtro: state,
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    buscaDados(state) {
        let query = '';
        if (state.NOME) {
            query += query.indexOf('?') === -1 ? `?nome=${state.NOME}` : `&nome=${state.NOME}`;
        }
        this.props.fetchObjs(`${this.state.url}${query}`)
            .then(() => {
                this.setState({ beneficiarios: this.props.records })
            });
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader id="headingOne">
                                <Button
                                    block color="link"
                                    className="text-left m-0 p-0"
                                    onClick={() => this.toogleFiltro(0)}
                                    aria-expanded={this.state.filtro[0]}
                                    aria-controls="collapseOne"
                                    style={{ color: 'black' }}>
                                    <strong>Filtros</strong>
                                </Button>
                            </CardHeader>
                            <Collapse isOpen={this.state.filtro[0]} data-parent="#filtro" id="collapseOne" aria-labelledby="headingOne">
                                <CardBody>
                                    <FormGroup row>
                                        <Col xs="12" md="2">
                                            <Label htmlFor="text-input">Nome</Label>
                                        </Col>
                                        <Col xs="12" md="10">
                                            <Input
                                                name="NOME"
                                                value={this.state.NOME}
                                                onChange={this.handleChange}
                                                id="NOME" />
                                            <FormText color="muted"></FormText>
                                        </Col>
                                    </FormGroup>
                                    <Button type="button" size="sm" color="primary" onClick={() => this.buscaDados(this.state)}>
                                        <i className="fa fa-search"></i> buscar
                                    </Button>
                                </CardBody>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Catequizando</strong>
                                    </Col>
                                    <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <ButtonGroup>
                                            <Button type="button" size="sm" color="primary" onClick={() => this.props.history.push(`${this.state.url}/new`)}>
                                                <i className="fa fa-plus"></i> Novo
                                        </Button>
                                            <DownloadActions body={this.state.beneficiarios && this.state.beneficiarios.length !== 0 ? this.state.beneficiarios : null} />
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.state.beneficiarios && this.state.beneficiarios.length !== 0 ? ([
                                    <Table key={`table`} hover bordered striped responsive size="sm">
                                        <thead key={`thead`}>
                                            <tr key="head">
                                                <th key="id">Código</th>
                                                <th key="nome">Nome</th>
                                                <th key="sacramentos">Sacramentos</th>
                                                <th key="CREATED_ON">Data de Cadastro</th>
                                                <th key="situacao">Situação do cadastro</th>
                                                <th xs="12" sm="1" key="acoes" className="text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody key={`tbody`}>
                                            {this.state.beneficiarios.map((beneficiario, i) => {
                                                return (
                                                    <tr key={`tr${i}`}>
                                                        <td key={`tdId${i}`} onClick={() => this.props.history.push(`${this.state.url}/${beneficiario.ID}`)}>{beneficiario ? beneficiario.ID : ''}</td>
                                                        <td key={`tdNome${i}`} onClick={() => this.props.history.push(`${this.state.url}/${beneficiario.ID}`)}>{beneficiario && beneficiario.pessoaFisica && beneficiario.pessoaFisica.pessoa ? beneficiario.pessoaFisica.pessoa.NOME : ''}</td>
                                                        <td key={`tdSacramentos${i}`} onClick={() => this.props.history.push(`${this.state.url}/${beneficiario.ID}`)}>
                                                            {beneficiario.beneficiarioSacramentos.map((item, itemI) => {
                                                                return (<Badge key={`BadgeSacramento${i}${itemI}`}>{item.sacramento.DESCRICAO}</Badge>);
                                                            })}
                                                        </td>
                                                        <td key={`tdCreatedAt${i}`} onClick={() => this.props.history.push(`${this.state.url}/${beneficiario.ID}`)}>{beneficiario && beneficiario.CREATED_ON ? dateFormatter(new Date(beneficiario.CREATED_ON)) : ''}</td>
                                                        <td key={`tdSituacao${i}`} onClick={() => this.props.history.push(`${this.state.url}/${beneficiario.ID}`)}>
                                                            {beneficiario && (beneficiario.situacao || beneficiario.situacaoPadrinho) ?
                                                                <Button key={`buttonTdSituacao${this.props.id}`} size="sm" color="danger">
                                                                    <i key={`ITdSituacao${this.props.id}`} className="fa fa-close"></i> Incompleto
                                                                </Button>
                                                                :
                                                                <Button key={`buttonTdSituacao${this.props.id}`} size="sm" color="success">
                                                                    <i key={`ITdSituacao${this.props.id}`} className="fa fa-check"></i> Completo
                                                                </Button>}
                                                        </td>
                                                        <td key={`tdActions${i}`}><ListActions {...this.props} {...this.state} id={beneficiario.ID} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>,
                                    <TablePaginator key={`paginator`}{...this.props} {...this.state} callback={this.mapPropsToState} />
                                ]) : emptyMessage}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        records: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(BeneficiarioList);

