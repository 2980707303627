import React, { Component } from 'react';
import { Col, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';

class TablePaginator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            records: 10
        }
    }

    handlePage = (valor) => {
        valor = valor < 1 ? 1 : valor;
        valor = valor > this.props.meta.pageCount ? this.props.meta.pageCount : valor;
        this.setState({ page: valor });
        this.props.fetchObjs(this.props.url, valor, this.state.records)
            .then(this.props.callback ? this.props.callback : () => { });
    }

    handleRecords = (valor) => {
        valor = valor < 10 ? 10 : valor;
        this.setState({ records: valor });
        this.props.fetchObjs(this.props.url, this.state.page, valor)
            .then(this.props.callback ? this.props.callback : () => { });
    }

    render() {
        return (
            <Row>
                <Col xs="12" sm="2">
                    <nav>
                        <Pagination>
                            <PaginationItem onClick={() => this.handlePage(this.state.page - 1)}><PaginationLink previous tag="button"></PaginationLink></PaginationItem>
                            <PaginationItem active>
                                <PaginationLink tag="button">{this.state.page}</PaginationLink>
                            </PaginationItem>
                            <PaginationItem onClick={() => this.handlePage(this.state.page + 1)}><PaginationLink next tag="button"></PaginationLink></PaginationItem>
                        </Pagination>
                    </nav>
                </Col>
                <Col xs="12" sm="10">
                    <nav>
                        <Pagination>
                            <PaginationItem><PaginationLink tag="button">Registros por pagina: </PaginationLink></PaginationItem>
                            <PaginationItem active={this.state.records === 10} onClick={() => this.handleRecords(10)}><PaginationLink tag="button">10</PaginationLink></PaginationItem>
                            <PaginationItem active={this.state.records === 25} onClick={() => this.handleRecords(25)}><PaginationLink tag="button">25</PaginationLink></PaginationItem>
                            <PaginationItem active={this.state.records === 50} onClick={() => this.handleRecords(50)}><PaginationLink tag="button">50</PaginationLink></PaginationItem>
                            <PaginationItem active={this.state.records === 100} onClick={() => this.handleRecords(100)}><PaginationLink tag="button">100</PaginationLink></PaginationItem>
                            <PaginationItem active={this.state.records === 250} onClick={() => this.handleRecords(250)}><PaginationLink tag="button">250</PaginationLink></PaginationItem>
                            <PaginationItem active={this.state.records === 500} onClick={() => this.handleRecords(500)}><PaginationLink tag="button">500</PaginationLink></PaginationItem>
                            <PaginationItem active={this.state.records === 1000} onClick={() => this.handleRecords(1000)}><PaginationLink tag="button">1000</PaginationLink></PaginationItem>
                        </Pagination>
                    </nav>
                </Col>
            </Row>
        );
    }

}

export default TablePaginator;