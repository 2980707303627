import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import TablePaginator from '../../containers/TablePaginator/TablePaginator';
import ListActions from '../../containers/ListActions/ListActions';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj } from '../../actions';

const emptyMessage = (<p>Não Existem registros para serem exibidos.</p>);

class PerfilList extends Component {

    state = {
        url: '/perfis'
    }

    componentDidMount() {
        this.props.fetchObjs(this.state.url);
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Perfils</strong>
                                    </Col>
                                    <Col  xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <Button type="button" size="sm" color="primary" onClick={() => this.props.history.push('/perfis/new')}>
                                            <i className="fa fa-plus"></i> Novo
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.props.perfis.length === 0 ? emptyMessage : ([
                                    <Table hover bordered striped responsive size="sm">
                                        <thead>
                                            <tr key="head">
                                                <th key="cnpj">CNPJ</th>
                                                <th key="nome">Nome</th>
                                                <th xs="12" sm="1" key="acoes" className="text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.perfis.map((perfil, i) => {
                                                return (
                                                    <tr>
                                                        <td onClick={() => this.props.history.push(`${this.state.url}/${perfil.ID}`)}>{perfil ? perfil.ID : ''}</td>
                                                        <td onClick={() => this.props.history.push(`${this.state.url}/${perfil.ID}`)}>{perfil ? perfil.DESCRICAO : ''}</td>
                                                        <td><ListActions {...this.props} {...this.state} id={perfil.ID} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>,
                                    <TablePaginator {...this.props} {...this.state} />
                                ])}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        perfis: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(PerfilList);

