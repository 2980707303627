import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Collapse, Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { saveObj, fetchObj, fetchObjs, updateObj, deleteObj } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';
import ImageCrop from '../../containers/ImageCrop/ImageCrop';
import Pessoa from '../Pessoa/Pessoa';
import PessoaFisica from '../PessoaFisica/PessoaFisica';
import Endereco from '../Endereco/Endereco';
import Email from '../Email/Email';
import Telefone from '../Telefone/Telefone';
import status from './status.json';

class Funcionario extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      CARGO: '',
      DATA_INICIO: '',
      IMAGEM: '',
      URL: '',
      STATUS: '',
      status: status,
      uploadImage: {},
      endereco: {},
      pessoa: {},
      pessoaFisica: {},
      cargos: [],
      emails: [],
      emailsRemove: [],
      telefones: [],
      telefonesRemove: [],
      errors: {},
      loading: false,
      redirect: false,
      email: [true],
      telefone: [true],
      activeTab: 0,
      url: '/funcionarios',
      nameObj: 'funcionario'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeChild = this.handleChangeChild.bind(this);
    this.handleChangeParamChild = this.handleChangeParamChild.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toogleEmail = this.toogleEmail.bind(this);
    this.toogleTelefone = this.toogleTelefone.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props });
          this.setState({ DATA_INICIO: new Date(this.state.DATA_INICIO).toISOString().split('T')[0] });
          this.props.fetchObjs(`/emailspessoas/${this.state.pessoaFisica.pessoa.ID}`)
            .then(() => {
              this.setState({ emails: this.props.records ? this.props.records : null });
            });
          this.props.fetchObjs(`/telefonespessoas/${this.state.pessoaFisica.pessoa.ID}`)
            .then(() => {
              this.setState({ telefones: this.props.records ? this.props.records : null });
            });
        });
    } else {
      this.newEmail();
      this.newTelefone();
    }
    this.props.fetchObjs('/cargos', 1, 1000)
      .then(() => { this.setState({ cargos: this.props.records }) });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeChild = (name, value, objName, index) => {
    let obj = this.state[objName];
    if (index !== undefined && index !== null) {
      obj[index][name] = value;
    } else {
      obj[name] = value;
    }
    this.setState({ [objName]: obj });
  }

  handleChangeParamChild = (name, value) => {
    this.setState({ [name]: value });
  }

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveObj(this.state)
      .then(() => this.props.history.push(this.state.url));
  }

  toogleEmail(tab) {
    const prevState = this.state.email;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState({
      email: state,
    });
  }

  toogleTelefone(tab) {
    const prevState = this.state.telefone;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState({
      telefone: state,
    });
  }

  newEmail() {
    let emails = this.state.emails;
    if (!emails) {
      emails = [];
    }
    emails.push({});
    this.setState({ emails });
  }

  removeEmail(index) {
    let emails = this.state.emails;
    let emailsRemove = this.state.emailsRemove;
    if (emails[index].ID) {
      emailsRemove.push(emails[index]);
    }
    emails.splice(index, 1);
    this.setState({ emails, emailsRemove });
  }

  newTelefone() {
    let telefones = this.state.telefones;
    if (!telefones) {
      telefones = [];
    }
    telefones.push({});
    this.setState({ telefones });
  }

  removeTelefone(index) {
    let telefones = this.state.telefones;
    let telefonesRemove = this.state.telefonesRemove;
    if (telefones[index].ID) {
      telefonesRemove.push(telefones[index]);
    }
    telefones.splice(index, 1);
    this.setState({ telefones, telefonesRemove });
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <strong>Cadastro</strong> Catequista
                </CardHeader>
            <CardBody>
              {this.state.ID ?
                <FormGroup row>
                  <Col md="2">
                    <Label>Código</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <p className="form-control-static">{this.state.ID}</p>
                  </Col>
                </FormGroup>
                : null}
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="text-input">Imagem</Label>
                </Col>
                <Col xs="12" md="5">
                  <ImageCrop onChange={this.handleChangeParamChild} {...this.state} />
                </Col>
              </FormGroup>
            </CardBody>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardBody>
                <Pessoa {...this.props} {...this.state} {...{ onChange: this.handleChangeChild }} />
                <PessoaFisica {...this.props} {...this.state} {...{ onChange: this.handleChangeChild }} />
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Cargo</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="CARGO"
                      value={this.state.CARGO}
                      onChange={this.handleChange}
                      id="CARGO"
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.cargos ?
                          this.state.cargos.map((cargo, i) => {
                            return (
                              <option key={cargo.ID} value={cargo.ID}>{cargo.DESCRICAO}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Data Início</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="date"
                      name="DATA_INICIO"
                      value={this.state.DATA_INICIO}
                      onChange={this.handleChange}
                      id="DATA_INICIO"
                      required>
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Status</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="STATUS"
                      value={this.state.STATUS}
                      onChange={this.handleChange}
                      id="STATUS"
                      required>
                      {
                        this.state.status ?
                          this.state.status.map((status, i) => {
                            return (
                              <option key={i} value={i}>{status}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <Endereco {...this.props} {...this.state} {...{ onChange: this.handleChangeChild }} />
              </CardBody>
              <CardBody>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="6" sm="6">
                        <Button
                          block color="link"
                          className="text-left m-0 p-0"
                          onClick={() => this.toogleEmail(0)}
                          aria-expanded={this.state.email[0]}
                          aria-controls="collapseOne"
                          style={{ color: 'black' }}>
                          <strong>Emails</strong>
                        </Button>
                      </Col>
                      <Col xs="6" sm="6"
                        style={{ textAlign: 'right' }}>
                        <Button type="button" size="sm" color="primary" onClick={() => this.newEmail()}>
                          <i className="fa fa-plus"></i> Novo
                      </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.email[0]} data-parent="#email" id="collapseOne" aria-labelledby="headingOne">
                    <CardBody>
                      <Row>
                        {
                          this.state.emails ? this.state.emails.map((item, i) => {
                            return ([
                              <Col xs="12" md="11" sm="12"
                                key={`colEmailFather${i}`}>
                                <Email key={`email${i}`} index={i} {...this.props} {...item.email} {...{ onChange: this.handleChangeChild }} />
                              </Col>,
                              <Col xs="12" md="1" sm="12"
                                key={`colBtnRemoveEmailFather${i}`}
                                style={{ textAlign: 'center', paddingTop: 3 }}>
                                <Button key={`Btnemail${i}`} type="reset" size="sm" color="danger" onClick={() => this.removeEmail(i)}>
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </Col>
                            ])
                          }) : null
                        }
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </CardBody>
              <CardBody>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="6" sm="6">
                        <Button
                          block color="link"
                          className="text-left m-0 p-0"
                          onClick={() => this.toogleTelefone(0)}
                          aria-expanded={this.state.telefone[0]}
                          aria-controls="collapseOne"
                          style={{ color: 'black' }}>
                          <strong>Telefones</strong>
                        </Button>
                      </Col>
                      <Col xs="6" sm="6"
                        style={{ textAlign: 'right' }}>
                        <Button type="button" size="sm" color="primary" onClick={() => this.newTelefone()}>
                          <i className="fa fa-plus"></i> Novo
                      </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.telefone[0]} data-parent="#telefone" id="collapseOne" aria-labelledby="headingOne">
                    <CardBody>
                      <Row>
                        {
                          this.state.telefones ? this.state.telefones.map((item, i) => {
                            return ([
                              <Col xs="12" md="11" sm="12" key={`colTelefoneFather${i}`} >
                                <Telefone key={`telefone${i}`} index={i} {...this.props} {...item.telefone} {...{ onChange: this.handleChangeChild }} />
                              </Col>,
                              <Col xs="12" md="1" sm="12"
                                key={`colBtnRemoveTelefoneFather${i}`}
                                style={{ textAlign: 'center', paddingTop: 3 }}>
                                <Button key={`BtnTelefone${i}`} type="reset" size="sm" color="danger" onClick={() => this.removeTelefone(i)}>
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </Col>
                            ])
                          }) : null
                        }
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row >
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, fetchObjs, updateObj, deleteObj })(Funcionario);