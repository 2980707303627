import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { saveObj, fetchObjs, fetchObj, updateObj, deleteObj } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';
import ImageCrop from '../../containers/ImageCrop/ImageCrop';

class Despesa extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      DATA: '',
      VALOR: '',
      DESCRICAO: '',
      TIPO_DESPESA: '',
      IMAGEM: '',
      URL: '',
      uploadImage: {},
      tiposdespesas: [],
      contas: [],
      errors: {},
      loading: false,
      redirect: false,
      url: '/despesas',
      nameObj: 'despesa'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeChild = this.handleChangeChild.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props });
          this.setState({ DATA: new Date(this.state.DATA).toISOString().split('T')[0] });
        });
    }
    this.props.fetchObjs('/tiposdespesas', 1, 1000)
      .then(() => { this.setState({ tiposdespesas: this.props.records }) });
    this.props.fetchObjs('/contas', 1, 1000)
      .then(() => { this.setState({ contas: this.props.records }) });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeChild = (name, value) => {
    this.setState({ [name]: value });
  }

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveObj(this.state)
      .then((props) => this.props.history.push(this.state.url));
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <strong>Cadastro</strong> Despesa
                </CardHeader>
            <CardBody>
              {this.state.ID ?
                <FormGroup row>
                  <Col md="2">
                    <Label>Código</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <p className="form-control-static">{this.state.ID}</p>
                  </Col>
                </FormGroup>
                : null}
              <FormGroup row>
                <Col md="2">
                  <Label htmlFor="text-input">Imagem</Label>
                </Col>
                <Col xs="12" md="5">
                  <ImageCrop onChange={this.handleChangeChild} {...this.state} />
                </Col>
              </FormGroup>
            </CardBody>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardBody>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Data</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="date"
                      name="DATA"
                      value={this.state.DATA}
                      onChange={this.handleChange}
                      id="DATA"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Valor</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="number"
                      name="VALOR"
                      value={this.state.VALOR}
                      onChange={this.handleChange}
                      id="VALOR"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Tipo</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="TIPO_DESPESA"
                      value={this.state.TIPO_DESPESA}
                      onChange={this.handleChange}
                      id="TIPO_DESPESA"
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.tiposdespesas ?
                          this.state.tiposdespesas.map((tipoDespesa, i) => {
                            return (
                              <option key={tipoDespesa.ID} value={tipoDespesa.ID}>{tipoDespesa.DESCRICAO}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Descrição</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="DESCRICAO"
                      value={this.state.DESCRICAO}
                      onChange={this.handleChange}
                      id="DESCRICAO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Conta bancária</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="CONTA"
                      value={this.state.CONTA}
                      onChange={this.handleChange}
                      id="CONTA"
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.contas ?
                          this.state.contas.map((conta, i) => {
                            return (
                              <option key={conta.ID} value={conta.ID}>{`Banco:${conta.BANCO} - Ag:${conta.AGENCIA} - CC: ${conta.CONTA}`}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, updateObj, deleteObj, fetchObjs })(Despesa);