import React, { Component } from 'react';
import { ButtonGroup, Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import TablePaginator from '../../containers/TablePaginator/TablePaginator';
import ListActions from '../../containers/ListActions/ListActions';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj } from '../../actions';
import DownloadActions from '../../containers/DownloadActions/DownloadActions';

const emptyMessage = (<p>Não Existem registros para serem exibidos.</p>);

class ContaList extends Component {

    state = {
        url: '/contas'
    }

    componentDidMount() {
        this.props.fetchObjs(this.state.url);
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                        <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Contas</strong>
                                    </Col>
                                    <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <ButtonGroup>
                                            <Button type="button" size="sm" color="primary" onClick={() => this.props.history.push(`${this.state.url}/new`)}>
                                                <i className="fa fa-plus"></i> Novo
                                        </Button>
                                            <DownloadActions body={this.props.contas.length !== 0 ? this.props.contas : null} />
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.props.contas.length === 0 ? emptyMessage : ([
                                    <Table key="table" hover bordered striped responsive size="sm">
                                        <thead key="thead" >
                                            <tr key="head">
                                                <th key="id">Código</th>
                                                <th key="banco">Banco</th>
                                                <th key="agencia">Agência</th>
                                                <th key="conta">Número da Conta</th>
                                                <th key="tipo">Tipo</th>
                                                <th xs="12" sm="1" key="acoes" className="text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody key="tbody" >
                                            {this.props.contas.map((conta, i) => {
                                                return (
                                                    <tr key={`tr${i}`} >
                                                        <td key={`id${i}`} onClick={() => this.props.history.push(`${this.state.url}/${conta.ID}`)}>{conta ? conta.ID : ''}</td>
                                                        <td key={`banco${i}`} onClick={() => this.props.history.push(`${this.state.url}/${conta.ID}`)}>{conta ? conta.BANCO : ''}</td>
                                                        <td key={`agencia${i}`} onClick={() => this.props.history.push(`${this.state.url}/${conta.ID}`)}>{conta ? conta.AGENCIA : ''}</td>
                                                        <td key={`conta${i}`} onClick={() => this.props.history.push(`${this.state.url}/${conta.ID}`)}>{conta ? conta.CONTA : ''}</td>
                                                        <td key={`tipo${i}`} onClick={() => this.props.history.push(`${this.state.url}/${conta.ID}`)}>{conta && conta.tipoConta ? conta.tipoConta.DESCRICAO : ''}</td>
                                                        <td key={`actions${i}`}><ListActions {...this.props} {...this.state} id={conta.ID} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>,
                                    <TablePaginator key="paginator" {...this.props} {...this.state} />
                                ])}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        contas: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(ContaList);

