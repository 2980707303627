import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import TablePaginator from '../../containers/TablePaginator/TablePaginator';
import ListActions from '../../containers/ListActions/ListActions';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj } from '../../actions';

const emptyMessage = (<p>Não Existem registros para serem exibidos.</p>);

class AtividadeList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            NOME: '',
            atividades: [],
            projetos: [],
            url: '/atividades',
            filtro: [true]
        }
        this.toogleFiltro = this.toogleFiltro.bind(this);
        this.mapPropsToState = this.mapPropsToState.bind(this);
    }

    componentDidMount() {
        this.props.fetchObjs(`${this.state.url}`)
            .then(() => { this.setState({ atividades: this.props.records }) });
    }

    mapPropsToState = () => {
        this.setState({ atividades: this.props.records });
    }

    toogleFiltro(tab) {
        const prevState = this.state.filtro;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        this.setState({
            filtro: state,
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        let state = this.state;
        state[e.target.name] = e.target.value;
        this.buscaDados(state);
    }

    buscaDados(state) {
        let query = '';
        this.props.fetchObjs(`${this.state.url}${query}`)
            .then(() => {
                this.setState({ atividades: this.props.records })
            });
    }
    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Atividades</strong>
                                    </Col>
                                    <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <Button type="button" size="sm" color="primary" onClick={() => this.props.history.push(`${this.state.url}/new`)}>
                                            <i className="fa fa-plus"></i> Novo
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.state.atividades && this.state.atividades.length !== 0 ? ([
                                    <Table hover bordered striped responsive size="sm">
                                        <thead>
                                            <tr key="head">
                                                <th key="nome">Nome</th>
                                                <th xs="12" sm="1" key="acoes" className="text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.atividades && this.state.atividades.map((atividade, i) => {
                                                return (
                                                    <tr>
                                                        <td onClick={() => this.props.history.push(`${this.state.url}/${atividade.ID}`)}>{atividade ? atividade.NOME : ''}</td>
                                                        <td><ListActions {...this.props} {...this.state} id={atividade.ID} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>,
                                    <TablePaginator {...this.props} {...this.state} callback={this.mapPropsToState} />
                                ]) : emptyMessage}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        records: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(AtividadeList);

