import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { autenticar, fetchObj } from '../../../actions';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import md5 from 'md5';

class Login extends Component {

  constructor(props) {
    super(props);
    localStorage.setItem('token', null);
    localStorage.setItem('usuarioLogado', null);
    this.state = {
      login: '',
      password: ''
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  autenticar = (event) => {
    let state = this.state;
    state.login = this.state.login;
    state.password = md5(this.state.password);
    event.preventDefault();
    this.props.autenticar(state)
      .then(() => {
        if (this.props && this.props.token) {
          localStorage.setItem('token', this.props.token);
          localStorage.setItem('usuarioLogado', JSON.stringify(jwt.decode(this.props.usuarioLogado)));
          if (state.password === md5('123456')) {
            this.props.history.push('register');
          } else {
            this.props.history.push('dashboard');
          }
        }
      });
  }

  forgotPassword = () => {
    this.props.history.push('remind');
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <CardGroup>
                <Card className="p-4">
                  <form onSubmit={this.autenticar.bind(this)}>
                    <CardBody>
                      <h1>Login</h1>
                      <p className="text-muted">Entre com a sua conta</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="Usuário" name="login" onChange={this.handleChange} />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Senha" name="password" onChange={this.handleChange} />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button type="submit" color="primary" className="px-4">Entrar</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0" onClick={() => { this.forgotPassword() }}>Esqueci minha senha?</Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </form>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { autenticar, fetchObj })(Login);
