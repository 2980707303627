import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, FormText, Button, Collapse, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { saveObj, fetchObj, fetchObjs, updateObj, deleteObj, staticUrl } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';
import ImageCrop from '../../containers/ImageCrop/ImageCrop';
import Pessoa from '../Pessoa/Pessoa';
import PessoaFisica from '../PessoaFisica/PessoaFisica';
import Endereco from '../Endereco/Endereco';
import Email from '../Email/Email';
import Telefone from '../Telefone/Telefone';
import BeneficiarioDocumento from '../BeneficiarioDocumento/BeneficiarioDocumento';
import Select from 'react-select';
import { dateFormatter } from '../../utils/utils';
import tipoDocumento from '../TipoDocumento/tipoDocumento.json';

class Beneficiario extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      CARGO: '',
      IMAGEM: '',
      URL: '',
      ESTADO_CIVIL: 'Solteiro',
      NOME_MAE: '',
      PROFISSAO_MAE: '',
      NOME_PAI: '',
      PROFISSAO_PAI: '',
      DT_BATISMO: '',
      LOCAL_BATISMO: '',
      LOCAL_NASCIMENTO: '',
      NOME_PADRINHO: '',
      CREATED_ON: '',
      situacao: '',
      situacaoPadrinho: '',
      uploadImage: {},
      endereco: {},
      pessoa: {},
      pessoaFisica: {},
      cargos: [],
      emails: [],
      emailsRemove: [],
      telefones: [],
      telefonesRemove: [],
      beneficiarioSacramentos: [],
      beneficiarioSacramentosRemove: [],
      sacramentos: [],
      sacramentosOptions: [],
      tipoDocumento: tipoDocumento,
      errors: {},
      loading: false,
      redirect: false,
      email: [true],
      telefone: [true],

      beneficiarioDocumento: [true],
      beneficiariosDocumentos: [],
      beneficiariosDocumentosRemove: [],

      
      matricula: [false],
      beneficiarioMatricula: {

      },

      activeTab: 0,
      url: '/beneficiarios',
      nameObj: 'beneficiario'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeChild = this.handleChangeChild.bind(this);
    this.handleChangeParamChild = this.handleChangeParamChild.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toogleEmail = this.toogleEmail.bind(this);
    this.toogleTelefone = this.toogleTelefone.bind(this);
    this.toogleBeneficiarioDocumento = this.toogleBeneficiarioDocumento.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props });
          this.setState({
            DT_NASCIMENTO: new Date(this.state.DT_NASCIMENTO).toISOString().split('T')[0],
            DT_BATISMO: new Date(this.state.DT_BATISMO).toISOString().split('T')[0]
          });
          this.setState({ beneficiariosDocumentos: this.props.beneficiarioDocumentos ? this.props.beneficiarioDocumentos : null });
          let beneficiarioSacramentos = this.props.beneficiarioSacramentos;
          beneficiarioSacramentos.map((element) => {
            return element.label = element.value = element.sacramento.DESCRICAO;
          });
          this.setState({ beneficiarioSacramentos });
          this.props.fetchObjs(`/emailspessoas/${this.state.pessoaFisica.pessoa.ID}`)
            .then(() => {
              this.setState({ emails: this.props.records ? this.props.records : null });
            });
          this.props.fetchObjs(`/telefonespessoas/${this.state.pessoaFisica.pessoa.ID}`)
            .then(() => {
              this.setState({ telefones: this.props.records ? this.props.records : null });
            });
        });
    } else {
      this.newTelefone();
      this.prepareDocumentos();
    }
    this.props.fetchObjs('/sacramentos', 1, 1000)
      .then(() => {
        let sacramentos = this.props.records;
        let beneficiarioSacramentos = [];
        sacramentos.map((element) => {
          return beneficiarioSacramentos.push({
            label: element.DESCRICAO,
            value: element.DESCRICAO,
            sacramento: element
          });
        });
        this.setState({ sacramentosOptions: beneficiarioSacramentos });
      });
  }

  prepareDocumentos = () => {
    let beneficiariosDocumentos = this.state.beneficiariosDocumentos;
    if (!beneficiariosDocumentos) {
      beneficiariosDocumentos = [];
    }
    this.state.tipoDocumento.map((item, i) => {
      return beneficiariosDocumentos.push({ TIPO_DOCUMENTO: i });
    });
    this.setState({ beneficiariosDocumentos });
  }

  filterSacramentos = (inputValue) => {
    return this.state.sacramentosOptions.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  handleChangeSacramento = (newValue, actionMeta) => {
    newValue = newValue ? newValue : {};
    let { beneficiarioSacramentosRemove } = this.state;
    if (actionMeta.action === 'remove-value' && actionMeta.removedValue.ID) {
      beneficiarioSacramentosRemove.push(actionMeta.removedValue);
    }
    this.setState({ beneficiarioSacramentos: newValue, beneficiarioSacramentosRemove });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeChild = (name, value, objName, index) => {
    let obj = this.state[objName];
    if (index !== undefined && index !== null) {
      obj[index][name] = value;
    } else {
      obj[name] = value;
    }
    this.setState({ [objName]: obj });
  }

  handleChangeParamChild = (name, value) => {
    this.setState({ [name]: value });
  }

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    console.log(this.state.beneficiarioMatricula);
    e.preventDefault();
    console.log(this.state.beneficiarioMatricula);
    this.saveObj(this.state)
      .then(() => this.props.history.push(this.state.url));
  }

  toogleEmail(tab) {
    const prevState = this.state.email;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState({
      email: state,
    });
  }

  toogleTelefone(tab) {
    const prevState = this.state.telefone;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState({
      telefone: state,
    });
  }

  toogleBeneficiarioDocumento(tab) {
    const prevState = this.state.beneficiarioDocumento;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState({
      beneficiarioDocumento: state,
    });
  }
  
  toogleBeneficiarioMatricula(tab) {
    const prevState = this.state.matricula;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState({
      matricula: state,
    });
  }

  newEmail() {
    let emails = this.state.emails;
    if (!emails) {
      emails = [];
    }
    emails.push({});
    this.setState({ emails });
  }

  removeEmail(index) {
    let emails = this.state.emails;
    let emailsRemove = this.state.emailsRemove;
    if (emails[index].ID) {
      emailsRemove.push(emails[index]);
    }
    emails.splice(index, 1);
    this.setState({ emails, emailsRemove });
  }

  newTelefone() {
    let telefones = this.state.telefones;
    if (!telefones) {
      telefones = [];
    }
    telefones.push({});
    this.setState({ telefones });
  }

  removeTelefone(index) {
    let telefones = this.state.telefones;
    let telefonesRemove = this.state.telefonesRemove;
    if (telefones[index].ID) {
      telefonesRemove.push(telefones[index]);
    }
    telefones.splice(index, 1);
    this.setState({ telefones, telefonesRemove });
  }

  handleImprimirInfo = () => {
    let query = `?token=${localStorage.getItem('token')}`;
    window.open(`${staticUrl}/beneficiarios/reports/infos/${this.state.ID}${query}`, "_self")
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="6" sm="6">
                  <strong>Cadastro</strong> Catequizando
                </Col>
                <Col  xs="6" sm="6" style={{ textAlign: 'right' }}>
                  {this.state.ID ?
                    <Button type="button" size="sm" color="success" onClick={() => this.handleImprimirInfo()}>
                      <i className="fa fa-file-text-o"></i> Imprimir ficha
                    </Button>
                  : null}
                </Col>
              </Row>
            </CardHeader>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardBody>
                {this.state.situacao ?
                  <FormGroup style={{ color: "red" }} row>
                    <Col md="2">
                      <Label>Situação do catequizando</Label>
                    </Col>
                    <Col xs="12" md="10">
                      <p className="form-control-static">{this.state.situacao}</p>
                    </Col>
                  </FormGroup>
                  : null}
                {this.state.situacaoPadrinho ?
                  <FormGroup style={{ color: "orange" }} row>
                    <Col md="2">
                      <Label>Situação Padrinho</Label>
                    </Col>
                    <Col xs="12" md="10">
                      <p className="form-control-static">{this.state.situacaoPadrinho}</p>
                    </Col>
                  </FormGroup>
                  : null}
                {this.state.ID || this.state.CREATED_ON ?
                  <FormGroup row>
                    {this.state.ID ?
                      [<Col md="2">
                        <Label>Código</Label>
                      </Col>,
                      <Col xs="6" md="4">
                        <p className="form-control-static">{this.state.ID}</p>
                      </Col>]
                      : null}
                    {this.state.CREATED_ON ?
                      [<Col md="2">
                        <Label>Data de Cadastro</Label>
                      </Col>,
                      <Col xs="6" md="4">
                        <p className="form-control-static">{dateFormatter(new Date(this.state.CREATED_ON))}</p>
                      </Col>]
                      : null}
                  </FormGroup>
                  : null}
                {this.state.IMG ?
                  <FormGroup row>
                    <Col md="2">
                      <Label htmlFor="text-input">Imagem</Label>
                    </Col>
                    <Col xs="12" md="5">
                      <ImageCrop onChange={this.handleChangeParamChild} {...this.state} />
                    </Col>
                  </FormGroup>
                  : null}
                <Pessoa {...this.props} {...this.state} {...{ onChange: this.handleChangeChild }} />
                <PessoaFisica {...this.props} {...this.state} {...{ onChange: this.handleChangeChild }} />
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Estado Civil</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="ESTADO_CIVIL"
                      value={this.state.ESTADO_CIVIL}
                      onChange={this.handleChange}
                      id="ESTADO_CIVIL"
                      required>
                      <option value="1">Solteiro</option>
                      <option value="2">Casado Cartório</option>
                      <option value="3">Casado Igreja</option>
                      <option value="4">Amasiado</option>
                      <option value="5">Separado</option>
                      <option value="6">Divorciado</option>
                      <option value="7">Viúvo</option>
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Sacramentos</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Select
                      isMulti
                      onChange={this.handleChangeSacramento}
                      options={this.state.sacramentosOptions}
                      value={this.state.beneficiarioSacramentos}
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Nome da Mãe</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="NOME_MAE"
                      value={this.state.NOME_MAE}
                      onChange={this.handleChange}
                      id="NOME_MAE"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Profissão da Mãe</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="PROFISSAO_MAE"
                      value={this.state.PROFISSAO_MAE}
                      onChange={this.handleChange}
                      id="PROFISSAO_MAE"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Nome do Pai</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="NOME_PAI"
                      value={this.state.NOME_PAI}
                      onChange={this.handleChange}
                      id="NOME_PAI"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Profissão do Pai</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="PROFISSAO_PAI"
                      value={this.state.PROFISSAO_PAI}
                      onChange={this.handleChange}
                      id="PROFISSAO_PAI"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Data de Batismo</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="date"
                      name="DT_BATISMO"
                      value={this.state.DT_BATISMO}
                      onChange={this.handleChange}
                      id="DT_BATISMO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Local de Batismo</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="LOCAL_BATISMO"
                      value={this.state.LOCAL_BATISMO}
                      onChange={this.handleChange}
                      id="LOCAL_BATISMO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Data de Nascimento</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="date"
                      name="DT_NASCIMENTO"
                      value={this.state.DT_NASCIMENTO}
                      onChange={this.handleChange}
                      id="DT_NASCIMENTO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Local de Nascimento</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="LOCAL_NASCIMENTO"
                      value={this.state.LOCAL_NASCIMENTO}
                      onChange={this.handleChange}
                      id="LOCAL_NASCIMENTO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Nome do Padrinho</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="NOME_PADRINHO"
                      value={this.state.NOME_PADRINHO}
                      onChange={this.handleChange}
                      id="NOME_PADRINHO"
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Estado Civil Padrinho</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="ESTADO_CIVIL_PADRINHO"
                      value={this.state.ESTADO_CIVIL_PADRINHO}
                      onChange={this.handleChange}
                      id="ESTADO_CIVIL_PADRINHO"
                      required>
                      <option value="1">Solteiro</option>
                      <option value="2">Casado Cartório</option>
                      <option value="3">Casado Igreja</option>
                      <option value="4">Amasiado</option>
                      <option value="5">Separado</option>
                      <option value="6">Divorciado</option>
                      <option value="7">Viúvo</option>
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <Endereco {...this.props} {...this.state} {...{ onChange: this.handleChangeChild }} />
              </CardBody>
              <CardBody>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="6" sm="6">
                        <Button
                          block color="link"
                          className="text-left m-0 p-0"
                          onClick={() => this.toogleEmail(0)}
                          aria-expanded={this.state.email[0]}
                          aria-controls="collapseOne"
                          style={{ color: 'black' }}>
                          <strong>Emails</strong>
                        </Button>
                      </Col>
                      <Col xs="6" sm="6"
                        style={{ textAlign: 'right' }}>
                        <Button type="button" size="sm" color="primary" onClick={() => this.newEmail()}>
                          <i className="fa fa-plus"></i> Novo
                      </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.email[0]} data-parent="#email" id="collapseOne" aria-labelledby="headingOne">
                    <CardBody>
                      <Row>
                        {
                          this.state.emails ? this.state.emails.map((item, i) => {
                            return ([
                              <Col xs="12" md="11" sm="12"
                                key={`colEmailFather${i}`}>
                                <Email key={`email${i}`} index={i} {...this.props} {...item.email} {...{ onChange: this.handleChangeChild }} />
                              </Col>,
                              <Col xs="12" md="1" sm="12"
                                key={`colBtnRemoveEmailFather${i}`}
                                style={{ textAlign: 'center', paddingTop: 3 }}>
                                <Button key={`Btnemail${i}`} type="reset" size="sm" color="danger" onClick={() => this.removeEmail(i)}>
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </Col>
                            ])
                          }) : null
                        }
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="6" sm="6">
                        <Button
                          block color="link"
                          className="text-left m-0 p-0"
                          onClick={() => this.toogleTelefone(0)}
                          aria-expanded={this.state.telefone[0]}
                          aria-controls="collapseOne"
                          style={{ color: 'black' }}>
                          <strong>Telefones</strong>
                        </Button>
                      </Col>
                      <Col xs="6" sm="6"
                        style={{ textAlign: 'right' }}>
                        <Button type="button" size="sm" color="primary" onClick={() => this.newTelefone()}>
                          <i className="fa fa-plus"></i> Novo
                      </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.telefone[0]} data-parent="#telefone" id="collapseOne" aria-labelledby="headingOne">
                    <CardBody>
                      <Row>
                        {
                          this.state.telefones ? this.state.telefones.map((item, i) => {
                            return ([
                              <Col xs="12" md="11" sm="12" key={`colTelefoneFather${i}`} >
                                <Telefone key={`telefone${i}`} index={i} {...this.props} {...item.telefone} {...{ onChange: this.handleChangeChild }} />
                              </Col>,
                              <Col xs="12" md="1" sm="12"
                                key={`colBtnRemoveTelefoneFather${i}`}
                                style={{ textAlign: 'center', paddingTop: 3 }}>
                                {
                                  i === 0 ? null :
                                    <Button key={`BtnTelefone${i}`} type="reset" size="sm" color="danger" onClick={() => this.removeTelefone(i)}>
                                      <i className="fa fa-trash"></i>
                                    </Button>
                                }
                              </Col>
                            ])
                          }) : null
                        }
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="2" sm="2">
                        <Button
                          block color="link"
                          className="text-left m-0 p-0"
                          aria-expanded={this.state.matricula[0]}
                          aria-controls="collapseOne"
                          style={{ color: 'black' }}>
                          <strong>Pré-Matrícula</strong>
                        </Button>
                      </Col>
                      <Col xs="1" sm="1">
                        <Input
                            type="checkbox"
                            name="matricula"
                            onClick={() => this.toogleBeneficiarioMatricula(0)}
                            id="MATRICULA"
                          />
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.matricula[0]} data-parent="#matricula" id="collapseOne" aria-labelledby="headingOne">
                    <CardBody>
                      <FormGroup row>
                        <Col md="2">
                          <Label htmlFor="text-input">Data</Label>
                        </Col>
                        <Col xs="12" md="10">
                          <Input
                            type="date"
                            name="DATA"
                            value={this.state.beneficiarioMatricula.DATA}
                            onChange={(e) => {this.handleChangeChild(e.target.name, e.target.value, 'beneficiarioMatricula')}}
                            id="DATA"
                            required={this.state.matricula[0]}
                          />
                          <FormText color="muted"></FormText>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="2">
                          <Label htmlFor="text-input">Vigência</Label>
                        </Col>
                        <Col xs="12" md="10">
                          <Input
                            type="date"
                            name="VIGENCIA"
                            value={this.state.beneficiarioMatricula.VIGENCIA}
                            onChange={(e) => {this.handleChangeChild(e.target.name, e.target.value, 'beneficiarioMatricula')}}
                            id="VIGENCIA"
                            required={this.state.matricula[0]}
                          />
                          <FormText color="muted"></FormText>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="2">
                          <Label htmlFor="text-input">Observação</Label>
                        </Col>
                        <Col xs="12" md="10">
                          <Input
                            type="textarea"
                            name="OBSERVACAO"
                            value={this.state.beneficiarioMatricula.OBSERVACAO}
                            onChange={(e) => {this.handleChangeChild(e.target.name, e.target.value, 'beneficiarioMatricula')}}
                            id="OBSERVACAO">
                          </Input>
                          <FormText color="muted"></FormText>
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="6" sm="6">
                        <Button
                          block color="link"
                          className="text-left m-0 p-0"
                          onClick={() => this.toogleBeneficiarioDocumento(0)}
                          aria-expanded={this.state.beneficiarioDocumento[0]}
                          aria-controls="collapseOne"
                          style={{ color: 'black' }}>
                          <strong>Documentos</strong>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.beneficiarioDocumento[0]} data-parent="#beneficiarioDocumento" id="collapseOne" aria-labelledby="headingOne">
                    <CardBody>
                      {
                        this.state.beneficiariosDocumentos ? this.state.beneficiariosDocumentos.map((item, i) => {
                          return ([
                            <Row>
                              <Col md="4">
                                <Label htmlFor="text-input">{this.state.tipoDocumento[item.TIPO_DOCUMENTO]}</Label>
                              </Col>
                              <Col xs="12" md="8" sm="12"
                                key={`colBeneficiarioDocumentoFather${i}`}>
                                <BeneficiarioDocumento key={`beneficiarioDocumento${i}`} index={i} {...this.props} {...item} {...{ onChange: this.handleChangeChild }} />
                              </Col>
                            </Row>
                          ])
                        }) : null
                      }
                    </CardBody>
                  </Collapse>
                </Card>
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row >
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, fetchObjs, updateObj, deleteObj })(Beneficiario);