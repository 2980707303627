import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row, Button } from 'reactstrap';
import { saveObj, fetchObjs, fetchObj, updateObj, deleteObj } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';
import Select from 'react-select';
import swal from 'sweetalert2';
import status from './status.json';

class Matricula extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      ANO: '',
      DATA: '',
      VIGENCIA: '',
      TURMA: '',
      PESSOA: '',
      STATUS: '',
      status: status,
      OBSERVACAO: '',
      turmas: [],
      pessoas: [],
      matriculaMotivos: [],
      matriculaSacramentos: [],
      matriculaSacramentosRemove: [],
      sacramentos: [],
      sacramentosOptions: [],
      errors: {},
      loading: false,
      redirect: false,
      url: '/matriculas',
      nameObj: 'matricula'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeChild = this.handleChangeChild.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTrancar = this.handleTrancar.bind(this);
  }

  componentDidMount = () => { 
    
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props });
          this.setState({ DATA: new Date(this.state.DATA).toISOString().split('T')[0], VIGENCIA: new Date(this.state.VIGENCIA).toISOString().split('T')[0] });
          this.props.fetchObjs(`/sacramentos/matriculas/${match.params.id}`)
            .then(() => {
              let matriculaSacramentos = this.props.records;
              matriculaSacramentos.map((element) => {
                return element.label = element.value = element.sacramento.DESCRICAO;
              });
              this.setState({ matriculaSacramentos });
            });
        });
    }
    this.props.fetchObjs('/sacramentos', 1, 1000)
      .then(() => {
        let sacramentos = this.props.records;
        let matriculaSacramentos = [];
        sacramentos.map((element) => {
          return matriculaSacramentos.push({
            label: element.DESCRICAO,
            value: element.DESCRICAO,
            sacramento: element
          });
        });
        this.setState({ sacramentosOptions: matriculaSacramentos });
      });
    this.props.fetchObjs('/turmas', 1, 1000)
      .then(() => { this.setState({ turmas: this.props.records }) });
    this.props.fetchObjs('/pessoas', 1, 1000)
      .then(() => { this.setState({ pessoas: this.props.records }) });
  }

  filterSacramentos = (inputValue) => {
    return this.state.sacramentosOptions.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  handleChangeSacramento = (newValue, actionMeta) => {
    newValue = newValue ? newValue : {};
    let { matriculaSacramentosRemove } = this.state;
    if (actionMeta.action === 'remove-value' && actionMeta.removedValue.ID) {
      matriculaSacramentosRemove.push(actionMeta.removedValue);
    }
    this.setState({ matriculaSacramentos: newValue, matriculaSacramentosRemove });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeChild = (name, value) => {
    this.setState({ [name]: value });
  }

  handleTrancar = (e) => {
    swal.mixin({
      input: 'textarea',
      confirmButtonText: 'Próximo &rarr;',
      cancelButtonText: 'Cancelar',
      cancelButtonClass: 'btn btn-danger',
      showCancelButton: true,
      progressSteps: ['1']
    }).queue([
      'Qual o motivo?'
    ]).then((result) => {
      if (result.value) {
        let state = this.state;
        state.MOTIVO = result.value && result.value[0] ? result.value[0] : '';
        swal({
          title: 'Está correto?',
          html: `${result.value && result.value[0] ? result.value[0] : ''}`,
          confirmButtonText: 'Trancar',
          cancelButtonText: 'Cancelar',
          cancelButtonClass: 'btn btn-danger',
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            if (state.ID) {
              return this.props.saveObj(`/trancas/matriculas`, state.nameObj, state);
            }
          }
        })
      }
    })
  }

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveObj(this.state)
      .then(() => this.props.history.push(this.state.url));
  }

  render() {
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="6" sm="6">
                  <strong>Cadastro</strong> Matrícula
                </Col>
                {this.state.STATUS === 1 ?
                  <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                    <Button type="button" size="sm" color="danger" onClick={() => this.handleTrancar()}>
                      <i className="fa fa-lock"></i> Desistiu
                  </Button>
                  </Col>
                  : null}
              </Row>
            </CardHeader>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardBody>
                {this.state.STATUS || this.state.STATUS === 0 ?
                  <FormGroup style={{ color: "red" }} row>
                    <Col md="2">
                      <Label>Status</Label>
                    </Col>
                    <Col xs="12" md="10">
                      <p className="form-control-static">{this.state.status[this.state.STATUS]}</p>
                    </Col>
                  </FormGroup>
                  : null}
                {this.state.ID ?
                  <FormGroup row>
                    <Col md="2">
                      <Label>Código</Label>
                    </Col>
                    <Col xs="12" md="10">
                      <p className="form-control-static">{this.state.ID}</p>
                    </Col>
                  </FormGroup>
                  : null}
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Data</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="date"
                      name="DATA"
                      value={this.state.DATA}
                      onChange={this.handleChange}
                      id="DATA"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Vigência</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="date"
                      name="VIGENCIA"
                      value={this.state.VIGENCIA}
                      onChange={this.handleChange}
                      id="VIGENCIA"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Turma</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="TURMA"
                      value={this.state.TURMA}
                      onChange={this.handleChange}
                      id="TURMA"
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.turmas ?
                          this.state.turmas.map((turma) => {
                            return (
                              <option key={turma.ID} value={turma.ID}>{`${turma.DESCRICAO} - ${turma.ANO} - ${turma.catequista.pessoaFisica.pessoa.NOME}`}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Pessoas</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="PESSOA"
                      value={this.state.PESSOA}
                      onChange={this.handleChange}
                      id="PESSOA"
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.pessoas ?
                          this.state.pessoas.map((pessoa) => {
                            return (
                              <option key={pessoa.ID} value={pessoa.ID}>{`${pessoa.NOME}`}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Sacramentos</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Select
                      isMulti
                      onChange={this.handleChangeSacramento}
                      options={this.state.sacramentosOptions}
                      value={this.state.matriculaSacramentos}
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Observação</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="textarea"
                      name="OBSERVACAO"
                      value={this.state.OBSERVACAO}
                      onChange={this.handleChange}
                      id="OBSERVACAO">
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                {
                  this.state.matriculaMotivos && this.state.matriculaMotivos.length > 0 ?
                    <FormGroup row>
                      <Col md="2">
                        <Label htmlFor="text-input">Motivos</Label>
                      </Col>
                      <Col xs="12" md="10">
                        <Input
                          type="textarea"
                          name="MOTIVO"
                          value={this.state.matriculaMotivos[0].MOTIVO}
                          id="MOTIVO"
                        />
                        <FormText color="muted"></FormText>
                      </Col>
                    </FormGroup>
                    : null
                }
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, updateObj, deleteObj, fetchObjs })(Matricula);