import swal from 'sweetalert2';

export const SET_OBJ = 'SET_OBJ';
export const ADD_OBJ = 'ADD_OBJ';
export const OBJ_FETCHED = 'OBJ_FETCHED';
export const OBJ_UPDATED = 'OBJ_UPDATED';
export const OBJ_DELETED = 'OBJ_DELETED';

let msg = "Ocorreu algum problema com esta operação! \n Tente novamente ou entre em contato com nosso suporte : ) ";

const hostname = window && window.location && window.location.hostname;
export const staticUrl = hostname.includes('cic.explorelabs.com.br') ? 'https://cicapi.explorelabs.com.br/api' : 'http://localhost:4700/api';
export const staticFrontUrl = hostname.includes('cic.explorelabs.com.br') ? 'https://www.cic.explorelabs.com.br' : 'http://localhost:8080';

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    return response.json().then((data) => {
      msg = data.userMessage ? data.userMessage : msg;
      throw msg;
    }).catch((e) => {
      swal("Ops!", msg, "error");
      throw e;
    });
  }
}

export function setObj(obj) {
  return {
    type: SET_OBJ,
    obj
  }
}

export function addObj(obj) {
  return {
    type: ADD_OBJ,
    obj
  }
}

export function objFetched(obj) {
  return {
    type: OBJ_FETCHED,
    obj
  }
}

export function objUpdated(obj) {
  return {
    type: OBJ_UPDATED,
    obj
  }
}

export function objDeleted(id) {
  return {
    type: OBJ_DELETED,
    id
  }
}

export function download(url, body) {
  return dispatch => {
    swal({ onOpen: () => { swal.showLoading() } });
    return fetch(`${staticUrl}${url}/`, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        accept: "aplication/json",
        "Content-Type": "application/json",
        "token": localStorage.getItem('token')
      }
    }).then((data) => {
      return data;
    })
      .catch(err => {
        throw err;
      });
  }
}

export function saveObj(url, obj, data) {
  return dispatch => {
    swal({ onOpen: () => { swal.showLoading() } });
    return fetch(`${staticUrl}${url}`, {
      method: 'post',
      body: JSON.stringify({ [obj]: data }),
      headers: {
        accept: "aplication/json",
        "Content-Type": "application/json",
        "token": localStorage.getItem('token')
      }
    }).then(handleResponse)
      .then((data) => {
        if (data) {
          dispatch(addObj(data));
          swal("", "Registro salvo com sucesso!", "success");
        }
      })
      .catch(err => {
        throw err;
      });
  }
}

export function updateObj(url, obj, data) {
  return dispatch => {
    swal({ onOpen: () => { swal.showLoading() } });
    return fetch(`${staticUrl}${url}/`, {
      method: 'put',
      body: JSON.stringify({ [obj]: data }),
      headers: {
        accept: "aplication/json",
        "Content-Type": "application/json",
        "token": localStorage.getItem('token')
      }
    }).then(handleResponse)
      .then(data => {
        dispatch(objUpdated(data));
        swal("", "Registro atualizado com sucesso!", "success");
      })
      .catch(err => {
        throw err;
      });
  }
}

export function deleteObj(url, id) {
  return dispatch => {
    swal({ onOpen: () => { swal.showLoading() } });
    return fetch(`${staticUrl}${url}/${id}`, {
      method: 'delete',
      headers: {
        accept: "aplication/json",
        "Content-Type": "application/json",
        "token": localStorage.getItem('token')
      }
    }).then(handleResponse)
      .then(data => {
        dispatch(objDeleted(id));
        swal("", "Registro excluído com sucesso!", "success");
      })
      .catch(err => {
        throw err;
      });
  }
}

export function fetchObjs(url, page = 1, records = 10) {
  let query = `${staticUrl}${url}`;
  if (url.indexOf('?') === -1) {
    query += `?page=${page}&limit=${records}`
  }
  return dispatch => {
    // swal({onOpen: () => { swal.showLoading()}});
    return fetch(query, {
      headers: {
        accept: "aplication/json",
        "token": localStorage.getItem('token')
      }
    }).then(res => res.json())
      .then(data => {
        // swal.close()
        dispatch(setObj(data));
      });
  }
}

export function fetchObj(url, id = "") {
  return dispatch => {
    // swal({onOpen: () => { swal.showLoading()}});
    return fetch(`${staticUrl}${url}/${id}`, {
      headers: {
        accept: "aplication/json",
        "token": localStorage.getItem('token')
      }
    }).then(handleResponse)
      .then(data => {
        // swal.close()
        dispatch(objFetched(data));
        if (data.records && data.records.length < 1) {
          swal("Ops!", "Nenhum registro encontrado : ) ", "warning");
        }
      });
  }
}

export function autenticar(user) {
  return dispatch => {
    return fetch(`${staticUrl}/login/web`, {
      headers: new Headers({
        'Content-type': 'application/json',
        "login": user.login,
        "password": user.password
      })
    }).then(handleResponse)
      .then(data => {
        if (data && data.records && data.records.length > 0) {
          dispatch(objFetched(data));
        }
      })
      .catch(err => {
        throw err;
      });
  }
}

export function sendObj(url, obj, data) {
  return dispatch => {
    swal({ onOpen: () => { swal.showLoading() } });
    return fetch(`${staticUrl}${url}`, {
      method: 'post',
      body: JSON.stringify({ [obj]: data }),
      headers: {
        accept: "aplication/json",
        "Content-Type": "application/json",
        "token": localStorage.getItem('token')
      }
    }).then(handleResponse)
      .then((data) => {
        if (data) {
          dispatch(addObj(data));
          swal("", "Enviado com sucesso!", "success");
        }
      })
      .catch(err => {
        throw err;
      });
  }
}
