import React, { Component } from 'react';
import { Label, Input, FormText, FormGroup, ButtonGroup, Button, Card, CardBody, CardHeader, Col, Collapse, Row, Table } from 'reactstrap';
import TablePaginator from '../../containers/TablePaginator/TablePaginator';
import ListActions from '../../containers/ListActions/ListActions';
import { connect } from 'react-redux';
import { fetchObjs, deleteObj } from '../../actions';
import DownloadActions from '../../containers/DownloadActions/DownloadActions';
import { emptyMessage, dateFormatter } from '../../utils/utils';
import Select from 'react-select';
import status from './status.json';

class MatriculaList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            NOME: '',
            ANO: '',
            status: status,
            matriculas: [],
            turmas: [],
            sacramentosFiltro: [],
            sacramentos: [],
            sacramentosOptions: [],
            url: '/matriculas',
            filtro: [true]
        }
        this.toogleFiltro = this.toogleFiltro.bind(this);
        this.mapPropsToState = this.mapPropsToState.bind(this);
        this.mapMatriculasToState = this.mapMatriculasToState.bind(this);
    }

    componentDidMount() {
        this.props.fetchObjs(`${this.state.url}`)
            .then(() => {
                this.setState({
                    matriculas: this.props.records && this.props.records[0] ? this.props.records[0].matriculas.rows : [],
                    estatisticas: this.props.records && this.props.records[0] ? this.props.records[0].matriculas.estatisticas : [],
                })
            });
        this.props.fetchObjs(`/turmas`, 1, 1000)
            .then(() => { this.setState({ turmas: this.props.records }) });
        this.props.fetchObjs('/sacramentos', 1, 1000)
            .then(() => {
                let sacramentos = this.props.records;
                let sacramentosFiltro = [];
                sacramentos.map((element) => {
                    return sacramentosFiltro.push({
                        label: element.DESCRICAO,
                        value: element.DESCRICAO,
                        ...element
                    });
                });
                this.setState({ sacramentosOptions: sacramentosFiltro });
            });
    }

    mapPropsToState = () => {
        this.setState({ matriculas: this.props.records });
    }

    mapMatriculasToState = () => {
        this.setState({
            matriculas: this.props.records && this.props.records[0] ? this.props.records[0].matriculas.rows : [],
            estatisticas: this.props.records && this.props.records[0] ? this.props.records[0].matriculas.estatisticas : [],
        })
    }


    filterSacramentos = (inputValue) => {
        return this.state.sacramentosOptions.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    handleChangeSacramento = (newValue, actionMeta) => {
        newValue = newValue ? newValue : {};
        this.setState({ sacramentosFiltro: newValue });
    };

    toogleFiltro(tab) {
        const prevState = this.state.filtro;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        this.setState({
            filtro: state,
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        let state = this.state;
        state[e.target.name] = e.target.value;
        this.buscaDados(state);
    }

    buscaDados(state) {
        let query = '';
        if (state.NOME) {
            query += query.indexOf('?') === -1 ? `?nome=${state.NOME}` : `&nome=${state.NOME}`;
        }
        if (state.TURMA) {
            query += query.indexOf('?') === -1 ? `?turma=${state.TURMA}` : `&turma=${state.TURMA}`;
        }
        if (state.ANO) {
            query += query.indexOf('?') === -1 ? `?ano=${state.ANO}` : `&ano=${state.ANO}`;
        }
        if (state.sacramentosFiltro) {
            let sacramentos = [];
            state.sacramentosFiltro.map(element => {
                return sacramentos.push(element.ID);
            });
            query += query.indexOf('?') === -1 ? `?sacramentos=${JSON.stringify(sacramentos)}` : `&sacramentos=${JSON.stringify(sacramentos)}`;
        }
        if (state.STATUS) {
            query += query.indexOf('?') === -1 ? `?status=${state.STATUS}` : `&status=${state.STATUS}`;
        }
        this.props.fetchObjs(`${this.state.url}${query}`)
            .then(() => {
                this.setState({
                    matriculas: this.props.records && this.props.records[0] ? this.props.records[0].matriculas.rows : [],
                    estatisticas: this.props.records && this.props.records[0] ? this.props.records[0].matriculas.estatisticas : [],
                })
            });
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader id="headingOne">
                                <Button
                                    block color="link"
                                    className="text-left m-0 p-0"
                                    onClick={() => this.toogleFiltro(0)}
                                    aria-expanded={this.state.filtro[0]}
                                    aria-controls="collapseOne"
                                    style={{ color: 'black' }}>
                                    <strong>Filtros</strong>
                                </Button>
                            </CardHeader>
                            <Collapse isOpen={this.state.filtro[0]} data-parent="#filtro" id="collapseOne" aria-labelledby="headingOne">
                                <CardBody>
                                    <FormGroup row>
                                        <Col xs="12" md="2">
                                            <Label htmlFor="text-input">Nome</Label>
                                        </Col>
                                        <Col xs="12" md="10">
                                            <Input
                                                name="NOME"
                                                value={this.state.NOME}
                                                onChange={this.handleChange}
                                                id="NOME" />
                                            <FormText color="muted"></FormText>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col xs="12" md="2">
                                            <Label htmlFor="text-input">Ano</Label>
                                        </Col>
                                        <Col xs="12" md="10">
                                            <Input
                                                type="number"
                                                name="ANO"
                                                value={this.state.ANO}
                                                onChange={this.handleChange}
                                                id="ANO"
                                                required
                                            />
                                            <FormText color="muted"></FormText>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col xs="12" md="2">
                                            <Label htmlFor="text-input">Turma</Label>
                                        </Col>
                                        <Col xs="12" md="10">
                                            <Input
                                                type="select"
                                                name="TURMA"
                                                value={this.state.TURMA}
                                                onChange={this.handleChange}
                                                id="TURMA">
                                                <option value="0">Todas</option>
                                                {
                                                    this.state.turmas ?
                                                        this.state.turmas.map((turma) => {
                                                            return (
                                                                <option key={turma.ID} value={turma.ID}>{`${turma.DESCRICAO} - ${turma.ANO} - ${turma.catequista.pessoaFisica.pessoa.NOME}`}</option>
                                                            )
                                                        }) : null
                                                }
                                            </Input>
                                            <FormText color="muted"></FormText>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="2">
                                            <Label htmlFor="text-input">Sacramentos</Label>
                                        </Col>
                                        <Col xs="12" md="10">
                                            <Select
                                                isMulti
                                                onChange={this.handleChangeSacramento}
                                                options={this.state.sacramentosOptions}
                                                value={this.state.sacramentosFiltro}
                                            />
                                            <FormText color="muted"></FormText>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="2">
                                            <Label htmlFor="text-input">Status</Label>
                                        </Col>
                                        <Col xs="12" md="10">
                                            <Input
                                                type="select"
                                                name="STATUS"
                                                value={this.state.STATUS}
                                                onChange={this.handleChange}
                                                id="STATUS">
                                                <option value="-1">Todos</option>
                                                {
                                                    this.state.status ?
                                                        this.state.status.map((s, i) => {
                                                            return (
                                                                <option key={i} value={i}>{`${s}`}</option>
                                                            )
                                                        }) : null
                                                }
                                            </Input>
                                            <FormText color="muted"></FormText>
                                        </Col>
                                    </FormGroup>
                                    <Button type="button" size="sm" color="primary" onClick={() => this.buscaDados(this.state)}>
                                        <i className="fa fa-search"></i> Buscar
                                    </Button>
                                </CardBody>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                {this.state.estatisticas ? ([
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col xs="12" sm="12">
                                            <strong>Estatísticas</strong>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs="12" sm="12">
                                            <strong>Total catequizandos</strong>{`: ${this.state.estatisticas.beneficiario ? this.state.estatisticas.beneficiario.QUANTIDADE_TOTAL || 0 : 0}`}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="12">
                                            <strong>Documentação incompleta</strong>{`: ${this.state.estatisticas.beneficiario ? this.state.estatisticas.beneficiario.QUANTIDADE || 0 : 0} - ${this.state.estatisticas.beneficiario ? this.state.estatisticas.beneficiario.PERCENTUAL || 0 : 0}%`}
                                        </Col>
                                    </Row>

                                    {this.state.estatisticas.sacramentos && this.state.estatisticas.sacramentos.length > 0 ? this.state.estatisticas.sacramentos.map(sacramento => {
                                        return ([
                                            <Row>
                                                <Col xs="12" sm="12">
                                                    <strong>{`${sacramento.DESCRICAO}`}</strong>{`: ${sacramento.QUANTIDADE} - ${sacramento.PERCENTUAL}%`}
                                                </Col>
                                            </Row>
                                        ])
                                    }) : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ]) : null}
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" sm="6">
                                        <strong>Matrículas</strong>
                                    </Col>
                                    <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                                        <ButtonGroup>
                                            <Button type="button" size="sm" color="primary" onClick={() => this.props.history.push(`${this.state.url}/new`)}>
                                                <i className="fa fa-plus"></i> Novo
                                            </Button>
                                            <DownloadActions body={this.state.matriculas && this.state.matriculas.length !== 0 ? this.state.matriculas : null} />
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.state.matriculas && this.state.matriculas.length !== 0 ? ([
                                    <Table key="table" hover bordered striped responsive size="sm">
                                        <thead key="thead">
                                            <tr key="head">
                                                <th key="id">Código</th>
                                                <th key="vigencia">Vigência</th>
                                                <th key="turma">Turma</th>
                                                <th key="pessoa">Pessoa</th>
                                                <th key="status">Status</th>
                                                <th xs="12" sm="1" key="acoes" className="text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody key="tbody">
                                            {this.state.matriculas.map(matricula => {
                                                return (
                                                    <tr key={`tr${matricula.ID}`}>
                                                        <td key={`tdId${matricula.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${matricula.ID}`)}>{matricula ? matricula.ID : ''}</td>
                                                        <td key={`tdVigencia${matricula.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${matricula.ID}`)}>{matricula && matricula.VIGENCIA ? dateFormatter(new Date(matricula.VIGENCIA)) : ''}</td>
                                                        <td key={`tdTurma${matricula.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${matricula.ID}`)}>{matricula && matricula.turma ? matricula.turma.DESCRICAO : ''}</td>
                                                        <td key={`tdPessoa${matricula.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${matricula.ID}`)}>{matricula && matricula.pessoa ? ` ${matricula.pessoa.NOME}` : ''}</td>
                                                        <td key={`tdStatus${matricula.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${matricula.ID}`)}>{this.state.status[matricula.STATUS]}</td>
                                                        <td key={`tdAction${matricula.ID}`} ><ListActions {...this.props} {...this.state} id={matricula.ID} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>,
                                    <TablePaginator key="tablePaginator" {...this.props} {...this.state} callback={this.mapMatriculasToState} />
                                ]) : emptyMessage}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        records: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs, deleteObj })(MatriculaList);

