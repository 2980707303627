import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row, Table, Button, ButtonGroup, Collapse } from 'reactstrap';
import FileBase64 from 'react-file-base64';
import { saveObj, fetchObj, fetchObjs, updateObj, deleteObj, staticUrl } from '../../actions';
import CardFooterActions from '../../containers/CardFooterActions/CardFooterActions';
import { dateFormatter } from '../../utils/utils';
import swal from 'sweetalert2';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
import months from '../../utils/months.json';
import status from '../Matricula/status.json';
import tipoDocumento from '../TipoDocumento/tipoDocumento.json';

class Turma extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      DESCRICAO: '',
      CATEQUISTA: '',
      catequistas: [],
      matriculas: [],
      status: status,
      turmas: [],
      ANO: '',
      DIA_SEMANA: 1,
      COR: '',
      color: {
        r: '00',
        g: '112',
        b: '19',
        a: '1',
      },
      LIMITE: '',
      LIMITE_ANO: '',
      LIMITE_NASC: '',
      SALA: '',
      URL: '',
      months: months,

      tipoDocumento: tipoDocumento,
      turmaDocumento: [true],
      turmasDocumentos: [],

      errors: {},
      loading: false,
      redirect: false,
      url: '/turmas',
      urlMatricula: '/matriculas',
      nameObj: 'turma'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTransferir = this.handleTransferir.bind(this);
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleDeleteDoc = this.handleDeleteDoc.bind(this);
    this.handleOpenDoc = this.handleOpenDoc.bind(this);
    this.toogleTurmaDocumento = this.toogleTurmaDocumento.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.params.id && match.params.id !== 'new') {
      this.props.fetchObj(this.state.url, match.params.id)
        .then(() => {
          this.setState({ ...this.props });
          this.setState({ turmasDocumentos: this.props.turmaDocumentos ? this.props.turmaDocumentos : [] });
          if (this.props.COR) {
            let hex = this.props.COR;
            let r = parseInt(hex.slice(1, 3), 16),
              g = parseInt(hex.slice(3, 5), 16),
              b = parseInt(hex.slice(5, 7), 16);
            this.setState({ color: { r: r, g: g, b: b, a: '100' } });
          }
        });
      this.props.fetchObjs(`/matriculas/turmas/${match.params.id}`)
        .then(() => { this.setState({ matriculas: this.props.records }) });
      this.props.fetchObjs(`/transferencias/turmas`, 1, 10000)
        .then(() => {
          let turmas = this.props.records;
          let turmasFormatted = {};
          turmas.map(turma => {
            return turmasFormatted[turma.ID + ""] = `${turma.DESCRICAO} - ${turma.ANO} - ${turma.catequista.pessoaFisica.pessoa.NOME}`;
          })
          this.setState({ turmas: turmasFormatted });
        });
    }
    this.props.fetchObjs('/funcionarios', 1, 10000)
      .then(() => { this.setState({ catequistas: this.props.records }) });
    this.prepareDocumentos();
  }

  prepareDocumentos = () => {
    let turmasDocumentos = this.state.turmasDocumentos;
    if (!turmasDocumentos) {
      turmasDocumentos = [];
    }
    if (turmasDocumentos.length === 0) {
      this.state.tipoDocumento.map((item, i) => {
        return turmasDocumentos.push({ TIPO_DOCUMENTO: i });
      });
    }
    this.setState({ turmasDocumentos });
  }

  handleChange = (e) => {
    if (e.base64) {
      let state = this.state;
      state["URL"] = e.base64;
      this.setState(state);
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleCheck = (e, i) => {
    let matriculas = this.state.matriculas;
    matriculas[i].TRANSFERIR = e.target.checked;
    this.setState({ matriculas })
  }

  handleCheckAll = (e) => {
    let matriculas = this.state.matriculas;
    matriculas.map(matricula => {
      return matricula.TRANSFERIR = e.target.checked;
    });
    this.setState({ matriculas })
  }

  handleChangeColor = (color) => {
    this.setState({ color: color.rgb, COR: color.hex });
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };


  handleToogleChange = (e, i) => {
    let turmasDocumentos = this.state.turmasDocumentos;
    turmasDocumentos[i][e.target.name] = e.target.checked ? 1 : 0;
    this.setState({ turmasDocumentos });
  };

  handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
  };

  handleDeleteDoc = () => {
    this.setState({ URL: '' });
  }

  handleOpenDoc = () => {
    window.open(this.state.URL, "_blank");
  }

  saveObj = (obj) => {
    if (obj.ID) {
      return this.props.updateObj(this.state.url, this.state.nameObj, obj);
    } else {
      return this.props.saveObj(this.state.url, this.state.nameObj, obj);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveObj(this.state)
      .then(() => this.props.history.push(this.state.url));
  }

  handleImprimirPresenca = () => {
    let query = `?token=${localStorage.getItem('token')}`;
    window.open(`${staticUrl}/presencas/reports/brancas/turmas/${this.state.ID}${query}`, "_self")
  }

  handleImprimirMatriculas = () => {
    let query = `?token=${localStorage.getItem('token')}`;
    window.open(`${staticUrl}/turmas/reports/matriculas/${this.state.ID}${query}`, "_self")
  }

  handleImprimirInfo = () => {
    let query = `?token=${localStorage.getItem('token')}`;
    window.open(`${staticUrl}/turmas/reports/infos/${this.state.ID}${query}`, "_self")
  }

  handleImprimirNomes = () => {
    let query = `?token=${localStorage.getItem('token')}`;
    window.open(`${staticUrl}/turmas/reports/nomes/${this.state.ID}${query}`, "_self")
  }

  handleTransferir = (e) => {
    let transferencia = {
      matriculas: this.state.matriculas.filter(matricula => { return matricula.TRANSFERIR ? true : false })
    };
    if (transferencia.matriculas.length === 0) {
      swal({
        title: 'Ops',
        type: 'error',
        text: `Selecione pelo menos uma matrícula para fazer a transferência!`
      });
    } else {
      swal.mixin({
        width: '70%',
        confirmButtonText: 'Próximo &rarr;',
        cancelButtonText: 'Cancelar',
        cancelButtonClass: 'btn btn-danger',
        showCancelButton: true,
        progressSteps: ['1', '2']
      }).queue([
        {
          input: 'select',
          inputOptions: this.state.turmas,
          text: 'Para qual turma as matrículas selecionadas serão transferidas?'
        },
        {
          input: 'select',
          inputOptions: { 'Sim': 'Sim', 'Não': 'Não' },
          text: 'Deseja concluir as matrículas que forem transferidas?'
        }
      ]).then((result) => {
        if (result.value) {
          transferencia.turma = result.value[0];
          transferencia.canFinish = result.value[1] && result.value[1] === 'Sim' ? true : false;
          swal({
            width: '70%',
            title: 'Confirmar',
            text: `Deseja realmente transferir as matrículas selecionadas para ${this.state.turmas[result.value[0]]}${result.value[1] && result.value[1] === 'Sim' ? ' e alterar o seus status para concluir' : ''}?`,
            confirmButtonText: 'Transferir',
            cancelButtonText: 'Cancelar',
            cancelButtonClass: 'btn btn-danger',
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              return this.props.saveObj(`/transferencias/turmas`, 'transferencia', transferencia);
            }
          })
        }
      });
    }
  }

  handleApprove = () => {
    this.approveObj()
      .then(() => this.props.history.push(this.state.url));
  }

  toogleTurmaDocumento(tab) {
    const prevState = this.state.turmaDocumento;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState({
      turmaDocumento: state,
    });
  }


  render() {
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return (
      <Row>
        <Col xs="12" md="12">
          <Card>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleSubmit}>
              <CardHeader>
                <strong>Cadastro</strong> Turma
                </CardHeader>
              <CardBody>
                {this.state.ID ?
                  <FormGroup row>
                    <Col md="2">
                      <Label>Código</Label>
                    </Col>
                    <Col xs="12" md="10">
                      <p className="form-control-static">{this.state.ID}</p>
                    </Col>
                  </FormGroup>
                  : null}
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Descrição</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="DESCRICAO"
                      value={this.state.DESCRICAO}
                      onChange={this.handleChange}
                      id="DESCRICAO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Catequista</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="CATEQUISTA"
                      value={this.state.CATEQUISTA}
                      onChange={this.handleChange}
                      id="CATEQUISTA"
                      required>
                      <option value="0">Selecione ...</option>
                      {
                        this.state.catequistas ?
                          this.state.catequistas.map((catequista, i) => {
                            return (
                              <option key={catequista.ID} value={catequista.ID}>{`${catequista.pessoaFisica.pessoa.NOME}`}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Ano</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="number"
                      name="ANO"
                      value={this.state.ANO}
                      onChange={this.handleChange}
                      id="ANO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Dia da Semana</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="DIA_SEMANA"
                      value={this.state.DIA_SEMANA}
                      onChange={this.handleChange}
                      id="DIA_SEMANA">
                      <option value="1">Domingo</option>
                      <option value="2">Segunda-feira</option>
                      <option value="3">Terça-feira</option>
                      <option value="4">Quarta-feira</option>
                      <option value="5">Quinta-feira</option>
                      <option value="6">Sexta-feira</option>
                      <option value="7">Sábado</option>
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Sala</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      name="SALA"
                      value={this.state.SALA}
                      onChange={this.handleChange}
                      id="SALA"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Limite</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="number"
                      name="LIMITE"
                      value={this.state.LIMITE}
                      onChange={this.handleChange}
                      id="LIMITE"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Limite Idade</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="number"
                      name="LIMITE_ANO"
                      value={this.state.LIMITE_ANO}
                      onChange={this.handleChange}
                      id="LIMITE_ANO"
                      required
                    />
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Mês limite</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      type="select"
                      name="LIMITE_NASC"
                      value={this.state.LIMITE_NASC}
                      onChange={this.handleChange}
                      id="LIMITE_NASC">
                      {
                        this.state.months ?
                          this.state.months.map((month, i) => {
                            return (
                              <option key={month.id} value={month.id}>{month.nome}</option>
                            )
                          }) : null
                      }
                    </Input>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Cor</Label>
                  </Col>
                  <Col xs="12" md="10">
                    <div>
                      <div style={styles.swatch} onClick={this.handleClick}>
                        <div style={styles.color} />
                      </div>
                      {this.state.displayColorPicker ? <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />
                        <SketchPicker color={this.state.color} onChange={this.handleChangeColor} />
                      </div> : null}

                    </div>
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="2">
                    <Label htmlFor="text-input">Controle de presença</Label>
                  </Col>
                  <Col xs="12" md="10">
                    {!this.state.URL ?
                      <div>
                        <FileBase64
                          name="URL"
                          value={this.state.URL}
                          id="URL"
                          multiple={false}
                          onDone={this.handleChange} />
                      </div>
                      :
                      <div>
                        <Button key={`delete${this.props.key}`} size="sm" color="danger" onClick={() => this.handleDeleteDoc()}>
                          <i key={`deleteI${this.props.key}`} className="fa fa-trash"></i>
                        </Button>
                        <Button key={`open${this.props.key}`} size="sm" color="primary" onClick={() => this.handleOpenDoc()}>
                          <i key={`openI${this.props.key}`} className="fa fa-folder-open-o"></i>
                        </Button>
                        &nbsp;
                                    <a href={this.state.URL} className="form-control-static">{this.state.URL}</a>
                      </div>
                    }
                    <FormText color="muted"></FormText>
                  </Col>
                </FormGroup>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="6" sm="6">
                        <Button
                          block color="link"
                          className="text-left m-0 p-0"
                          onClick={() => this.toogleTurmaDocumento(0)}
                          aria-expanded={this.state.turmaDocumento[0]}
                          aria-controls="collapseOne"
                          style={{ color: 'black' }}>
                          <strong>Documentos obrigatórios</strong>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.turmaDocumento[0]} data-parent="#turmaDocumento" id="collapseOne" aria-labelledby="headingOne">
                    <CardBody>
                      {
                        this.state.turmasDocumentos ? this.state.turmasDocumentos.map((item, i) => {
                          return ([
                            <Row>
                              <Col md="6">
                                <Label htmlFor="text-input">{this.state.tipoDocumento[item.TIPO_DOCUMENTO]}</Label>
                              </Col>
                              <Col xs="6" md="6" sm="6"
                                key={`colTurmaDocumentoFather${i}`}>
                                <Input
                                  type="checkbox"
                                  name={`OBRIGATORIO`}
                                  checked={item.OBRIGATORIO}
                                  value={item.OBRIGATORIO + ""}
                                  onChange={(e) => { this.handleToogleChange(e, i) }}
                                  id={`OBRIGATORIO`} />
                              </Col>
                            </Row>
                          ])
                        }) : null
                      }
                    </CardBody>
                  </Collapse>
                </Card>
                {this.state.ID && this.state.matriculas && this.state.matriculas.length !== 0 ? ([
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col xs="6" sm="6">
                          <strong>Matrículas</strong>
                        </Col>
                        <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
                          <ButtonGroup>
                            {this.state.ID ?
                              <Button type="button" size="sm" color="danger" onClick={() => this.handleImprimirInfo()}>
                                <i className="fa fa-file-text-o"></i> Imprimir Informações Catequizandos
                            </Button>
                              : null}
                            {this.state.ID ?
                              <Button type="button" size="sm" color="warning" onClick={() => this.handleImprimirNomes()}>
                                <i className="fa fa-file-text-o"></i> Imprimir Nomes
                            </Button>
                              : null}
                            {this.state.ID ?
                              <Button type="button" size="sm" color="secondary" onClick={() => this.handleImprimirMatriculas()}>
                                <i className="fa fa-file-text-o"></i> Imprimir Informações Turma
                            </Button>
                              : null}
                            {this.state.ID ?
                              <Button type="button" size="sm" color="success" onClick={() => this.handleImprimirPresenca()}>
                                <i className="fa fa-file-text-o"></i> Imprimir Presença
                            </Button>
                              : null}
                            <Button type="button" size="sm" color="primary" onClick={() => this.handleTransferir()}>
                              <i className="fa fa-refresh"></i> Transferir
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Table key="table" hover bordered striped responsive size="sm">
                        <thead key="thead">
                          <tr key="head">
                            <th key="checkall" className="text-center">
                              <input type="checkbox" key={`tdCheckAll`} onChange={this.handleCheckAll} />
                            </th>
                            <th key="id">Código</th>
                            <th key="vigencia">Vigência</th>
                            <th key="turma">Turma</th>
                            <th key="pessoa">Pessoa</th>
                            <th key="status">Status</th>
                          </tr>
                        </thead>
                        <tbody key="tbody">
                          {this.state.matriculas.map((matricula, i) => {
                            return (
                              <tr key={`tr${matricula.ID}`}>
                                <td key="check" className="text-center">
                                  <input
                                    type="checkbox"
                                    key={`tdCheck${matricula.ID}`}
                                    checked={matricula.TRANSFERIR}
                                    onChange={(e) => { this.handleCheck(e, i) }}
                                  />
                                </td>
                                <td key={`tdId${matricula.ID}`} onClick={() => this.props.history.push(`${this.state.urlMatricula}/${matricula.ID}`)}>{matricula ? matricula.ID : ''}</td>
                                <td key={`tdVigencia${matricula.ID}`} onClick={() => this.props.history.push(`${this.state.urlMatricula}/${matricula.ID}`)}>{matricula && matricula.VIGENCIA ? dateFormatter(new Date(matricula.VIGENCIA)) : ''}</td>
                                <td key={`tdTurma${matricula.ID}`} onClick={() => this.props.history.push(`${this.state.urlMatricula}/${matricula.ID}`)}>{matricula && matricula.turma ? matricula.turma.DESCRICAO : ''}</td>
                                <td key={`tdPessoa${matricula.ID}`} onClick={() => this.props.history.push(`${this.state.urlMatricula}/${matricula.ID}`)}>{matricula && matricula.pessoa ? `${matricula.pessoa.NOME}` : ''}</td>
                                <td key={`tdStatus${matricula.ID}`} onClick={() => this.props.history.push(`${this.state.url}/${matricula.ID}`)}>{this.state.status[matricula.STATUS]}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                ]) : null}
              </CardBody>
              <CardFooterActions {...this.props} {...this.state} />
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { saveObj, fetchObj, fetchObjs, updateObj, deleteObj })(Turma);
