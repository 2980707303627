import React, { Component } from 'react';
import { Form, Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { updateObj } from '../../../actions';
import { connect } from 'react-redux';
import md5 from 'md5';
import jwt from 'jsonwebtoken';

class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pass1: '',
      pass2: '',
      nameObj: 'usuario',
      url: '/usuarios'
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  register = (event) => {
    event.preventDefault();

    let usuario = JSON.parse(localStorage.getItem('usuarioLogado'));
    
    if (!usuario) {
      let href = window && window.location && window.location.href;
      let user = href.substring(href.indexOf('?') + 1);
      usuario = jwt.decode(user);
      localStorage.setItem('token', user);
      localStorage.setItem('usuarioLogado', JSON.stringify(usuario));

      usuario.SENHA = md5(this.state.pass1);

      this.props.updateObj(this.state.url, this.state.nameObj, usuario)
        .then(user => {
          this.props.history.push('loginpage');
        });

    } else {
      usuario.SENHA = md5(this.state.pass1);

      this.props.updateObj(this.state.url, this.state.nameObj, usuario)
        .then(user => {
          this.props.history.push('dashboard');
        });
    }
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">

                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal" onSubmit={this.register}>
                  <CardBody className="p-4">
                    <h1>Nova Senha</h1>
                    <p className="text-muted">Seja bem vindo, precisamos de uma senha segura e que você se lembre sempre, para sua comodidade!</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" placeholder="Senha" name="pass1" onChange={this.handleChange} required />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" placeholder="Repita a Senha" name="pass2" onChange={this.handleChange} required />
                    </InputGroup>
                    <Button type="submit" color="success" block >Alterar Senha</Button>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.obj };
}

export default connect(mapStateToProps, { updateObj })(Register);
