import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Label, Input, FormText, FormGroup, Card, CardBody, CardHeader, Collapse, CardTitle, Col, Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchObjs } from '../../actions';
import { makeColors } from '../../utils/utils';
import Select from 'react-select';
import status from '../Matricula/status.json';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

class ResultadoMatricula extends Component {

    constructor(props) {
        super(props);
        this.state = {
            matriculasPorStatusChart: {
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: makeColors()
                },]
            },
            matriculasPorSacramentoChart: {
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: makeColors()
                },]
            },
            matriculasPorDocumentacaoChart: {
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: makeColors()
                },]
            },
            turmas: [],
            sacramentosFiltro: [],
            sacramentos: [],
            sacramentosOptions: [],
            status: status,
            radioSelected: 1,
            filtro: [false],
            url: '/matriculas/resultados/grafico'
        }
        this.toogleFiltro = this.toogleFiltro.bind(this);
    }

    componentDidMount() {
        this.props.fetchObjs(`/turmas`, 1, 1000)
            .then(() => { this.setState({ turmas: this.props.records }) });
        this.props.fetchObjs('/sacramentos', 1, 1000)
            .then(() => {
                let sacramentos = this.props.records;
                let sacramentosFiltro = [];
                sacramentos.map((element) => {
                    return sacramentosFiltro.push({
                        label: element.DESCRICAO,
                        value: element.DESCRICAO,
                        ...element
                    });
                });
                this.setState({ sacramentosOptions: sacramentosFiltro });
            });
        this.buscaDados(this.state);
    }

    buscaDados(state) {
        let query = '';
        if (state.NOME) {
            query += query.indexOf('?') === -1 ? `?nome=${state.NOME}` : `&nome=${state.NOME}`;
        }
        if (state.TURMA) {
            query += query.indexOf('?') === -1 ? `?turma=${state.TURMA}` : `&turma=${state.TURMA}`;
        }
        if (state.ANO) {
            query += query.indexOf('?') === -1 ? `?ano=${state.ANO}` : `&ano=${state.ANO}`;
        }
        if (state.sacramentosFiltro) {
            let sacramentos = [];
            state.sacramentosFiltro.map(element => {
                return sacramentos.push(element.ID);
            });
            query += query.indexOf('?') === -1 ? `?sacramentos=${JSON.stringify(sacramentos)}` : `&sacramentos=${JSON.stringify(sacramentos)}`;
        }
        if (state.STATUS) {
            query += query.indexOf('?') === -1 ? `?status=${state.STATUS}` : `&status=${state.STATUS}`;
        }
        this.props.fetchObjs(`${state.url}${query}`)
            .then(() => {
                let state = this.state;

                state.matriculasPorStatusChart.labels = this.props.resultado && this.props.resultado.matriculasPorStatus && this.props.resultado.matriculasPorStatus.labels ? this.props.resultado.matriculasPorStatus.labels : [];
                state.matriculasPorStatusChart.datasets[0].data = this.props.resultado && this.props.resultado.matriculasPorStatus && this.props.resultado.matriculasPorStatus.values ? [].concat(this.props.resultado.matriculasPorStatus.values) : [];

                state.matriculasPorSacramentoChart.labels = this.props.resultado && this.props.resultado.matriculasPorSacramento && this.props.resultado.matriculasPorSacramento.labels ? this.props.resultado.matriculasPorSacramento.labels : [];
                state.matriculasPorSacramentoChart.datasets[0].data = this.props.resultado && this.props.resultado.matriculasPorSacramento && this.props.resultado.matriculasPorSacramento.values ? [].concat(this.props.resultado.matriculasPorSacramento.values) : [];
                
                state.matriculasPorDocumentacaoChart.labels = this.props.resultado && this.props.resultado.matriculasPorDocumentacao && this.props.resultado.matriculasPorDocumentacao.labels ? this.props.resultado.matriculasPorDocumentacao.labels : [];
                state.matriculasPorDocumentacaoChart.datasets[0].data = this.props.resultado && this.props.resultado.matriculasPorDocumentacao && this.props.resultado.matriculasPorDocumentacao.values ? [].concat(this.props.resultado.matriculasPorDocumentacao.values) : [];
                
                this.setState(state);
            });
    }

    filterSacramentos = (inputValue) => {
        return this.state.sacramentosOptions.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    handleChangeSacramento = (newValue, actionMeta) => {
        newValue = newValue ? newValue : {};
        this.setState({ sacramentosFiltro: newValue });
    };


    toogleFiltro(tab) {
        const prevState = this.state.filtro;
        const state = prevState.map((x, index) => tab === index ? !x : false);
        this.setState({
            filtro: state,
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.buscaDados({ start: this.state.start, end: this.state.end, url: this.state.url, CONTA: e.target.value });
    }

    render() {
        return (
            <div className="animated fadeIn">
                {
                    this.props.hiddenFilter ? null :
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader id="headingOne">
                                        <Button
                                            block color="link"
                                            className="text-left m-0 p-0"
                                            onClick={() => this.toogleFiltro(0)}
                                            aria-expanded={this.state.filtro[0]}
                                            aria-controls="collapseOne">
                                            <strong>Filtros</strong>
                                        </Button>
                                    </CardHeader>
                                    <Collapse isOpen={this.state.filtro[0]} data-parent="#filtro" id="collapseOne" aria-labelledby="headingOne">
                                        <CardBody>
                                            <FormGroup row>
                                                <Col xs="12" md="2">
                                                    <Label htmlFor="text-input">Nome</Label>
                                                </Col>
                                                <Col xs="12" md="10">
                                                    <Input
                                                        name="NOME"
                                                        value={this.state.NOME}
                                                        onChange={this.handleChange}
                                                        id="NOME" />
                                                    <FormText color="muted"></FormText>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col xs="12" md="2">
                                                    <Label htmlFor="text-input">Ano</Label>
                                                </Col>
                                                <Col xs="12" md="10">
                                                    <Input
                                                        type="number"
                                                        name="ANO"
                                                        value={this.state.ANO}
                                                        onChange={this.handleChange}
                                                        id="ANO"
                                                        required
                                                    />
                                                    <FormText color="muted"></FormText>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col xs="12" md="2">
                                                    <Label htmlFor="text-input">Turma</Label>
                                                </Col>
                                                <Col xs="12" md="10">
                                                    <Input
                                                        type="select"
                                                        name="TURMA"
                                                        value={this.state.TURMA}
                                                        onChange={this.handleChange}
                                                        id="TURMA">
                                                        <option value="0">Todas</option>
                                                        {
                                                            this.state.turmas ?
                                                                this.state.turmas.map((turma) => {
                                                                    return (
                                                                        <option key={turma.ID} value={turma.ID}>{`${turma.DESCRICAO} - ${turma.ANO} - ${turma.catequista.pessoaFisica.pessoa.NOME}`}</option>
                                                                    )
                                                                }) : null
                                                        }
                                                    </Input>
                                                    <FormText color="muted"></FormText>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="2">
                                                    <Label htmlFor="text-input">Sacramentos</Label>
                                                </Col>
                                                <Col xs="12" md="10">
                                                    <Select
                                                        isMulti
                                                        onChange={this.handleChangeSacramento}
                                                        options={this.state.sacramentosOptions}
                                                        value={this.state.sacramentosFiltro}
                                                    />
                                                    <FormText color="muted"></FormText>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="2">
                                                    <Label htmlFor="text-input">Status</Label>
                                                </Col>
                                                <Col xs="12" md="10">
                                                    <Input
                                                        type="select"
                                                        name="STATUS"
                                                        value={this.state.STATUS}
                                                        onChange={this.handleChange}
                                                        id="STATUS">
                                                        <option value="-1">Todos</option>
                                                        {
                                                            this.state.status ?
                                                                this.state.status.map((s, i) => {
                                                                    return (
                                                                        <option key={i} value={i}>{`${s}`}</option>
                                                                    )
                                                                }) : null
                                                        }
                                                    </Input>
                                                    <FormText color="muted"></FormText>
                                                </Col>
                                            </FormGroup>
                                            <Button type="button" size="sm" color="primary" onClick={() => this.buscaDados(this.state)}>
                                                <i className="fa fa-search"></i> Buscar
                                            </Button>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                }
                <Row>
                    <Col sm="4">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="5">
                                        <CardTitle className="mb-0">Documentação</CardTitle>
                                        <div className="small text-muted">Completa/Imcompleta</div>
                                    </Col>
                                </Row>
                                <div style={{}}>
                                    <Doughnut legend={{position:"right"}} data={this.state.matriculasPorDocumentacaoChart} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col sm="4">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="5">
                                        <CardTitle className="mb-0">Matriculas</CardTitle>
                                        <div className="small text-muted">Por Status</div>
                                    </Col>
                                </Row>
                                <div style={{}}>
                                    <Doughnut legend={{position:"right"}} data={this.state.matriculasPorStatusChart} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col sm="4">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="5">
                                        <CardTitle className="mb-0">Matriculas</CardTitle>
                                        <div className="small text-muted">Por Sacramento</div>
                                    </Col>
                                </Row>
                                <div style={{}}>
                                    <Doughnut legend={{position:"right"}} data={this.state.matriculasPorSacramentoChart} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        resultado: state.obj.records && state.obj.records.length > 0 ? state.obj.records[0] : {},
        records: state.obj.records ? state.obj.records : [],
        meta: state.obj.meta ? state.obj.meta : {}
    }
}

export default connect(mapStateToProps, { fetchObjs })(ResultadoMatricula);

