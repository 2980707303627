//Adicionar os imports em ordem alfabética
import Atividade from './views/Atividade/Atividade';
import AtividadeList from './views/Atividade/AtividadeList';
import Agenda from './views/Agenda/Agenda';
import AgendaList from './views/Agenda/AgendaList';
import Beneficiario from './views/Beneficiario/Beneficiario';
import BeneficiarioList from './views/Beneficiario/BeneficiarioList';
import Calendario from './views/Calendario/Calendario';
import Cargo from './views/Cargo/Cargo';
import CargoList from './views/Cargo/CargoList';
import Conta from './views/Conta/Conta';
import ContaList from './views/Conta/ContaList';
import ContabilList from './views/Contabil/ContabilList';
import Dashboard from './views/Dashboard/Dashboard';
import Despesa from './views/Despesa/Despesa';
import DespesaList from './views/Despesa/DespesaList';
import Documento from './views/Documento/Documento';
import DocumentoList from './views/Documento/DocumentoList';
import Fluxo from './views/Fluxo/Fluxo';
import Funcionario from './views/Funcionario/Funcionario';
import FuncionarioList from './views/Funcionario/FuncionarioList';
import Full from './containers/Full';
import Lembranca from './views/Lembranca/Lembranca';
import Matricula from './views/Matricula/Matricula';
import MatriculaList from './views/Matricula/MatriculaList';
import Meta from './views/Meta/Meta';
import MetaList from './views/Meta/MetaList';
import Ocorrencia from './views/Ocorrencia/Ocorrencia';
import OcorrenciaList from './views/Ocorrencia/OcorrenciaList';
import Perfil from './views/Perfil/Perfil';
import PerfilList from './views/Perfil/PerfilList';
import Presenca from './views/Presenca/Presenca';
import Produto from './views/Produto/Produto';
import ProdutoList from './views/Produto/ProdutoList';
import Receita from './views/Receita/Receita';
import ReceitaList from './views/Receita/ReceitaList';
import Resultado from './views/Resultado/Resultado';
import ResultadoMatricula from './views/ResultadoMatricula/ResultadoMatricula';
import Roteiro from './views/Roteiro/Roteiro';
import Sacramento from './views/Sacramento/Sacramento';
import SacramentoList from './views/Sacramento/SacramentoList';
import Status from './views/Status/Status';
import StatusList from './views/Status/StatusList';
import TipoAtividade from './views/TipoAtividade/TipoAtividade';
import TipoAtividadeList from './views/TipoAtividade/TipoAtividadeList';
import TipoConta from './views/TipoConta/TipoConta';
import TipoContaList from './views/TipoConta/TipoContaList';
import TipoDespesa from './views/TipoDespesa/TipoDespesa';
import TipoDespesaList from './views/TipoDespesa/TipoDespesaList';
import TipoReceita from './views/TipoReceita/TipoReceita';
import TipoReceitaList from './views/TipoReceita/TipoReceitaList';
import Turma from './views/Turma/Turma';
import TurmaList from './views/Turma/TurmaList';
import Unidade from './views/Unidade/Unidade';
import UnidadeList from './views/Unidade/UnidadeList';
import Usuario from './views/Usuario/Usuario';
import UsuarioList from './views/Usuario/UsuarioList';


// adicionar todas em ordem alfabética
const routes = [
  { path: '/', exact: true, name: 'Home', component: Full },
  { path: '/atividades/:id', name: 'Atividade', component: Atividade },
  { path: '/atividades', name: 'Lista Atividades', component: AtividadeList },
  { path: '/agendas/:id', name: 'Agendamentos', component: Agenda },
  { path: '/agendas', name: 'Lista Agendamentos', component: AgendaList },
  { path: '/beneficiarios/:id', name: 'Catequizando', component: Beneficiario },
  { path: '/beneficiarios', name: 'Lista Catequizando', component: BeneficiarioList },
  { path: '/calendarios', name: 'Calendários', component: Calendario },
  { path: '/cargos/:id', name: 'Cargo', component: Cargo },
  { path: '/cargos', name: 'Lista Cargos', component: CargoList },
  { path: '/contabeis', name: 'Livro Contábil', component: ContabilList },
  { path: '/contas/:id', name: 'Conta', component: Conta },
  { path: '/contas', name: 'Lista Contas', component: ContaList },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/despesas/:id', name: 'Despesa', component: Despesa },
  { path: '/despesas', name: 'Lista Despesas', component: DespesaList },
  { path: '/documentos/:id', name: 'Documento', component: Documento },
  { path: '/documentos', name: 'Lista Artigos', component: DocumentoList },
  { path: '/fluxos', name: 'Fluxo de caixa', component: Fluxo },
  { path: '/funcionarios/:id', name: 'Catequista', component: Funcionario },
  { path: '/funcionarios', name: 'Lista Catequistas', component: FuncionarioList },
  { path: '/lembrancas', name: 'Lembrança', component: Lembranca },
  { path: '/matricula/resultados', name: 'Resultados Matrículas', component: ResultadoMatricula },
  { path: '/matriculas/:id', name: 'Matrícula', component: Matricula },
  { path: '/matriculas', name: 'Lista Matrículas', component: MatriculaList },
  { path: '/metas/:id', name: 'Meta', component: Meta },
  { path: '/metas', name: 'Lista Metas', component: MetaList },
  { path: '/ocorrencias/:id', name: 'Ocorrencia', component: Ocorrencia },
  { path: '/ocorrencias', name: 'Lista Ocorrencias', component: OcorrenciaList },
  { path: '/perfis/:id', name: 'Perfil', component: Perfil },
  { path: '/perfis', name: 'Lista Perfis', component: PerfilList },
  { path: '/presencas', name: 'Presença', component: Presenca },
  { path: '/produtos/:id', name: 'Produto', component: Produto },
  { path: '/produtos', name: 'Inventário', component: ProdutoList },
  { path: '/receitas/:id', name: 'Receita', component: Receita },
  { path: '/receitas', name: 'Lista Receitas', component: ReceitaList },
  { path: '/resultados', name: 'Resultados Financeiros', component: Resultado },
  { path: '/roteiros', name: 'Roteiros Semanais', component: Roteiro },
  { path: '/sacramentos/:id', name: 'Sacramento', component: Sacramento },
  { path: '/sacramentos', name: 'Lista Sacramentos', component: SacramentoList },
  { path: '/status/:id', name: 'Status', component: Status },
  { path: '/status', name: 'Lista Status', component: StatusList },
  { path: '/tiposatividades/:id', name: 'Tipo de Atividade', component: TipoAtividade },
  { path: '/tiposatividades', name: 'Lista Tipos de Atividades', component: TipoAtividadeList },
  { path: '/tiposcontas/:id', name: 'Tipo de Contas', component: TipoConta },
  { path: '/tiposcontas', name: 'Lista Tipos de Contass', component: TipoContaList },
  { path: '/tiposdespesas/:id', name: 'Tipo de Despesa', component: TipoDespesa },
  { path: '/tiposdespesas', name: 'Lista Tipos de Despesas', component: TipoDespesaList },
  { path: '/tiposreceitas/:id', name: 'Tipo de Receita', component: TipoReceita },
  { path: '/tiposreceitas', name: 'Lista Tipos de Receitas', component: TipoReceitaList },
  { path: '/turmas/:id', name: 'Turma', component: Turma },
  { path: '/turmas', name: 'Lista Turmas', component: TurmaList },
  { path: '/unidades/:id', name: 'Unidade', component: Unidade },
  { path: '/unidades', name: 'Lista Unidades', component: UnidadeList },
  { path: '/usuarios/:id', name: 'Usuário', component: Usuario },
  { path: '/usuarios', name: 'Lista Usuários', component: UsuarioList },
  
];

export default routes;
